import React, { Component, createRef } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import Modal from 'bootstrap/js/dist/modal';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import qs from 'qs';
import FilterableTable from 'rc-data-table';
import { Chip } from '@/ui-library';

import refillIcon from '../../assets/images/refill.png';
import splitIcon from '../../assets/images/split.png';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import { OrderService } from '../../services/order.service';
import Button from '../common/button';
import CustomModal from '../common/CustomModal';
import DateRangeComp from '../daterange/DateRangeComp';

import UnableToDispenseModal from './unableToDispenseModal';

import './splitOrder.scss';
import './unableToDispenseOrder.scss';
import { getUnableToDispenseInfo } from '@/services/pharmacist.service';
import { OrderStatus } from './order.types';

import { Logger } from '@/utils/logger';
import FilterByOrderStatusSelect from '../common/FilterByOrderStatusSelect';
import FilterOrderByPharmacySelect from '../common/FilterOrderByPharmacySelect';
import USER_ROLES from '@/constants/userRoles';
import SplitOrderModal from './splitOrderModal';

const logger = new Logger('orderView');

const { url } = settings;
const API_URL = `${url}/order`;
const { CancelToken } = axios;
let cancel;
const history = createBrowserHistory();

class orderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      actions: [],
      bulkactions: [],
      selected: '',
      bulkselected: '',
      record_id: '',
      loading: false,
      pay_status: '',
      selectedItems: [],
      order_status: false,
      mlHeader: '',
      mlValue: '',
      pageSize: 100,
      count: 0,
      page: 0,
      filter: '',
      confirmModalVisible: false,
      ordersInAwaiting: ' ',
      sort: { name: 'order_date', reverse: true },
      showSplitOrderModal: false,
      selectedProducts: [],
      selectedOrder: null,
      splitOrders: [],
      disabled: false,
      setCount: '',
      loadData: true,
      includeSasStatus: true,
      unableToDispenseData: null,
      inactiveProducts: [],
      pharmacyFilter: '',
      role_id: AuthService.getUser().user.role_id,
    };
    this.validator = new SimpleReactValidator();
    this.unableToDispenseRef = createRef();
  }

  /**
   * Runs a cancellable order search if there is a filter value, otherwise
   * empty data is returned.
   */
  searchOrders = (pageSize, page, sort, filter) =>
    axios.get(`${url}/data/order/`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      params: {
        limit: pageSize,
        offset: page * pageSize,
        sort: sort.name,
        sortOrder: sort.reverse ? 'DESC' : 'ASC',
        filter,
        ts: new Date().getTime(),
        useFastQuery: 'Y',
        pharmacy_id: this.state.pharmacyFilter,
      },
    });

  debouncedSearchOrders = AwesomeDebouncePromise(this.searchOrders, 1500);

  async componentDidMount() {
    document.title = 'Orders - Montu Group | Making Medical Cannabis Accessible';
    const isML = AuthService.getUser().user.role_id === USER_ROLES.ml;
    const orderStatus = await OrderService.getOrderStatus();
    const bulkOrderStatus = await OrderService.getOrderStatusBulk();
    this.setState({
      actions: orderStatus.data.map((action) => ({ label: action.name, value: action.id })),
      bulkactions: bulkOrderStatus.data.map((action) => ({ label: action.name, value: action.id })),
      mlHeader: isML ? 'd-none' : '',
      mlValue: isML ? 'd-none' : 'sub-text',
    });
    this.splitOrderModal = null;

    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    // if there is a search query fetch data we set the search input value
    // which will inturn triggers api request
    if (filtersFromParams.search) {
      this.setSearchInputValue(filtersFromParams.search);
    }
  }

  /**
   * Stops all promises/state changes as component is unmounting.
   * @returns {Promise<void>}
   */
  async componentWillUnmount() {
    this.setState = () => {};
  }

  // user based data from api
  dataBind = async () => {
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    this.setState({
      filter: filtersFromParams.search || '',
      loading: true,
      data: [],
    });

    const sortParams = history.location.search.substr(1);
    const sortFromParams = qs.parse(sortParams);
    if (sortFromParams.sort) {
      this.setState({
        sort: { name: sortFromParams.sort, reverse: sortFromParams.reverse === 'true' },
      });
    }
    const { page, pageSize, sort, filter } = this.state;
    if (cancel) {
      cancel();
    }

    const data = await this.debouncedSearchOrders(pageSize, page, sort, filter);
    this.setState({
      sort,
      data: data.data.orders,
      count: data.data.count,
      loading: false,
      includeSasStatus: data.data?.includeSasStatus !== false,
    });
  };

  pageChange = async (page, size) => {
    this.setState({ page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
    history.push(`?sort=${sort[0].name}&&reverse=${Boolean(sort[0].reverse)}`);
  };

  // rc-data-table doesnt let us set the value on the search input
  // in a reacty way. This function is used as a hacky workaround.
  // https://stackoverflow.com/questions/30683628/react-js-setting-value-of-input
  setNativeValue(element, value) {
    const lastValue = element.value;
    element.value = value;
    const event = new Event('input', { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    const tracker = element._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  }

  setSearchInputValue = (filter) => {
    const tableDiv = document.querySelector('#filterable-table');
    if (!tableDiv) {
      console.error('Could not find element #filterable-table');
      return;
    }
    const spanInputWrapper = tableDiv.querySelector('.filter-container');
    if (!spanInputWrapper) {
      console.error('Could not find element .filter-container');
      return;
    }
    const input = spanInputWrapper.querySelector('.filter-input');
    if (!input) {
      console.error('Could not find element .filter-input');
      return;
    }
    this.setNativeValue(input, filter);
    // onfilter will be fired by this.setNativeValue
  };

  onFilter = (rawFilter) => {
    const filter = rawFilter?.trim().replace(/\s{2,}/g, ' ') ?? '';

    // We allow re-query when empty so that a user can query for latest orders
    if (!!filter.length && this.state.filter === filter) {
      return;
    }

    const path = filter ? `/orders?search=${encodeURIComponent(filter)}` : 'orders';
    history.push(path);

    const previousFilter = this.state.filter;

    this.setState({ filter: filter || '', page: 0, data: [] }, function () {
      // We dont query if the previous filter had a value and new filter doesnt.
      // This is to prevent unintentional calls to api when the user is removing a filter
      if (!!previousFilter.length && !filter.length) {
        return;
      }

      this.dataBind();
    });
  };

  handleUserInput = (e) => this.setState({ [e.target.name]: e.target.value });

  bulkActionChange = async (props) => {
    this.setState({
      current_status: props,
      bulkselected: { label: props.value, value: props.label },
    });
    this.forceUpdate();
  };

  showModal = async () => {
    await axios.post(`${API_URL}/awaitingOrdersCount`).then((data) => {
      this.setState({ ordersInAwaiting: data.data.count });
    });
    this.setState({
      confirmModalVisible: true,
    });
  };

  onConfirmModal = () => {
    this.updateShippedStatusAllAwaiting();
    this.setState({ confirmModalVisible: false });
  };

  onCancelModal = () => {
    this.setState({ confirmModalVisible: false });
  };

  updateShippedStatusAllAwaiting = async () => {
    await axios
      .put(`${API_URL}/exportAndShipAllAwaiting`, {
        tz: moment().format('Z'),
        updateDate: moment(new Date()).valueOf(),
      })
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(() => {
        toast.error('No orders found eligible for shipment');
      });
    await this.dataBind();
    this.setState({
      loading: false,
      current_status: '',
    });
    this.forceUpdate();
  };

  // Change into shipped status
  updateShippedStatus = async (e) => {
    e.preventDefault();
    const { selectedItems } = this.state;
    this.setState({ loading: true });
    await axios
      .put(`${API_URL}/exportAndShip`, {
        orderIds: selectedItems,
        tz: moment().format('Z'),
        updateDate: moment(new Date()).valueOf(),
      })
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(() => {
        toast.error('No orders found eligible for shipment');
      });
    await this.dataBind();
    this.setState({
      loading: false,
      selectedItems: [],
      current_status: '',
    });
    this.forceUpdate();
  };

  bulkStatusUpdate = async (e) => {
    e.preventDefault();
    const { selectedItems, current_status } = this.state;
    if (!current_status) {
      toast.error('Choose a status to continue');
      return;
    }
    if (selectedItems.length === 0) {
      toast.error('Select one or more orders to proceed');
      return;
    }
    this.setState({
      loading: true,
      order_status: true,
    });
    try {
      const res = await axios.put(`${API_URL}/bulk-update/`, {
        order_status_id: current_status.value,
        items: selectedItems,
        tz: moment().format('Z'),
        order_date: moment(new Date()).valueOf(),
      });
      toast.success(res.data.message);
    } catch (err) {
      logger.error('Order status cannot be updated', err);
      toast.error('Order status cannot be updated');
    }
    await this.dataBind();
    this.setState({
      loading: false,
      order_status: false,
      selectedItems: [],
      current_status: '',
      selected: '',
      bulkselected: '',
    });
    // this.forceUpdate();
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  downloadForAdmin = () => {
    // for normal admins date range will be between one month
    this.downloadFile(
      moment().subtract(1, 'months').format('YYYY-MM-DD').toString(),
      moment().format('YYYY-MM-DD').toString(),
    );
  };

  // export as excel
  downloadFile = async (from_date = null, to_date = null) => {
    await axios({
      url: 'excel/order/download',
      data: {
        from_date,
        to_date,
      },
      method: 'POST',
    }).then((response) => {
      toast.success(response.data.message);
    });
  };

  closeCalendar = () => {
    this.setState({
      open: false,
    });
  };

  checkChange = (e) => {
    const value = parseInt(e.target.value);
    let { selectedItems } = this.state;
    if (e.target.checked) {
      selectedItems.push(value);
    } else {
      selectedItems = selectedItems.filter((x) => x !== value);
    }
    this.setState({
      selectedItems,
    });
  };

  selectAll = () => {
    if (this.state.selectedItems.length > 0) {
      this.setState({
        selectedItems: [],
      });
      return;
    }
    this.setState({
      selectedItems: this.state.data.map((x) => x.id),
    });
  };

  makeSplitOrder = async (orderId) => {
    await axios
      .get(`${API_URL}/ordered-products/${orderId}`, {
        headers: {
          prescription: true,
        },
      })
      .then((data) => {
        const selectedProducts = data.data.products;

        const orders = [];
        for (let i = 1; i <= selectedProducts.length; i++) {
          const order = { order: i, orderName: `Order ${i}`, products: [] };
          orders.push(order);
        }
        for (let i = 0; i < selectedProducts.length; i++) {
          selectedProducts[i].orderId = 1;
          orders[0].products.push(selectedProducts[i].product_id);
        }

        const orderNumber = orders.length + 1;
        const inactiveProducts = data.data.OrderProducts.filter(
          (op) => !op.Product.active || op.Product.is_out_of_stock,
        ).map((item) => ({
          ...item,
          orderId: orderNumber,
          status: settings.productStatus.inactive,
          product_name: item.Product.name,
        }));

        // add inactive products
        if (inactiveProducts.length) {
          orders.push({
            order: orderNumber,
            orderName: `Order ${orderNumber}`,
            products: inactiveProducts.map((p) => p.product_id),
            is_out_of_stock: true,
          });
          selectedProducts.push(
            ...inactiveProducts.map((p) => ({
              product_id: p.product_id,
              product_name: p.Product.name,
              quantity: p.quantity,
              status: settings.productStatus.inactive,
              orderId: orderNumber,
            })),
          );
        }

        this.setState(
          {
            selectedProducts,
            splitOrders: orders,
            selectedOrder: this.state.data.find((x) => x.id === orderId),
            showSplitOrderModal: true,
            inactiveProducts,
          },
          () => {
            this.showSplitOrderModal();
          },
        );
      });
  };

  getUnableToDispenseInfo = async (orderId) => {
    await getUnableToDispenseInfo(orderId)
      .then((unableToDispenseData) => {
        this.setState(
          {
            unableToDispenseData,
          },
          () => {
            this.showUnableToDispenseModal();
          },
        );
      })
      .catch((err) => {
        logger.error('getUnableToDispenseInfo', err);
        toast.error(err.message);
      });
  };

  showSplitOrderModal = () => {
    const element = document.getElementById('splitOrderModal');
    if (!element) return;
    this.splitOrderModal = new Modal(element, {
      backdrop: false,
      keyboard: false,
    });
    this.splitOrderModal.show();
  };

  showUnableToDispenseModal = () => {
    const element = this.unableToDispenseRef.current;
    if (!element) return;
    this.UnableToDispenseModal = new Modal(element, {
      backdrop: false,
      keyboard: false,
    });
    this.UnableToDispenseModal.show();
  };

  hideSplitOrderModal = () => {
    this.setState({
      showSplitOrderModal: false,
      selectedProducts: [],
    });
    this.splitOrderModal.hide();
    this.dataBind();
  };

  hideUnableToDispenseModal = () => {
    this.setState({
      unableToDispenseData: null,
    });
    this.UnableToDispenseModal.hide();
  };

  handleOrderSelect = (e, product) => {
    const Orders = this.state.splitOrders;
    const idx = Orders.findIndex((itm) => itm.order === e.value);
    Orders[idx].products.push(product.product_id);

    const idx1 = Orders.findIndex((itm) => itm.order === product.orderId);
    Orders[idx1].products.splice(
      Orders[idx1].products.findIndex((itm) => itm === product.product_id),
      1,
    );

    const products = this.state.selectedProducts;
    const pidx = products.findIndex((itm) => itm.product_id === product.product_id);
    products[pidx].orderId = e.value;

    this.setState({
      selectedProducts: products,
      splitOrders: Orders,
    });
  };

  getStatusForSplitOrder = (id) => {
    const order = this.state.data.find((x) => x.id === id);
    const statusId = order.order_status_id;
    const result = false;
    const statusIds = [13, 16, 20, 22, 28];
    if (order.payment_status !== 'INVOICED' && statusIds.findIndex((id) => id === statusId) >= 0) {
      return true;
    }
    if (
      order.payment_status !== 'INVOICED' &&
      [7, 8, 9, 10, 11, 12, 15, 17, 18, 19, 21, 24, 27].indexOf(statusId) === -1
    ) {
      return true;
    }
    return result;
  };

  saveSplitOrders = async () => {
    this.setState({ disabled: true });
    const orders = [];
    for (const idx in this.state.splitOrders) {
      if (this.state.splitOrders[idx].products.length > 0) {
        orders.push(this.state.splitOrders[idx]);
      }
    }
    const data = { orderId: this.state.selectedOrder.id, orders };
    this.setState({ loading: true });

    const toastError = () => {
      toast.error('Failed to Split order');
      logger.error('Failed to split order');
    };

    try {
      const order = await axios.post(`${API_URL}/v1/splitOrder`, data);
      if (order) {
        // status === 0 means the order can't be split
        // status === 1 means the order is split successfully
        toast[order.data.status === 1 ? 'success' : 'warn'](order.data.message);
      } else {
        // Something wrong if no order returned
        toastError();
      }
    } catch (err) {
      toastError();
    }

    this.splitOrderModal.hide();
    this.setState({ showSplitOrderModal: false, selectedProducts: [], disabled: false }, function () {
      this.dataBind();
    });
  };

  refillOrder = (id) => {
    this.props.history.push(`/order/${id}/refill`);
  };

  isRefillPermittedForOrder = (id) => {
    const order = this.state.data.find((x) => x.id === id);
    return !order?.is_discharge;
  };

  /**
   * Retrieves the list of bulk actions based on user permissions.
   *
   * @returns {Array} The array of bulk actions.
   */
  getBulkActions = () => {
    const { bulkactions } = this.state;

    // Filter bulk actions based on user role
    if (!AuthService.isSuperAdmin()) {
      return bulkactions.filter((option) => option.value !== settings.orderStatus.AWAITING_DISPENSE);
    }

    return bulkactions;
  };

  render() {
    const role = AuthService.getUser().user.role_id;

    const {
      loading,
      data,
      bulkselected,
      count,
      pageSize,
      page,
      sort,
      filter,
      selectedItems,
      includeSasStatus,
      selectedProducts,
      splitOrders,
      selectedOrder,
      disabled,
      inactiveProducts,
    } = this.state;

    const fields = [
      { name: 'id', displayName: 'id', visible: false, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'order_code',
        displayName: 'Order #',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) =>
          role === 2 ? (
            `${e.value}`
          ) : (
            <Link to={`/order/${e.record.id}`} target="_blank">
              {e.value}
            </Link>
          ),
      },
      {
        name: 'order_date',
        displayName: 'Date',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text inc-width',
        sortFieldName: 'order_date',
        render: (e) => <span>{moment(e.value).format('DD-MMM-YYYY')}</span>,
      },
      {
        name: 'patient_name',
        displayName: 'Patient',
        inputFilterable: true,
        tdClassName: 'sub-text',
        sortable: true,
        sortFieldName: 'Patient.PatientUser.first_name',
      },
      {
        name: 'patient_id',
        displayName: 'Patient ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) => <Link to={`/onboard/${e.record.patient_uniq_id}`}>{e.value}</Link>,
      },
      {
        name: 'patient_state',
        displayName: 'Patient State',
        inputFilterable: true,
        sortable: false,
        tdClassName: 'sub-text',
        render: (e) => <span>{e.record.patient_address_state_abbr}</span>,
      },
      {
        name: 'pharmacy_id',
        displayName: 'Pharmacy ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'Pharmacy.identifier_name',
        render: (e) => <span>{e.record.identifier_name}</span>,
      },
      {
        name: 'product_name',
        displayName: 'Product',
        inputFilterable: true,
        tdClassName: 'sub-text',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record?.product_name.map((x, idx) => (
              <li key={idx + e.record.id}>{x}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'quantity',
        displayName: 'Qty',
        inputFilterable: true,
        tdClassName: 'sub-text text-center',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record.quantity.map((x, idx) => (
              <li key={idx + e.record.id}>{x}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'pharmacy_name',
        displayName: 'Pharmacy',
        thClassName: AuthService.isAdmin() ? 'd-none' : '',
        inputFilterable: true,
        sortable: true,
        tdClassName: AuthService.isAdmin() ? 'd-none' : 'sub-text',
        sortFieldName: 'Pharmacy.name',
      },
      {
        name: 'gp_name',
        displayName: 'Doctor',
        thClassName: role === 2 ? 'd-none' : '',
        inputFilterable: true,
        sortable: true,
        tdClassName: role === 2 ? 'd-none' : 'sub-text',
        sortFieldName: 'GeneralPractitioner.Doctor.first_name',
        render: (e) =>
          e.record.client_id === 1 ? (
            <ul style={{ listStyle: 'none' }}>
              {e.record.prescriber_details.map((x, idx) => (
                <Link to={`/gp-register/${x.docUserId}`} key={idx}>
                  {x.name} <br />
                </Link>
              ))}
            </ul>
          ) : (
            <Link to={`/gp-register/${e.record.gp_uniq_id}`}>{e.record.gp_name}</Link>
          ),
      },
      {
        name: 'payment_status',
        displayName: 'Payment',
        inputFilterable: true,
        sortable: false,
        tdClassName: 'sub-text',
      },
      includeSasStatus
        ? {
            name: 'sasStatus',
            displayName: 'Approval',
            inputFilterable: true,
            tdClassName: 'sub-text ',
            render: (e) => (
              <ul style={{ listStyle: 'none' }}>
                {e.record.sasStatus.map((x, idx) => (
                  <li key={`${e.record.id}-approval-${idx}`}>{x}</li>
                ))}
              </ul>
            ),
          }
        : null,
      {
        name: 'shipped_date',
        displayName: 'Shipped',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text inc-width text-center',
        sortFieldName: 'shipped_date',
        render: (e) => (
          <span>
            {moment(e.record.shipped_date).isValid() ? moment(e.record.shipped_date).format('DD-MMM-YYYY') : '-'}
          </span>
        ),
      },
      {
        name: 'orderStatus',
        displayName: 'Status',
        tdClassName: 'sub-text',
        inputFilterable: true,
        sortFieldName: 'OrderStatus.name',
        sortable: true,
        render: (e) =>
          e.record.order_status_id === settings.orderStatus.UNABLE_TO_DISPENSE ? (
            <div style={{ display: 'flex', width: '87px' }}>
              <a onClick={() => this.getUnableToDispenseInfo(e.record.id)} style={{ paddingRight: '4px' }}>
                <BsInfoCircle style={{ marginTop: '14px' }} />
              </a>
              <span>{e.record.orderStatus}</span>
            </div>
          ) : (
            <>
              {e.record.orderStatus === OrderStatus.Refunded ? (
                <>
                  {OrderStatus.Cancelled}
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}>
                    <Chip color="primary" label={e.record.orderStatus} />
                  </div>
                </>
              ) : (
                `${e.record.orderStatus}`
              )}

              {e.record.orderStatus === OrderStatus.Cancelled || e.record.orderStatus === OrderStatus.Refunded ? (
                <div>{moment(e.record.modified_date).format('DD-MMM-YYYY')}</div>
              ) : null}
            </>
          ),
      },
      {
        name: 'dispensed_date',
        displayName: 'Dispensed',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text inc-width',
        sortFieldName: 'dispensed_date',
        render: (e) => <span>{moment(e.record.dispensed_date).isValid() ? e.record.dispensed_date : '-'}</span>,
      },
      {
        name: 'consignment_code',
        displayName: 'Consignment',
        tdClassName: AuthService.isAdmin() ? 'sub-text text-center' : 'd-none',
        thClassName: AuthService.isAdmin() ? '' : 'd-none',
        render: (orderRow) => {
          const shipmentTrackingId = orderRow.record.shipment_tracking_id;
          const shipmentCarrier = orderRow.record.shipment_carrier;

          if (!shipmentTrackingId || shipmentTrackingId === '-' || !shipmentCarrier) {
            return orderRow.value;
          }

          let trackingUrl;
          switch (shipmentCarrier) {
            case settings.shipmentCarrier.ausPost:
              trackingUrl = `${settings.ausPostTrackingUrl}${shipmentTrackingId}`;
              break;
            case settings.shipmentCarrier.shippit:
              trackingUrl = `${settings.shippitTrackingUrl}${shipmentTrackingId}`;
              break;
          }

          return (
            <a href={trackingUrl} className="tracking-id" target="_blank" rel="noopener noreferrer">
              {orderRow.value}
            </a>
          );
        },
      },
      // eslint-disable-next-line react/display-name
      {
        name: 'id',
        displayName: '',
        thClassName: 'text-center',
        tdClassName: 'sub-text text-center',
        render: (e) => (
          <div style={{ display: 'flex' }}>
            <a>
              {this.isRefillPermittedForOrder(e.value) ? (
                <img
                  src={refillIcon}
                  alt="refill_icon"
                  className="prescriptionIcon"
                  onClick={() => this.refillOrder(e.value)}
                />
              ) : (
                <img src={refillIcon} alt="refill_icon" className="prescriptionIconDisabled" />
              )}
            </a>
            {role === 2 ? (
              ''
            ) : (
              <a>
                {this.getStatusForSplitOrder(e.value) ? (
                  <img
                    src={splitIcon}
                    alt="split_icon"
                    className="prescriptionIcon"
                    onClick={() => this.makeSplitOrder(e.value)}
                  />
                ) : (
                  <img src={splitIcon} alt="split_icon" className="prescriptionIconDisabled" />
                )}
              </a>
            )}
            {AuthService.isAdmin() ? (
              <div style={{ paddingTop: '3px' }} className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={selectedItems.includes(e.value)}
                  onChange={this.checkChange}
                  value={e.value}
                  id={`check${e.value}`}
                />
                <label className="custom-control-label" htmlFor={`check${e.value}`} />
              </div>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ].filter((x) => !!x);

    const menuPortalTarget = document.getElementById('splitOrderModal');
    const customStyles = {
      content: {
        width: '35%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    return (
      <div>
        <div className="container-fluid">
          <h3>Orders</h3>
          <div>
            {AuthService.isAdmin() && (
              <div>
                <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
                  <div className="d-flex mt-3 mt-lg-0">
                    <Button
                      text=" Export & Ship All Awaiting"
                      className="btn btn-md btn-warning mx-1"
                      onClick={this.showModal}
                    />
                    {filter.toLowerCase() === 'awaiting shipment' && (
                      <Button text="Select All" className="btn btn-md btn-primary mx-1" onClick={this.selectAll} />
                    )}
                    <div className="d-flex justify-content-end">
                      {AuthService.isAdmin() && AuthService.isSuperAdmin() ? (
                        <Button text="Export" className="btn btn-md btn-primary mx-1" onClick={this.handleClick} />
                      ) : (
                        <Button text="Export" className="btn btn-md btn-primary mx-1" onClick={this.downloadForAdmin} />
                      )}
                      {this.state.open && (
                        <div className="Order-Export ">
                          <DateRangeComp open closeCalendar={this.closeCalendar} onChange={this.downloadFile} />{' '}
                        </div>
                      )}
                    </div>
                    {this.state.selectedItems.length > 0 ? (
                      <Button
                        text="Export & Ship"
                        className="btn btn-md btn-primary mx-1"
                        onClick={this.updateShippedStatus}
                      />
                    ) : null}
                  </div>
                  <div className="form-inline bulk-update mr-5">
                    <Select
                      name="bulkactions"
                      value={bulkselected ? { label: bulkselected.value, value: bulkselected.label } : ''}
                      isSearchable
                      placeholder="Select"
                      className={`select-field mx-1 ${this.state.order_status ? 'rounded dropdown-border' : ''}`}
                      options={this.getBulkActions()}
                      onChange={this.bulkActionChange}
                    />
                    <Button
                      text="Change status"
                      className="btn btn-md btn-primary mx-1"
                      onClick={this.bulkStatusUpdate}
                    />
                  </div>
                </div>
                <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
                  <p className="mr-5 mb-0" style={{ fontSize: 10 }}>
                    Selected: {selectedItems.length}
                  </p>
                </div>
              </div>
            )}

            <Stack>
              <Stack direction="row" className="mt-2" justifyContent="flex-end" spacing={2}>
                <Box className="mt-2">
                  <FilterByOrderStatusSelect
                    statuses={Object.values(OrderStatus)}
                    selectedStatus={this.state.filter}
                    onSelect={this.setSearchInputValue}
                  />
                </Box>
                {this.state.role_id === USER_ROLES.superAdmin && (
                  <Box className="mr-5 mt-2">
                    <FilterOrderByPharmacySelect
                      onSelect={(value) => {
                        this.setState({ ...data, pharmacyFilter: value });
                        this.dataBind();
                      }}
                      selectedPharmacy={this.state.pharmacyFilter}
                    />
                  </Box>
                )}
              </Stack>
              {/* this div is used as a selector in setSearchInputValue() */}
              <div id="filterable-table">
                <FilterableTable
                  className={
                    AuthService.isAdmin()
                      ? 'table-responsive w-100 p-2'
                      : 'table-responsive d-lg-table' && data.patient_pharmacy_name !== null
                      ? 'preferred-pharmacy'
                      : ''
                  }
                  data={data}
                  fields={fields}
                  paginationOnServer
                  initialSort={sort.name}
                  initialSortDir={!sort.reverse}
                  onPageChanged={this.pageChange}
                  filterData={this.onFilter}
                  serverSort={this.getSortColumn}
                  totalRecords={count}
                  currentPage={page}
                  pageSize={pageSize}
                  filterPlaceholder="Search"
                  pageSizes={false}
                  maintainPageOnSetData
                  topPagerVisible={false}
                  noRecordsMessage={
                    loading
                      ? 'Loading...'
                      : filter
                      ? 'There are no orders matching your search criteria.'
                      : "To search for orders, simply enter your search term in the search bar above. For the most recent orders, perform an empty search by clicking the 'x' icon."
                  }
                />
              </div>
            </Stack>
          </div>
        </div>

        {/* Export and ship all awaiting modal */}
        <CustomModal isOpen={this.state.confirmModalVisible} style={customStyles}>
          <>
            <div className="modalheader">
              <h3>Confirm Export & Ship</h3>
            </div>
            <div className="modalbody">{`${this.state.ordersInAwaiting} order/s will be shipped. Are you sure you want to proceed?`}</div>
            <div className="modalfooter">
              <Button
                text="Confirm"
                type="submit"
                className="btn btn-primary float-right mt-5"
                onClick={this.onConfirmModal}
              />
              <Button
                text="Cancel"
                type="submit"
                className="btn btn-outline-primary float-right mt-5 mr-3"
                onClick={this.onCancelModal}
              />
            </div>
          </>
        </CustomModal>
        <SplitOrderModal
          menuPortalTarget={menuPortalTarget}
          hideSplitOrderModal={this.hideSplitOrderModal}
          selectedProducts={selectedProducts}
          splitOrders={splitOrders}
          handleOrderSelect={this.handleOrderSelect}
          saveSplitOrders={this.saveSplitOrders}
          selectedOrder={selectedOrder}
          disabled={disabled}
          inactiveProducts={inactiveProducts}
        />
        <UnableToDispenseModal
          unableToDispenseRef={this.unableToDispenseRef}
          unableToDispenseData={this.state.unableToDispenseData}
          hideUnableToDispenseModal={this.hideUnableToDispenseModal}
        />
      </div>
    );
  }
}

export default orderView;
