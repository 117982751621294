/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum OrderStatus {
  AwaitingPayment = 'Awaiting Payment',
  AwaitingShipment = 'Awaiting Shipment',
  AwaitingDispensing = 'Awaiting Dispensing',
  AwaitingToken = 'Awaiting Token',
  Completed = 'Completed',
  Dispensed = 'Dispensed',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Test = 'Test',
  Error = 'Error',
  Expired = 'Expired',
  ApprovedNotPaid = 'Approved / Not Paid',
  PaidNotApproved = 'Paid / Not Approved',
  PharmacyOutOfStock = 'Pharmacy out of Stock',
  PMSDeleted = 'PMS deleted',
  OutOfRepeats = 'Out of Repeats',
  IntervalTime = 'Interval Time',
  NoPharmacy = 'No Pharmacy',
  PharmacyPartiallyOutOfStock = 'Pharmacy Partially out of Stock',
  OutOfStockReasoning = 'Out of stock - Reasoning',
  ReScript = 'Re-Script',
  OutOfStock = 'Out of Stock',
  PartiallyOutOfStock = 'Partially Out of Stock',
  UnableToDispense = 'Unable to Dispense',
  ProcessingDispense = 'Processing Dispense',
  PMSPatientProfileIncomplete = 'PMS Patient Profile Incomplete',
  Refunded = 'Refunded',
}
