import { styled } from '@mui/material';

import MontuIcon from '@/components/common/MontuIcon';
import MontuLogo from '@/components/common/MontuLogo';
import UmedsIcon from '@/components/common/UmedsIcon';
import UmedsLogo from '@/components/common/UmedsLogo';
import { FF_POWERED_BY_UMEDS } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const MontuLogoWithSx = styled(MontuLogo)({});
const MontuIconWithSx = styled(MontuIcon)({});
const UmedsLogoWithSx = styled(UmedsLogo)({});
const UmedsIconWithSx = styled(UmedsIcon)({});

export const PartnerKey = {
  MONTU: 'montu',
  UMEDS: 'uMeds',
} as const;

const PartnerComponents = {
  [PartnerKey.UMEDS]: {
    name: 'uMeds',
    Logo: UmedsLogoWithSx,
    Icon: UmedsIconWithSx,
  },
  [PartnerKey.MONTU]: {
    name: 'Montu',
    Logo: MontuLogoWithSx,
    Icon: MontuIconWithSx,
  },
} as const;

export function usePortalPartnerBrand() {
  const { flags } = useFeatureFlags();
  const ffPoweredByUmeds = Boolean(flags[FF_POWERED_BY_UMEDS] ?? ffDefaults[FF_POWERED_BY_UMEDS]);

  const key = ffPoweredByUmeds ? PartnerKey.UMEDS : PartnerKey.MONTU;
  const components = PartnerComponents[key];

  return { key, ...components };
}

export default usePortalPartnerBrand;
