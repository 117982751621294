import { useHistory } from 'react-router-dom';
import { BrandThemeProvider } from '@montugroup/themes';
import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import AltLogo from '@/components/common/AltLogo';

const AlternaleafLogoWithSx = styled(AltLogo)({});

const ParentHeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 32),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

function ParentHeader() {
  const history = useHistory();

  return (
    <BrandThemeProvider variant="alternaleaf">
      <ParentHeaderContainer>
        <IconButton
          disableRipple
          size="small"
          sx={{
            padding: 0,
            '&.MuiButtonBase-root:focus': {
              outline: 'none',
            },
          }}
          onClick={() => history.push({ pathname: '/home' })}
        >
          <ChevronLeft color="primary" fontSize="large" sx={{ marginRight: 2 }} />
          <AlternaleafLogoWithSx
            fillColor="currentColor"
            sx={{ color: (theme) => theme.palette.primary.main }}
            width="150"
          />
        </IconButton>
      </ParentHeaderContainer>
    </BrandThemeProvider>
  );
}

export default ParentHeader;
