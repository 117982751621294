import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ChevronRightOutlined,
  Circle as CircleIcon,
  NorthEast as NorthEastIcon,
} from '@mui/icons-material';
import { Alert, Box, Card, styled, Typography } from '@mui/material';

import BrainfishHelpDeskPopup from '@/components/brainfish/HelpDeskPopup';
import CircleLoader from '@/components/common/circleLoader';
import CurvedUnderline from '@/components/common/CurvedUnderline';
import MontuLogo from '@/components/common/MontuLogo';
import OrderTrackingStatus from '@/components/patient/orderTrackingStatus/OrderTrackingStatus';
import { skyBlue, teal } from '@/components/products/detail/common';
import { FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION, FF_ENABLE_WOM_CAMPAIGN } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import MontuTheme from '@/theme';
import { GoogleAnalyticsEventName } from '@/types';
import { Logger } from '@/utils/logger';

import PatientReferralView from '../../components/patientReferral/patientReferralView';
import { AuthService } from '../../services/authentication.service';
import { getPatientOrderTrackingStatus, PatientOrderTrackingStatus } from '../../services/patient.service';

const logger = new Logger('patientHome');

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  height: '100%',
  gridTemplateRows: 'auto',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const ContentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBottom: '1.5rem',
  zIndex: '1',
  position: 'relative',
}));

const BgContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    position: 'fixed',
    minWidth: '50%',
    height: '100%',
    right: '0',
    '> img': {
      objectFit: 'cover',
      objectPosition: '0 25%',
    },
  },
}));

const BaseContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '0 2rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 10rem',
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: '0',
  },
}));

const ToastContainer = styled(BaseContainer)(({ theme }) => ({
  margin: '0 0 1.5rem 0',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    marginTop: '1.5rem',
  },
}));

const TextContainer = styled(BaseContainer)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const LinkContainer = styled(BaseContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1.5rem',
  margin: '3.75rem 0 0',
  gap: '1rem',
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto',
    gridGap: '2rem',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '6.25rem',
  },
}));

const HeadingContainer = styled(Box)`
  width: 100%;
  display: flex;
`;

const HeadingGreetingWrapper = styled(Box)`
  width: min-content;
  display: flex;
  flex-direction: column;

  > svg {
    margin-top: -1%;
    margin-left: -3px;
  }
`;

const Underline = styled('span')(({ theme }) => ({
  width: 'min-content',
  display: 'inline-flex',
  flexDirection: 'column',
  fontSize: '1.5rem',
  color: theme.palette.secondary.main,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.7rem',
    lineHeight: '1.5',
  },
  '> svg': {
    marginTop: '-5px',
  },
}));

const HeadingGreeting = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'fit-content',
  fontSize: '3.2rem',
  color: `${theme.palette.secondary.main} !important`,
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4.25rem',
  },
}));

const HeadingName = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '3.2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4.25rem',
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  width: '100%',
  marginTop: '0.8rem',
  fontSize: '1.5rem',
  fontWeight: '500',
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
}));

const PageLink = styled(Link)(({ theme }) => ({
  borderRadius: '20px',
  '&:hover': {
    textDecoration: 'none',
  },
  '&.link-tile-0': {
    backgroundColor: skyBlue,
    color: theme.palette.primary.main,
  },
  '&.link-tile-1': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&.link-tile-2': {
    backgroundColor: teal,
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 2',
    },
  },
}));

const LinkCard = styled(Card)(({ theme }) => ({
  padding: '1.4rem',
  backgroundColor: 'transparent',
  color: 'inherit',
  borderRadius: 'inherit',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '1.6rem',
  },
}));

const LinkTextWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const LinkText = styled(Typography)(({ theme }) => ({
  width: '85%',
  fontSize: '1.5rem',
  fontWeight: '500',
  display: 'flex',
  flexDirection: 'column',
  color: 'inherit',
  [theme.breakpoints.up('lg')]: {
    width: '90%',
    fontSize: '1.7rem',
    lineHeight: '1.5',
  },
}));

const LinkTextFooter = styled('span')(({ theme }) => ({
  width: '100%',
  fontSize: '0.9rem',
  paddingTop: '0.5rem',
  [theme.breakpoints.up('lg')]: {
    width: '80%',
    fontSize: '1rem',
  },
}));

const StyledArrowForwardIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: '0.5rem',
}));

const NorthEastIconWrapper = styled(Box)`
  position: relative;
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
`;

const StyledNorthEastIcon = styled(NorthEastIcon)`
  color: ${teal} !important;

  position: absolute;
  right: 25%;
  top: 25%;
  width: 50%;
  height: 50%;
  text-align: center;
`;

const StyledCircleIcon = styled(CircleIcon)`
  color: ${MontuTheme.colorSchemes.light.palette.secondary.main}; // direct access to theme required
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export default function PatientHome() {
  const [orderData, setOrderData] = useState<PatientOrderTrackingStatus | false>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const user = AuthService.getUser()?.user;
  const { flags } = useFeatureFlags();
  const showOrderTrackingNotification = flags[FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION];
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (user && user.id) {
      try {
        datadogRum.setUser({
          id: user.id.toString(),
        });
        logger.debug('DD RUM :: setUser was successful.', user.id);
      } catch (error) {
        logger.error('DD RUM :: Error setting user key', error);
      }
    }
  }, [user?.id]);

  useEffect(() => {
    if (showOrderTrackingNotification) {
      getPatientOrderTrackingStatus().then((res) => {
        setOrderData(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [showOrderTrackingNotification]);

  const data = [
    {
      id: 0,
      para: (
        <span>
          <span>Book a follow up&nbsp;</span>
          <Underline>
            consultation
            <CurvedUnderline width="100%" height="100%" strokeWidth="3" />
          </Underline>
        </span>
      ),
      footer: false,
      hyperLink: '/patient/consultations',
      icon: <StyledArrowForwardIcon />,
    },
    {
      id: 1,
      para: (
        <span>
          <Underline>
            Browse&nbsp;
            <CurvedUnderline width="100%" height="100%" strokeWidth="4" />
          </Underline>
          <span>formulations our doctors prescribe</span>
        </span>
      ),
      footer: false,
      hyperLink: '/product-catalog',
      icon: <StyledArrowForwardIcon />,
    },
    {
      id: 2,
      para: <span>Order my medication</span>,
      footer: true,
      hyperLink: '/patient/refill',
      icon: (
        <NorthEastIconWrapper>
          <StyledCircleIcon />
          <StyledNorthEastIcon />
        </NorthEastIconWrapper>
      ),
      includeBranding: true,
    },
  ];

  return (
    <>
      {flags[FF_ENABLE_WOM_CAMPAIGN] && <PatientReferralView />}
      <MainContainer>
        <ContentContainer>
          {loading ? (
            <CircleLoader />
          ) : (
            <>
              {showOrderTrackingNotification && orderData ? (
                <ToastContainer>
                  <OrderTrackingStatus {...orderData} />
                </ToastContainer>
              ) : (
                <TextContainer>
                  <Alert
                    sx={{
                      margin: '2rem 0',
                      '.MuiAlert-message': {
                        width: '100%',
                      },
                    }}
                  >
                    <Link
                      to="/patient/orders"
                      onClick={() =>
                        sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.LINK_CLICK, {
                          event_category: 'view_order',
                          event_label: 'view_my_orders',
                        })
                      }
                    >
                      <Typography display="flex" justifyContent="space-between" fontWeight={700}>
                        View my orders <ChevronRightOutlined />
                      </Typography>
                    </Link>
                  </Alert>
                </TextContainer>
              )}

              <TextContainer>
                <HeadingContainer>
                  <HeadingGreetingWrapper>
                    <HeadingGreeting fontWeight={500} variant="h2">
                      Hello
                    </HeadingGreeting>
                    <CurvedUnderline width="98%" height="100%" />
                  </HeadingGreetingWrapper>

                  {user && (
                    <HeadingName fontWeight={500} variant="h2">
                      &nbsp;{user.first_name}
                    </HeadingName>
                  )}
                </HeadingContainer>

                <SubHeading variant="h5">How can we help you today?</SubHeading>
              </TextContainer>

              <LinkContainer data-dd-privacy="allow">
                {data.map((ele) => (
                  <PageLink key={ele.id} data-testid="page-link" to={ele.hyperLink} className={`link-tile-${ele.id}`}>
                    <LinkCard>
                      <LinkTextWrapper>
                        <LinkText variant="body1">
                          {ele.para}

                          {ele.includeBranding ? (
                            <LinkTextFooter>
                              Powered by&nbsp;
                              <MontuLogo />
                            </LinkTextFooter>
                          ) : (
                            ''
                          )}
                        </LinkText>
                        {ele.icon}
                      </LinkTextWrapper>
                    </LinkCard>
                  </PageLink>
                ))}
              </LinkContainer>
            </>
          )}
        </ContentContainer>

        <BgContainer className="home-bg-container">
          <img width="100%" height="100%" src="/assets/images/alt_home_bg.png" alt="Circuit" />
        </BgContainer>
      </MainContainer>
      <BrainfishHelpDeskPopup />
    </>
  );
}
