import { apiPatientPortalContract } from '@montugroup/pms-api-contracts';

import TsRestClient from './ts-rest.client';

let cachedClient: TsRestClient<typeof apiPatientPortalContract> | null = null;

export const getTsPatientPortalClient = () => {
  if (!cachedClient) {
    cachedClient = new TsRestClient(apiPatientPortalContract);
  }
  return cachedClient.getClient();
};

export default null;
