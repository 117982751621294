import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import CheckBoxSharp from '@mui/icons-material/CheckBoxSharp';
import UploadFile from '@mui/icons-material/UploadFile';
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';

import { DropdownOption, InputField, SupportTaskProps } from '@/components/supportSelfService/types';
import settings from '@/constants/constants';
import { viteAppBaseUrl } from '@/constants/env';
import { buildFormDataRequestBody, buildIdFromEndpoint, buildJsonRequestBody } from '@/utils/supportTasks';

function TextFeildSelfService({
  box,
  inputs,
  handleInputChange,
}: {
  box: InputField;
  inputs: Record<string, string>;
  handleInputChange: (property: string, value: string) => void;
}) {
  return (
    <Grid item xs={12} key={box.property}>
      <TextField
        fullWidth
        label={box.label}
        variant="outlined"
        value={inputs[box.property]}
        onChange={(e) => handleInputChange(box.property, e.target.value)}
      />
    </Grid>
  );
}

function DropdownSelfService({
  dropdown,
  inputs,
  handleInputChange,
}: {
  dropdown: DropdownOption;
  inputs: Record<string, string>;
  handleInputChange: (property: string, value: string) => void;
}) {
  return (
    <Grid item xs={12} key={dropdown.property}>
      <FormControl fullWidth>
        <InputLabel>{dropdown.label}</InputLabel>
        <Select
          value={inputs[dropdown.property]}
          label={dropdown.label}
          onChange={(e) => handleInputChange(dropdown.property, e.target.value)}
        >
          {dropdown.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

function UploadButtonSelfService({
  file,
  setFile,
}: {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
}) {
  const VisuallyHiddenInput = styled('input')({
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const csv = event?.target?.files?.[0];
    if (csv) {
      setFile(csv);
    }
  };

  return (
    <Grid item xs={12}>
      <Button
        component="label"
        role="button"
        variant="contained"
        tabIndex={-1}
        startIcon={file ? <CheckBoxSharp /> : <UploadFile />}
      >
        Upload csv
        <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".csv" />
      </Button>
    </Grid>
  );
}

interface SupportTaskVisualProps {
  highlight?: boolean;
}

function SupportTask({
  title,
  description,
  inputTextFields,
  dropdowns,
  uploadCSV,
  endpoint,
  requestType,
  highlight,
}: SupportTaskProps & SupportTaskVisualProps) {
  const [inputs, setInputs] = useState(() => {
    const initialState: Record<string, string> = {};
    inputTextFields?.forEach((box) => {
      initialState[box.property] = '';
    });
    dropdowns?.forEach((dropdown) => {
      initialState[dropdown.property] = dropdown.options[0]?.value || '';
    });
    return initialState;
  });

  const [file, setFile] = useState<File | null>(null);

  const [alert, setAlert] = useState<{
    show: boolean;
    severity: AlertColor | undefined;
    msg: string;
  }>({
    show: false,
    severity: 'success',
    msg: '',
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (property: string, value: string) => {
    setInputs((prev) => ({ ...prev, [property]: value }));
    setAlert({ ...alert, show: false });
  };

  const handleSubmit = async () => {
    const allInputs = [...(inputTextFields || []), ...(dropdowns || []), ...(uploadCSV ? [uploadCSV] : [])];

    const mandatoryInputValues = Object.keys(inputs)
      .filter((key) => allInputs.some((input) => input.property === key && !input.optional))
      .map((key) => inputs[key]);

    if (mandatoryInputValues.some((value) => !value)) {
      setAlert({ ...alert, show: true, severity: 'error', msg: 'Please fill all the fields.' });
      return;
    }

    setLoading(true);

    try {
      const requestBody = buildJsonRequestBody(inputs, inputTextFields, dropdowns);
      const data = file ? buildFormDataRequestBody(requestBody, file) : requestBody;
      const contentTypeHeader = file ? 'multipart/form-data' : 'application/json';
      // Allow full url overrides for endpoints during transition to xapi
      const baseURL = viteAppBaseUrl();
      const url = endpoint.includes('/api/') ? endpoint : `${settings.url}${endpoint}`;
      // do something with the axios response? the responses are not all aligned in format, so not sure this is worth it right now
      // mostly just interested in fixing this during our own testing phase on prod before we release to the wider montu org
      const res = await axios({
        method: requestType,
        baseURL,
        url,
        data,
        headers: { 'Content-Type': contentTypeHeader },
      });

      const hasStatusInResponse = typeof res.data?.status === 'number';
      let severity: AlertColor = 'success';

      // Support legacy endpoints that always return a 2xx http status but include it in the response
      if (hasStatusInResponse) {
        severity = [200, 204].includes(res.data.status) ? 'success' : 'error';
      }

      // temporarily showing the raw response until we have better support
      setAlert({ show: true, severity, msg: JSON.stringify(res.data) });
    } catch (error) {
      // bad type, not even sure it works in all cases
      // mostly just interested in fixing this during our own testing phase on prod before we release to the wider montu org
      const e = error as unknown as { message: string };
      setAlert({ show: true, severity: 'error', msg: e?.message || 'Unknown error' });
    } finally {
      setLoading(false);
    }
  };

  const id = buildIdFromEndpoint(endpoint);

  return (
    <Paper
      key={id}
      id={id}
      sx={(theme) => ({
        p: 4,
        transition: 'box-shadow 0.3s ease-in-out',
        boxShadow: highlight ? theme.shadows[8] : theme.shadows[2],
      })}
      elevation={highlight ? 8 : 2}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
      )}

      {alert.show && (
        <Fade in={alert.show} timeout={{ enter: 1000, exit: 1000 }}>
          <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })} sx={{ my: 4 }}>
            <AlertTitle>{alert.severity?.toUpperCase()}</AlertTitle>
            <Box
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {alert.msg}
            </Box>
          </Alert>
        </Fade>
      )}

      <Grid container spacing={4}>
        {inputTextFields?.map((box) => (
          <TextFeildSelfService box={box} inputs={inputs} handleInputChange={handleInputChange} />
        ))}

        {dropdowns?.map((dropdown) => (
          <DropdownSelfService dropdown={dropdown} inputs={inputs} handleInputChange={handleInputChange} />
        ))}

        {uploadCSV && <UploadButtonSelfService file={file} setFile={setFile} />}
      </Grid>

      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleSubmit}
        disabled={loading}
        sx={{ my: 4 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </Paper>
  );
}

export default SupportTask;
