import { useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { AuthService } from '@/services/authentication.service';
import { changeUser } from '@/services/braze.service';
import { UserResponse } from '@/types/user.types';
import identifyUserForLaunchDarkly from '@/utils/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

interface PharmacistLoginFormData {
  email: string;
  password: string;
  remember: boolean;
  loginType: string;
}

const logger = new Logger('PharmacistSignInForm');

function usePharmacistLogin() {
  const [loading, setLoad] = useState<boolean>(false);
  const ldClient = useLDClient();

  const login = async (data: PharmacistLoginFormData, onSuccess?: () => void) => {
    setLoad(true);
    let userResponse: UserResponse | undefined;
    try {
      userResponse = await AuthService.login(
        data.email.toLowerCase().trim(),
        data.password,
        data.remember,
        data.loginType,
      );
      if (ldClient && userResponse?.user) {
        await identifyUserForLaunchDarkly(ldClient, userResponse.user);
        changeUser(userResponse.user.email);
        if (onSuccess) {
          onSuccess();
        } else {
          window.location.replace('/pharmacist/orders');
        }
      }
    } catch (err) {
      logger.error(err);
    } finally {
      setLoad(false);
    }
  };

  return {
    login,
    loading,
  };
}

export default usePharmacistLogin;
