import { ReactNode } from 'react';
import { Box, styled } from '@mui/material';

const AlertContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(32),
    paddingRight: theme.spacing(32),
    paddingTop: theme.spacing(8),
  },
  '&:empty': {
    display: 'none',
  },
}));

export type PartnerAlertProps = {
  children?: ReactNode;
};

function PartnerAlert({ children }: PartnerAlertProps) {
  if (!children) {
    return null;
  }

  return <AlertContainer data-testid="portal-partner-layout__alert">{children}</AlertContainer>;
}

export default PartnerAlert;
