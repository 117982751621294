import { ChangeEvent, useState } from 'react';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';

interface FormData {
  email: string;
  password: string;
  remember: boolean;
  loginType: string;
  onBoardKey?: string;
}

function usePharmacistForm(initialState: FormData) {
  const [data, setData] = useState<FormData>(initialState);
  const validator = new SimpleReactValidator();
  const [errors, setErrors] = useState({
    email: validator.message('email', data.email.trim(), 'required|email'),
    password: validator.message('password', data.password, 'required'),
  });

  const handleUserInput = (prop: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: prop === 'checkbox' ? e.target.checked : e.target.value });
  };

  return {
    data,
    errors,
    setErrors,
    handleUserInput,
    validator,
  };
}

export default usePharmacistForm;
