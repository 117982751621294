import { FF_DISABLE_CONSULTATION_BUTTONS } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import Mixpanel from '@/services/mixpanel.service';
import { ButtonProps } from '@/ui-library';
import buildBookingLinks from '@/utils/calendar';

import CalendarPopupButton from './CalendarPopupButton';

export type BookConsultationButtonProps = {
  calcomFollowUpConsultEvent?: string;
  bookDoctorConsultationButtonText: string;
  doctorEmail?: string;
  buttonProps?: Omit<ButtonProps, 'children'>;
  disabled?: boolean;
  handleBookingSuccess?: () => void;
  handleRescheduleSuccess?: () => void;
  handleCancelSuccess?: () => void;
  calcomUrlOverride?: string;
  onOpen?: () => void;
  testId?: string;
  togglePrimaryColours?: boolean;
  currentDoctorLink?: string;
};

export default function BookConsultationButton({
  calcomFollowUpConsultEvent,
  buttonProps,
  bookDoctorConsultationButtonText,
  disabled,
  handleBookingSuccess,
  handleRescheduleSuccess,
  handleCancelSuccess,
  doctorEmail,
  calcomUrlOverride,
  onOpen,
  testId,
  togglePrimaryColours = false,
  currentDoctorLink,
}: BookConsultationButtonProps) {
  const user = AuthService.getUser();

  const { flags } = useFeatureFlags();

  const { calcom: calcomDoctorsIndividualUrl } = buildBookingLinks({
    calcom_follow_up_consult_event: calcomFollowUpConsultEvent || null,
    email: doctorEmail || null,
  });
  const calcomUrl = calcomUrlOverride || calcomDoctorsIndividualUrl || currentDoctorLink;

  const prefillCalCom = {
    email: user?.user?.email,
    name: `${user?.user?.first_name} ${user?.user?.last_name}`,
    phoneNumber: user?.user?.phone,
  };

  const handleCalendarOpenedTracking = () => {
    Mixpanel('Book a follow up consultation');
  };

  const ffDisableConsultationButtons =
    flags[FF_DISABLE_CONSULTATION_BUTTONS] || ffDefaults[FF_DISABLE_CONSULTATION_BUTTONS];

  const disabledButtonTooltip =
    'Bookings are temporarily disabled while we update our system. We appreciate your patience.';

  return (
    <div>
      {calcomUrl && (
        <CalendarPopupButton
          testId={testId}
          link={calcomUrl}
          formData={prefillCalCom}
          onOpen={() => {
            if (onOpen) {
              onOpen();
            }
            handleCalendarOpenedTracking();
          }}
          disabled={ffDisableConsultationButtons || disabled}
          buttonProps={buttonProps}
          tooltipProps={{ title: disabledButtonTooltip }}
          handleBookingSuccess={handleBookingSuccess}
          handleRescheduleSuccess={handleRescheduleSuccess}
          handleCancelSuccess={handleCancelSuccess}
          togglePrimaryColours={togglePrimaryColours}
        >
          {bookDoctorConsultationButtonText}
        </CalendarPopupButton>
      )}
    </div>
  );
}
