import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { usePharmacistSplitOrderModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';

const ContentLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

type SplitOrderProduct = {
  name: string;
  id: number;
  quantity: number | undefined;
};

type ReasonOption = {
  name: string;
  value: number;
};

type SelectedReasons = {
  [key: string | number]: {
    label: string;
    value: number;
    otherReason?: string;
  };
};

function SplitOrderModal() {
  const { unableToDispenseReasons, splitOrderModal, hideSplitOrderModal, saveUnableToDispense } =
    usePharmacistSplitOrderModal();
  const [products, setProducts] = useState<SplitOrderProduct[]>([]);
  const [reasonOptions, setReasonOptions] = useState<ReasonOption[]>([]);
  const [selectedReasons, setSelectedReasons] = useState<SelectedReasons>({});
  const [additionalComments, setAdditionalComments] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setReasonOptions(unableToDispenseReasons.map((reason) => ({ name: reason.name, value: reason.id })));
  }, [unableToDispenseReasons]);

  useEffect(() => {
    if (splitOrderModal.order) {
      setProducts(
        splitOrderModal.order.product_details.map((productDetail, index) => ({
          name: productDetail.name,
          id: productDetail.id,
          quantity: splitOrderModal.order?.quantity[index] || undefined,
        })),
      );
    } else {
      setProducts([]);
    }
  }, [splitOrderModal]);

  const handleReasonChange = (e: SelectChangeEvent<unknown>, product: SplitOrderProduct) => {
    const selectedReason = reasonOptions.find((option) => option.value === e.target.value);
    if (selectedReason) {
      setSelectedReasons({
        ...selectedReasons,
        [product.id]: { label: selectedReason.name, value: selectedReason.value },
      });
    }
  };

  const handleOtherReasonChange = (e: ChangeEvent<HTMLInputElement>, product: SplitOrderProduct) => {
    setSelectedReasons({
      ...selectedReasons,
      [product.id]: {
        ...selectedReasons[product.id],
        otherReason: e.target.value,
      },
    });
  };

  const handleAdditionalCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalComments(e.target.value);
  };

  const resetStateAndClose = () => {
    setDisabled(false);
    setAdditionalComments('');
    setSelectedReasons({});
    hideSplitOrderModal();
  };

  const handleConfirm = async () => {
    setDisabled(true);
    const orderId = splitOrderModal.order?.id;
    if (!orderId) {
      toast.error('Save failed, order not found');
      setDisabled(false);
    } else {
      const productsWithReasons = products.map((product) => ({
        productId: product.id,
        orderId,
        reason: { label: selectedReasons[product.id]?.label, value: selectedReasons[product.id]?.value },
        otherReason: selectedReasons[product.id]?.otherReason,
        additionalComment: additionalComments,
      }));
      const result = await saveUnableToDispense(productsWithReasons);
      if (result.success) {
        toast.success('Order Updated');
        resetStateAndClose();
      } else {
        toast.error(result.message);
        setDisabled(false);
      }
    }
  };

  return (
    <Dialog open={splitOrderModal.visible} onClose={hideSplitOrderModal} fullWidth maxWidth="lg" disableEscapeKeyDown>
      <DialogTitle>Unable to dispense {splitOrderModal.order?.order_code}</DialogTitle>
      <DialogContent>
        <ContentLayout>
          <Typography>Mark the reason why this order cannot be dispensed</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Typography fontWeight="bold">Paid date</Typography>
                <Typography>{splitOrderModal.order?.shipped_date}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Typography fontWeight="bold">Patient ID</Typography>
                <Typography>{splitOrderModal.order?.patient_id}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Typography fontWeight="bold">Prescription</Typography>
                <Typography>
                  {splitOrderModal.order?.prescriptions.map((prescription) => prescription.name).join(', ') || '-'}{' '}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          {products.map((product) => (
            <Grid container spacing={5} key={product.id}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <Typography fontWeight="bold">Product</Typography>
                  <Typography>{product.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <Typography fontWeight="bold">Quantity</Typography>
                  <Typography>{product.quantity}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <Typography fontWeight="bold">Reason</Typography>
                  {reasonOptions.length > 0 && (
                    <Select
                      data-testid="reason-select"
                      onChange={(e) => handleReasonChange(e, product)}
                      value={selectedReasons[product.id]?.value}
                    >
                      {reasonOptions.map((option) => (
                        <MenuItem value={option.value} key={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {selectedReasons[product.id] && selectedReasons[product.id].label === 'Others' && (
                    <TextField
                      placeholder="Issue"
                      name="reason"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleOtherReasonChange(e, product)}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              name="additional-info"
              InputLabelProps={{ shrink: true }}
              label="Additional comments"
              placeholder="Any additional comments relating to an issue.."
              multiline
              rows={3}
              fullWidth
              onChange={handleAdditionalCommentChange}
            />
          </Grid>
        </ContentLayout>
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={resetStateAndClose} disabled={disabled}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} disabled={disabled}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SplitOrderModal;
