import { ReactNode } from 'react';
import { Box, styled } from '@mui/material';

export const ContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 32),
  },
}));

export type PartnerContentProps = {
  children: ReactNode;
};

function PartnerContent({ children }: PartnerContentProps) {
  return <ContentContainer>{children}</ContentContainer>;
}

export default PartnerContent;
