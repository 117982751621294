import { ReactNode } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

import { ClickableTooltip as Tooltip } from '@/ui-library/tooltip/ClickableTooltip';

function KeyText({ children, sx, tooltip }: Pick<TypographyProps, 'children' | 'sx'> & { tooltip?: string }) {
  const text = (
    <Typography
      component="span"
      variant="body2"
      sx={[
        {
          cursor: tooltip ? 'pointer' : 'default',
          textDecoration: tooltip ? 'underline' : 'none',
          fontWeight: 'bold',
          display: 'inline',
          mr: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );

  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip}>
        {text}
      </Tooltip>
    );
  }

  return text;
}

function ValueText(props: Pick<TypographyProps, 'children' | 'sx'>) {
  return <Typography variant="body2" component="span" {...props} />;
}

export type KeyValueTextProps = Pick<BoxProps, 'sx'> & {
  children?: ReactNode;
};

export function KeyValueText({ children, ...boxProps }: KeyValueTextProps) {
  return <Box {...boxProps}>{children}</Box>;
}

KeyValueText.Key = KeyText;
KeyValueText.Value = ValueText;

export default KeyValueText;
