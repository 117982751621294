interface IUmedsLogoProps {
  width?: string;
  height?: string;
  fillColor?: string;
  className?: string;
}

export default function UmedsLogo({
  width = '99',
  height = '100%',
  fillColor = '#F2EFE4',
  className,
}: IUmedsLogoProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 99 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6131_17101)">
        <path
          d="M34.3139 15.7217L28.0024 0.176035C27.9589 0.0696607 27.855 0 27.7398 0H23.2482C23.0914 0 22.9648 0.126142 22.9648 0.282408V23.7176C22.9648 23.8739 23.0914 24 23.2482 24H26.615C26.7718 24 26.8984 23.8739 26.8984 23.7176V8.47507C26.8984 8.16442 27.3271 8.08158 27.4442 8.3687L33.5518 23.4126C33.5952 23.519 33.6991 23.5886 33.8143 23.5886H35.3424C35.4576 23.5886 35.5625 23.519 35.605 23.4116L41.6776 8.38659C41.7937 8.09853 42.2235 8.18137 42.2235 8.49202V23.7101C42.2235 23.8663 42.35 23.9925 42.5068 23.9925H45.8736C46.0304 23.9925 46.157 23.8663 46.157 23.7101V0.282408C46.157 0.126142 46.0304 0 45.8736 0H41.382C41.2668 0 41.1619 0.0696607 41.1194 0.176976L34.839 15.7217C34.7436 15.957 34.4093 15.958 34.3139 15.7217Z"
          fill={fillColor}
        />
        <path
          d="M79.2597 11.4083V18.3273C79.2597 18.3857 79.2427 18.4431 79.2087 18.4911C78.32 19.7309 76.569 20.6139 74.9597 20.6139C72.101 20.6139 70.2537 18.3763 70.2537 14.913C70.2537 11.4498 72.1444 9.17825 74.9597 9.17825C76.7976 9.17825 78.49 10.2853 79.2606 11.4093M79.2597 7.14209C79.2597 7.37837 78.9877 7.50828 78.8016 7.36331C77.5115 6.35511 75.941 5.79689 74.3175 5.79689C69.6351 5.79689 66.4883 9.46066 66.4883 14.914C66.4883 20.3673 69.6341 23.9972 74.3175 23.9972C75.9448 23.9972 77.5163 23.4455 78.8026 22.4505C78.9886 22.3065 79.2597 22.4373 79.2597 22.6727V23.3109C79.2597 23.4672 79.3862 23.5933 79.543 23.5933H82.6398C82.7965 23.5933 82.9231 23.4672 82.9231 23.3109V0.285216C82.9231 0.12895 82.7965 0.00280762 82.6398 0.00280762H79.543C79.3862 0.00280762 79.2597 0.12895 79.2597 0.285216V7.14115V7.14209Z"
          fill={fillColor}
        />
        <path
          d="M56.4455 9.0126C59.5574 9.0126 60.8107 11.2747 61.1506 12.9353C61.1865 13.1103 61.0524 13.2751 60.873 13.2751H52.0899C51.9067 13.2751 51.7707 13.1047 51.8141 12.9268C52.2155 11.2625 53.5046 9.0126 56.4465 9.0126M56.4795 5.79879C51.5733 5.79879 47.873 9.70356 47.873 14.882C47.873 20.0604 51.5365 23.9991 56.7836 23.9991C59.4856 23.9991 61.8656 23.0699 63.6666 21.3124L63.8413 21.142C63.9423 21.0432 63.9546 20.886 63.8715 20.773L62.3142 18.6597C62.2103 18.5194 62.0044 18.5053 61.8826 18.6295L61.6512 18.8668C60.4593 20.0858 58.7594 20.7853 56.9876 20.7853C54.772 20.7853 52.4223 19.4909 51.8311 16.6235C51.7952 16.4494 51.9312 16.2865 52.1097 16.2865H64.6016C64.7583 16.2865 64.8849 16.1604 64.8849 16.0041V15.0834C64.8849 9.52941 61.5076 5.79785 56.4814 5.79785"
          fill={fillColor}
        />
        <path
          d="M92.3376 12.8854C90.4431 12.4335 88.8074 12.0438 88.8074 10.9754C88.8074 9.79961 90.0615 8.97875 91.8578 8.97875C93.6541 8.97875 95.3257 9.67065 96.289 10.8295L96.5289 11.1175C96.6498 11.2625 96.8765 11.2512 96.9823 11.0949L98.5028 8.84413C98.5774 8.73305 98.5642 8.58526 98.4707 8.48924L98.311 8.32638C97.1844 7.17322 95.1501 5.79883 91.8588 5.79883C87.2906 5.79883 85.2459 8.43276 85.2459 11.0432C85.2459 14.8199 88.729 15.6634 91.5282 16.3421C93.8978 16.9163 95.3493 17.3324 95.3493 18.5882C95.3493 19.9861 94.1074 20.8201 92.0278 20.8201C89.9482 20.8201 88.0027 19.7507 87.0828 18.6908L86.8477 18.4197C86.7268 18.2803 86.5077 18.2916 86.4019 18.4413L84.7775 20.742C84.6991 20.8521 84.7105 21.0028 84.8049 21.0997L84.9655 21.2673C86.7136 23.0803 89.0671 23.9991 91.9608 23.9991C96.1833 23.9991 98.9108 21.8481 98.9108 18.5185C98.9108 14.4509 95.1123 13.5453 92.3385 12.8845"
          fill={fillColor}
        />
        <path
          d="M15.6651 14.1261C15.6651 18.1071 13.5864 20.3005 9.81063 20.3005C6.03484 20.3005 3.99017 18.108 3.99017 14.1261V11.7237C3.99017 11.5665 3.86268 11.4394 3.70496 11.4394H0.285215C0.127497 11.4394 0 11.5665 0 11.7237V14.1939C0 20.3871 3.57652 23.9388 9.81063 23.9388C16.0447 23.9388 19.6553 20.3748 19.6553 14.16V5.93529C19.6553 5.77808 19.5278 5.651 19.3701 5.651H15.9503C15.7926 5.651 15.6651 5.77808 15.6651 5.93529V14.1261Z"
          fill={fillColor}
        />
        <path
          d="M4.18284 5.651H0V9.55106H1.25797C3.98451 9.55106 4.61727 8.28964 4.61727 7.06869C4.61727 6.32125 4.29994 5.651 4.18284 5.651Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6131_17101">
          <rect width="98.9091" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
