import { PropsWithChildren } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { getAccordionHeaderColors } from '@/tech-debt/out-of-theme-brand-colors';

interface ProductCategoryAccordionProps {
  label: string;
  count: number;
  startCollapsed?: boolean;
  showCountAtZero?: boolean;
}

const StyledAccordion = styled(Accordion)(() => ({
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  background: '#F6F4EC', // TODO: Replace this with the proper semantic color, once UX decides what to use
  borderTop: '1px solid',
  borderTopColor: '#e8e4d1', // TODO: Replace this with the proper semantic color, once UX decides what to use
  paddingLeft: theme.spacing(4),
  minHeight: theme.spacing(12),
  borderRadius: 0,
  marginTop: '0.75rem',
  [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
    minHeight: theme.spacing(12),
  },
  [`& .${accordionSummaryClasses.content}`]: {
    gap: theme.spacing(2),
    margin: theme.spacing(3),
    alignItems: 'center',
  },
  [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
    margin: theme.spacing(3),
  },
}));

const ProductCategoryCount = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: '50%',
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export function ProductCategoryAccordion(props: PropsWithChildren<ProductCategoryAccordionProps>) {
  // This whole block is a temporary hack to get around some design system inconsistency.
  // Once the uMeds brand is rolled out, destroy all of style switching code.
  const { key: brandName } = usePortalPartnerBrand();
  const sxAccordionSummary = getAccordionHeaderColors(brandName);

  const { count, label, showCountAtZero, startCollapsed, children } = props;
  const shouldShowCount = Boolean(count > 0 || showCountAtZero);
  return (
    <StyledAccordion defaultExpanded={!startCollapsed} elevation={0}>
      <StyledAccordionSummary expandIcon={<KeyboardArrowDownIcon />} sx={sxAccordionSummary}>
        <Typography variant="h5" component="h2">
          {label}
        </Typography>
        {shouldShowCount && (
          <ProductCategoryCount>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {count}
            </Typography>
          </ProductCategoryCount>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

export default ProductCategoryAccordion;
