import { Info } from '@mui/icons-material';
import { Box, Button, Chip, styled, svgIconClasses, Theme, Typography } from '@mui/material';

import { formatPrice } from './util';

export interface UnavailableProductData {
  id: number;
  name: string;
  price: number;
  is_out_of_stock: boolean;
  reasoning_toggle: boolean;
  is_generative_scripting: boolean;
  Suppliers?: { supplier_name: string }[];
}

export interface UnavailableProductIssue {
  title: string;
  info: string;
  isHighSeverity?: boolean;
}

const UNKNOWN_SUPPLIER = 'Unknown Supplier';

const isOOSorDiscontinued = (product: UnavailableProductData) =>
  product.is_out_of_stock || product.reasoning_toggle || product.is_generative_scripting;

const UnavailableProductCardRoot = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.action.disabledBackground,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start ',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(2),
}));

const Instructions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.warning.light,
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.warning.main,
  },
  '&.severity-high': {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
}));

export interface UnavailableProductRefillCardProps {
  product: UnavailableProductData;
  issue: UnavailableProductIssue;
  handleCheckProductStatus: () => void;
  showRequestReplacementOption?: boolean;
  showRequestReplacementInfo?: boolean;
}

const ProductStatusChip = styled(Chip)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.warning.main,
  fontWeight: 600,
  backgroundColor: theme.palette.warning.light,
  border: `1px solid ${theme.palette.warning.main}`,
  '&.severity-high': {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const DullText = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.disabled,
  fontWeight: 600,
}));

const ActionText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(2),
  color: theme.palette.primary.main,
  borderColor: theme.palette.action.disabledBackground,
}));

export function UnavailableProductRefillCard(props: UnavailableProductRefillCardProps) {
  const { product, issue, handleCheckProductStatus, showRequestReplacementOption, showRequestReplacementInfo } = props;
  const severityClass = issue.isHighSeverity ? 'severity-high' : '';
  const supplierName = product.Suppliers?.[0]?.supplier_name || UNKNOWN_SUPPLIER;

  return (
    <UnavailableProductCardRoot>
      <Box>
        <ProductStatusChip className={severityClass} variant="outlined" label={issue.title} />
      </Box>
      <DullText variant="subtitle2" sx={{ fontWeight: 600 }}>
        {product.name}
      </DullText>
      <DullText variant="body2">
        {supplierName} | RRP <strong>{formatPrice(product.price)}</strong>
      </DullText>
      {!isOOSorDiscontinued(product) && issue.info && (
        <Instructions className={severityClass}>
          {!issue.isHighSeverity && <Info />}
          <Typography>{issue.info}</Typography>
        </Instructions>
      )}
      {Boolean(showRequestReplacementOption) && isOOSorDiscontinued(product) && (
        <>
          {issue.info && (
            <Instructions className={severityClass}>
              {!issue.isHighSeverity && <Info />}
              <Typography>{issue.info}</Typography>
            </Instructions>
          )}
          <Button variant="outlined" onClick={handleCheckProductStatus}>
            <ActionText variant="body1">Check product status</ActionText>
          </Button>
        </>
      )}
      {showRequestReplacementInfo && isOOSorDiscontinued(product) && issue.info && (
        <Instructions className={severityClass}>
          {!issue.isHighSeverity && <Info />}
          <Typography>{issue.info}</Typography>
        </Instructions>
      )}
    </UnavailableProductCardRoot>
  );
}

export default UnavailableProductRefillCard;
