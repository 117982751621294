import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import Button from '@/ui-library/button/Button';
import { Logger } from '@/utils/logger';

const logger = new Logger('approvalsModal');

interface CustomTabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

function CustomTabPanel(props: CustomTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: '500px' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface ApprovalDocument {
  id: string;
  ap_document_path?: string;
  sas_ingredient_path?: string;
  sas_path?: string;
  ProductIngredient: {
    name: string;
  };
  ProductFormulation: {
    name: string;
  };
  upload_date: string;
  expiry_date?: string;
  end_date?: string;
  patient_id?: string;
}

const downloadAPDocument = async (
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  approvalDocument: ApprovalDocument,
  orderId?: number,
) => {
  e.preventDefault();

  if (!approvalDocument.id && !approvalDocument.ap_document_path) {
    toast.error('AP document could not be downloaded.');
    logger.error('AP document could not be downloaded. no approvalDocument.id');
    return;
  }
  await axios({
    url: 'ap/document/download',
    method: 'GET',
    params: {
      apId: approvalDocument.id,
      oldFormat: false,
      orderId,
    },
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', approvalDocument.ap_document_path || approvalDocument.id);
    document.body.appendChild(link);
    link.click();
  });
};

const downloadSASDocument = async (
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  approvalDocument: ApprovalDocument,
  orderId?: number,
) => {
  e.preventDefault();
  if (!approvalDocument.id || !approvalDocument.patient_id) {
    toast.error('SAS document could not be downloaded.');
    logger.error('SAS document could not be downloaded. !sasDocument.id || !sasDocument.patient_id');
    return;
  }

  if (!approvalDocument.sas_path && !approvalDocument.sas_ingredient_path) {
    toast.error('SAS document could not be downloaded.');
    logger.error(
      'SAS document could not be downloaded. !approvalDocumnet.sas_path && !approvalDocument.sas_ingredient_path',
    );
    return;
  }
  await axios({
    url: 'sas/document/download',
    method: 'GET',
    params: {
      sasId: approvalDocument.id,
      patientId: approvalDocument.patient_id,
      oldFormat: false,
      orderId,
    },
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const downloadPath = approvalDocument.sas_path || approvalDocument.sas_ingredient_path || approvalDocument.id;
    link.setAttribute('download', downloadPath);
    document.body.appendChild(link);
    link.click();
  });
};

interface SASIngredientDocumentTableProps {
  data: ApprovalDocument[] | undefined;
  orderId: number;
}

function SASIngredientDocumentTable({ data, orderId }: SASIngredientDocumentTableProps) {
  return (
    <TableContainer>
      <Table style={{ overflow: 'auto', height: '450px', display: 'block' }}>
        <TableHead>
          <TableRow sx={{ bgcolor: 'background.paper', position: 'sticky', top: 0 }}>
            <TableCell>Category</TableCell>
            <TableCell>Formulation</TableCell>
            <TableCell>SAS Document</TableCell>
            <TableCell>Uploaded Date</TableCell>
            <TableCell>Expiry Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((approvalDocument) => (
            <TableRow key={approvalDocument.id}>
              <TableCell>{approvalDocument.ProductIngredient.name}</TableCell>
              <TableCell>{approvalDocument.ProductFormulation.name}</TableCell>
              <TableCell>
                <Link href="/#" onClick={(e) => downloadSASDocument(e, approvalDocument, orderId)}>
                  {approvalDocument.sas_ingredient_path}
                </Link>
              </TableCell>
              <TableCell>{moment(approvalDocument.upload_date).format('DD-MMM-YYYY')}</TableCell>
              <TableCell>{moment(approvalDocument.expiry_date).format('DD-MMM-YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface APIngredientDocumentTableProps {
  data: ApprovalDocument[] | undefined;
  orderId: number;
}

function APIngredientDocumentTable({ data, orderId }: APIngredientDocumentTableProps) {
  return (
    <TableContainer>
      <Table style={{ overflow: 'auto', height: '450px', display: 'block' }}>
        <TableHead>
          <TableRow sx={{ bgcolor: 'background.paper', position: 'sticky', top: 0 }}>
            <TableCell>Category</TableCell>
            <TableCell>Formulation</TableCell>
            <TableCell>AP Document</TableCell>
            <TableCell>Uploaded Date</TableCell>
            <TableCell>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((approvalDocument) => (
            <TableRow key={approvalDocument.id}>
              <TableCell>{approvalDocument.ProductIngredient.name}</TableCell>
              <TableCell>{approvalDocument.ProductFormulation.name}</TableCell>
              <TableCell>
                <Link href="/#" onClick={(e) => downloadAPDocument(e, approvalDocument, orderId)}>
                  {approvalDocument.ap_document_path}
                </Link>
              </TableCell>
              <TableCell>{moment(approvalDocument.upload_date).format('DD-MMM-YYYY')}</TableCell>
              <TableCell>{moment(approvalDocument.end_date).format('DD-MMM-YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface PharmacistApprovalDocumentsModalProps {
  data: {
    id: number;
    pharmacy_id: number;
    patient_id: string;
    gp_id: number;
  } | null;
  show: boolean;
  close: () => void;
}

function PharmacistApprovalDocumentsModal(props: PharmacistApprovalDocumentsModalProps) {
  const { data, show, close } = props;
  const [value, setValue] = useState(0);
  const [approvalDocuments, setApprovalDocuments] = useState<{
    sas_ingredients: ApprovalDocument[];
    ap_ingredient_documents: ApprovalDocument[];
  }>();
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      setLoadingDocuments(true);
      axios
        .get('pharmacist/orders/documents', {
          params: { patientCode: data.patient_id, gpId: data.gp_id, orderId: data.id },
        })
        .then((response) => {
          setApprovalDocuments(response?.data);
          setLoadingDocuments(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {});
          logger.error(`catch ${err.response.data.message}`);
        });
    }
  }, [data]);

  return (
    <Dialog open={show} onClose={close}>
      <Box sx={{ p: 5 }}>
        <DialogTitle>Approvals</DialogTitle>
        {!loadingDocuments && data ? (
          <>
            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="SAS" />
                <Tab label="AP" />
              </Tabs>
            </DialogActions>
            <CustomTabPanel value={value} index={0}>
              <SASIngredientDocumentTable data={approvalDocuments?.sas_ingredients} orderId={data.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <APIngredientDocumentTable data={approvalDocuments?.ap_ingredient_documents} orderId={data.id} />
            </CustomTabPanel>
          </>
        ) : (
          <Box sx={{ p: 50 }}>
            <CircularProgress />
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={close}>Close</Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PharmacistApprovalDocumentsModal;
