import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Box, Skeleton, Stack, styled, Typography } from '@mui/material';
import moment from 'moment';

import { GENERATIVE, OUT_OF_STOCK, ProductEnquiryStatus } from '@/components/patient/enquiryModal/common';
import {
  FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES,
  FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES,
} from '@/constants/featureFlags';
import useProductEnquiryStatus from '@/hooks/products/useProductEnquiryStatus';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { ProductFormulationFilter, ProductFormulations } from '@/types';
import AddRemoveButton from '@/ui-library/button/AddRemoveButton';
import Button from '@/ui-library/button/Button';
import { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';
import getValidProductTypeName from '@/utils/getValidProductTypeName';

import ProductStatusLabel from '../detail/ProductStatusLabel';
import ProductTypeButton from '../ProductTypeButton';

import CheckProductEnquiryBtn from './components/CheckProductEnquiryBtn';
import Prescription from './components/Prescription';
import ProductCardIssue, { getProductCardIssue } from './components/ProductCardIssue';
import Thumbnail from './components/Thumbnail';

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: '1rem',
  boxShadow: '0 0.25rem 0.938rem rgba(0, 0, 0, 0.03)',
  borderRadius: '0.375rem',
  marginBottom: '0.5rem',
  backgroundColor: theme?.palette?.common?.white,
  border: `1px solid ${theme?.palette?.grey?.[200]}`,
  [theme.breakpoints.up('sm')]: {
    padding: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    padding: '1.25rem',
    justifyContent: 'space-between',
    marginBottom: '1.25rem',
    border: `1px solid ${theme?.palette?.grey?.[200]}`,
  },
}));

const ProductDiv = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '40fr 60fr',
  gridTemplateRows: 'repeat(2, auto)',
  position: 'relative',
  columnGap: '0.65rem',
  [theme.breakpoints.up('lg')]: {
    flex: 0.8,
    gridTemplateColumns: '10fr 25fr 39fr 10fr 16fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ProductDetails = styled(Box)`
  grid-column-start: 2;
`;

const MobileProductDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gridColumnStart: 1,
  gridRowStart: 2,
  alignItems: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const PriceContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    gridColumnStart: 4,
  },
}));

export const Price = styled(Typography)`
  display: flex;
  font-weight: 800;
  padding: 0;
`;

export const ProductName = styled(Typography)`
  width: 100%;
  min-width: 100px;
`;

export const Supplier = styled(Typography)`
  margin-bottom: 0.25rem;
`;

export const DetailsLink = styled(Link)`
  width: 100%;
  min-width: 100px;
`;

const Mobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '6.75rem',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const Desktop = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
  },
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    gridColumnStart: 5,
    justifyContent: 'flex-end',
    gap: '1.5rem',
    alignItems: 'center',
    flex: 0.2,
  },
}));

export const ProductTypeChipMobile = styled(ProductTypeButton)`
  font-size: 0.875rem;
  min-width: 6.25rem;
  justify-content: center;
`;

export const StyledImg = styled(Thumbnail)`
  max-width: 6.25rem;
  max-height: 6.25rem;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: '0.75rem 0',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '18rem',
  },
}));

const MobileCheckStatusBtn = styled(CheckProductEnquiryBtn)(({ theme }) => ({
  margin: '1rem 0',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const RelativeBox = styled(Box)`
  position: relative;
`;

const ProductStatusContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  right: '0',
  objectFit: 'contain',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: '0',
}));

export type ProductCardProduct = {
  id: number;
  productType: ProductFormulationFilter;
  name: string;
  price: number;
  supplierName: string;
  isOutOfStock: boolean;
  isGenerativeScripting?: boolean;
  reasoningToggle: boolean;
  shortName?: string;
  imageUrl?: string;
  thcStrengthForDisplay?: string;
  cbdStrengthForDisplay?: string;
  remainingUnits: number;
  repeats: number;
  isIntervalLocked?: boolean;
  intervalEndDate?: string | null;
  maxAllowedQuantity?: number;
  isExpired?: boolean;
  isCampaignDiscountPrice?: boolean;
  isEnquiryProductDialogOpen?: boolean;
  productEnquiryStatus?: ProductEnquiryStatus;
  productFormulation?: {
    id: string;
    name: string;
    whitelabel_image_url: string;
  };
};

export type ProductCardProps = {
  product: ProductCardProduct;
  quantity: number;
  actions?: {
    handleQtyIncreasePressed: (product: { id: number }) => void;
    handleQtyDecreasePressed: (product: { id: number }) => void;
  };
  error?: string;
  disabled?: boolean;
  setIsCheckProductEnquiryLoading?: (value: boolean) => void;
  patientId?: number;
  prescriptionId?: number;
  setProductEnquiryStatus?: (value: ProductEnquiryStatus | null) => void;
  setSelectedProductId?: (id: number) => void;
  setIsEnquiryProductDialogOpen?: (value: boolean) => void;
  setHasEnquiryError?: (value: boolean) => void;
  hideCheckEnquiryStatusBtn?: boolean;
};

export default function ProductCard(props: ProductCardProps) {
  const {
    product: {
      id: productId,
      productType,
      imageUrl,
      name: productName,
      price,
      thcStrengthForDisplay,
      cbdStrengthForDisplay,
      supplierName,
      isGenerativeScripting,
      isOutOfStock,
      shortName,
      reasoningToggle,
      remainingUnits,
      repeats,
      isIntervalLocked = false,
      intervalEndDate,
      maxAllowedQuantity = Infinity,
      isExpired,
      isCampaignDiscountPrice,
      productFormulation,
    },
    quantity,
    actions,
    error,
    disabled,
    setIsCheckProductEnquiryLoading,
    patientId,
    setProductEnquiryStatus,
    setIsEnquiryProductDialogOpen,
    setSelectedProductId,
    prescriptionId,
    setHasEnquiryError,
    hideCheckEnquiryStatusBtn = false,
  } = props;

  const { flags } = useFeatureFlags();

  const fetchProductEnquiryStatus = useProductEnquiryStatus();

  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];
  const isDevice = productType === ProductFormulations.DEVICE;

  const validProductTypeName = getValidProductTypeName(productType);

  const handleQtyIncrease = () => {
    actions?.handleQtyIncreasePressed?.({ id: productId });
  };

  const handleQtyDecrease = () => {
    actions?.handleQtyDecreasePressed?.({ id: productId });
  };

  const isOutOfStockOrReasoningToggle = isOutOfStock || reasoningToggle;

  const productIssue = getProductCardIssue({
    isOutOfStock: isOutOfStockOrReasoningToggle,
    remainingUnits,
    isIntervalLocked,
    intervalEndDate,
    isExpired,
    isGenerativeScripting,
  });

  const showAddRemoveButton = !productIssue;

  const isOOSOrDiscontinuedProduct = isOutOfStock || isGenerativeScripting;

  const AddRemove = showAddRemoveButton ? (
    <AddRemoveButton
      count={quantity}
      isAddDisabled={disabled || quantity >= maxAllowedQuantity}
      isRemoveDisabled={disabled || !quantity}
      onClickAdd={handleQtyIncrease}
      onClickRemove={handleQtyDecrease}
    />
  ) : null;

  const handleCheckProductStatus = async () => {
    setIsCheckProductEnquiryLoading?.(true);
    setSelectedProductId?.(productId);
    try {
      const rescriptRequestReason = isOutOfStock ? OUT_OF_STOCK : GENERATIVE;
      const data = await fetchProductEnquiryStatus(patientId, prescriptionId, productId, rescriptRequestReason);
      setProductEnquiryStatus?.({
        ...data,
        rescriptActionDate: data.rescriptActionDate ? moment(data.rescriptActionDate).format('DD/MM/YYYY') : null,
        rescriptRequestDate: data.rescriptRequestDate ? moment(data.rescriptRequestDate).format('DD/MM/YYYY') : null,
      });
      setIsCheckProductEnquiryLoading?.(false);
      setIsEnquiryProductDialogOpen?.(true);
    } catch {
      setIsCheckProductEnquiryLoading?.(false);
      setHasEnquiryError?.(true);
    }
  };

  return (
    <Container
      sx={
        disabled && !ffEnableProductIssueEnquiries ? { pointerEvents: 'none', opacity: '0.6', userSelect: 'none' } : {}
      }
    >
      <ProductDiv
        data-testid={`product-card-${productId}`}
        sx={
          disabled && ffEnableProductIssueEnquiries ? { pointerEvents: 'none', opacity: '0.6', userSelect: 'none' } : {}
        }
      >
        <RelativeBox>
          {flags[FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES] ? (
            <StyledImg
              data-testid="product-thumbnail"
              src={getWhiteLabelProductImageUrl({ whiteLabelImageUrl: productFormulation?.whitelabel_image_url })}
              alt="Product"
            />
          ) : (
            <StyledImg data-testid="product-thumbnail" src={imageUrl} alt="Product" />
          )}

          {isCampaignDiscountPrice && (
            <ProductStatusContainer>
              <ProductStatusLabel campaignDiscountPrice />
            </ProductStatusContainer>
          )}
        </RelativeBox>

        <ProductDetails>
          <ProductName fontWeight={800} variant="h5">
            <DetailsLink
              data-testid="details-link"
              to={`/product-detail/${productId}`}
              onClick={(e: React.MouseEvent<HTMLElement>) => disabled && e.preventDefault()}
            >
              {shortName || productName}
            </DetailsLink>
          </ProductName>

          <Supplier>{supplierName}</Supplier>

          {!(ffEnableProductIssueEnquiries && isOOSOrDiscontinuedProduct) && (
            <Mobile>
              <Price variant="h5">${price}</Price>
            </Mobile>
          )}

          <Desktop>{validProductTypeName && <ProductTypeButton type={validProductTypeName} fixed />}</Desktop>
        </ProductDetails>

        <MobileProductDetails>
          {validProductTypeName && (
            <ProductTypeChipMobile
              type={validProductTypeName}
              fixed
              sx={{ ...(ffEnableProductIssueEnquiries && { width: '100%' }) }}
            />
          )}
          {AddRemove}
          {productIssue && (
            <ProductCardIssue title={productIssue.title} info={productIssue.info} linkTo={productIssue.linkTo} />
          )}
        </MobileProductDetails>

        {!isDevice && (
          <Prescription
            data={{
              repeats,
              thcStrengthForDisplay,
              cbdStrengthForDisplay,
            }}
            hideRepeats={ffEnableProductIssueEnquiries && isOOSOrDiscontinuedProduct}
          />
        )}

        {!(ffEnableProductIssueEnquiries && isOOSOrDiscontinuedProduct) && (
          <PriceContainer>
            <Price>${price}</Price>
          </PriceContainer>
        )}
      </ProductDiv>
      <ActionContainer>
        {AddRemove}
        {productIssue && (
          <Stack spacing={2}>
            <ProductCardIssue title={productIssue.title} info={productIssue.info} linkTo={productIssue.linkTo} />
            {ffEnableProductIssueEnquiries && isOOSOrDiscontinuedProduct && !hideCheckEnquiryStatusBtn && (
              <CheckProductEnquiryBtn handleCheckProductStatus={handleCheckProductStatus} />
            )}
          </Stack>
        )}
      </ActionContainer>

      {ffEnableProductIssueEnquiries && isOOSOrDiscontinuedProduct && !hideCheckEnquiryStatusBtn && (
        <MobileCheckStatusBtn handleCheckProductStatus={handleCheckProductStatus} />
      )}

      {error && (
        <Alert severity="error" sx={{ marginTop: '1rem' }}>
          {error}
        </Alert>
      )}
    </Container>
  );
}

export function ProductCardSkeleton() {
  return (
    <Container data-testid="product-card-skeleton">
      <ProductDiv>
        {/* Skeleton for the image */}
        <Skeleton
          variant="rectangular"
          sx={{
            width: '100px',
            height: '100px',
            maxWidth: '6.25rem', // max-width for the image
            maxHeight: '6.25rem', // max-height for the image
          }}
        />
        {/* Skeleton for the product name and details */}
        <ProductDetails>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </ProductDetails>
        <PriceContainer>
          <Skeleton variant="text" sx={{ fontSize: '2rem', width: 100 }} />
        </PriceContainer>
      </ProductDiv>
      <ActionContainer>
        <Skeleton variant="text" sx={{ fontSize: '2rem', width: 100 }} />
      </ActionContainer>
    </Container>
  );
}
