import { Accordion } from 'react-accessible-accordion';
import { Box, Container, styled, Typography } from '@mui/material';

import accordionData from './accordionData';
import ResearchAccordionItem from './ResearchAccordionItem';
import TopBanner from './TopBanner';

const CustomBox = styled(Box)(({ theme }) => ({
  height: '100%',
  marginTop: theme.spacing(-4),
  marginBottom: 0,
  marginLeft: theme.spacing(-1.5),
  marginRight: theme.spacing(-1.5),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(5),
}));

function Research() {
  return (
    <CustomBox>
      <TopBanner pText="Montu Research Library" h1Text="Medical Cannabis Research Reports" />
      <CustomContainer>
        <Box>
          <CustomTypography variant="h4">Montu Research Library</CustomTypography>
        </Box>
        <Accordion allowZeroExpanded>
          {accordionData.map((item) => (
            <ResearchAccordionItem key={item.title} title={item.title} links={item.links} />
          ))}
        </Accordion>
      </CustomContainer>
    </CustomBox>
  );
}

export default Research;
