import { useEffect } from 'react';
import { toast } from 'react-toastify';

function useSessionExpirationCheck() {
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.get('expired')) {
      toast.error('Your session has expired, please login again.');
    }
  }, []);
}

export default useSessionExpirationCheck;
