import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AuthService } from '../../services/authentication.service';
import settings from '../../constants/constants';
import ResourceCard from './ResourceCard';
import TopBanner from './TopBanner';

const url = settings.url;

function Resourses() {
  const mlInit = {
    name: '...',
    phone: '...',
    email: '...',
  };
  const userId = AuthService.getUser().user.id;
  const role = AuthService.getUser().user.role_id;
  const [mlInfo, setMLInfo] = useState(mlInit);
  useEffect(() => {
    if (role === 2) {
      axios.get(url + '/liaison/getByGP/' + userId).then((resp) => {
        setMLInfo(resp.data);
      });
    }
  }, [role, userId]);

  return (
    <div className="resources-content">
      <TopBanner pText="" h1Text="Resources" />
      {role === 2 && (
        <div>
          <div className="card text-center border-0 mx-auto p-4" style={{ maxWidth: '550px' }}>
            <p>Your dedicated Account Manager is available to support you:</p>
            <p className="m-1">N: {mlInfo.name}</p>
            <p className="m-1">P: {mlInfo.phone}</p>
            <p className="m-1">E: {mlInfo.email}</p>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center flex-wrap mt-4">
        <ResourceCard
          title="Research"
          body="Learn more about how medical cannabis is used to treat a range of conditions"
          link="/resources/research"
          cssClass="mr-3"
        />
        <ResourceCard
          body="View our library of previous virtual roundtable recordings by experienced doctors and professionals"
          title="Virtual Roundtable Recordings"
          link="/resources/cpd-sessions"
          cssClass="ml-3"
        />
        <ResourceCard
          title="Product Overview"
          body="Explore our full Circle product offering"
          link="/resources/products"
          cssClass="ml-3"
        />
      </div>
    </div>
  );
}

export default Resourses;
