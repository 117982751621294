import { Card, CardContent, Stack, styled, Typography } from '@mui/material';

import HeaderUnauth from '../layout/header-unauth';

const CenteredStack = styled(Stack)({
  height: '70vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  marginTop: theme.spacing(6),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(12),
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
}));

const ResponsiveCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  width: '80%',
  height: 'auto',
  padding: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    height: '30vh',
    width: '25%',
  },
}));
function PharmacySuccess() {
  return (
    <>
      <HeaderUnauth />
      <CenteredStack>
        <ResponsiveCard>
          <CardContent>
            <Title variant="h5">Thank you!</Title>
            <Subtitle variant="body1">Your pharmacy account submission has been submitted</Subtitle>
          </CardContent>
        </ResponsiveCard>
      </CenteredStack>
    </>
  );
}

export default PharmacySuccess;
