import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { UserService } from '@/services/user.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useUserProfileData');

interface IUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  address: string;
  zip_code: string;
  city: string;
  country_id: number;
  state_id: number;
}

interface ICountry {
  id: number;
  name: string;
}

export interface IState {
  id: number;
  name: string;
}

interface IPatient {
  id: number;
  medicare_number: string | null;
  dob: string;
  client_code: string;
}

export interface IUserProfile {
  user: IUser;
  countries: ICountry[];
  states: IState[];
  patient: IPatient;
}

export const useUserProfileData = () => {
  const { data, isLoading, isError, isSuccess } = useQuery<IUserProfile>(['userProfileData'], async () => {
    try {
      const response = await UserService.fetchUserProfile();
      if (response.data) {
        return response.data;
      }
      throw new Error('failed to fetch data');
    } catch (e) {
      logger.error('Error fetching user profile, error:', e);
      throw e;
    }
  });

  const getUserState = useCallback(() => {
    if (!data || !data.states || !data.user) return null;

    const userState = data.states.find((state) => data.user.state_id === state.id);

    return userState ? userState.name : null;
  }, [data]);

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    getUserState,
  };
};

export default useUserProfileData;
