import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Skeleton, styled, Typography } from '@mui/material';
import moment from 'moment';

import BrainfishHelpDeskPopup from '@/components/brainfish/HelpDeskPopup';
import Error from '@/components/error/Error';
import OrderHistoryCard from '@/components/order/order-history-card/OrderHistoryCard';
import OrderHistoryCardLoading from '@/components/order/order-history-card/OrderHistoryCardLoading';
import useGetPatientOrders, { PatientOrder } from '@/hooks/patient/useGetPatientOrders';
import generateTrackingLink from '@/utils/patient/trackingLink';

import constants from '../../../constants/constants';

const getPastOrderMonth = (yearMonth: string) => moment(yearMonth, 'YYYY-MM').format('MMMM YYYY');

const OrderHistoryCardContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  width: '100%',
}));

const PastOrderMonthHeadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
}));

const PatientOrdersV2LoadingContainer = styled(ContentContainer)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(5),
}));

const PageHeadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
  flexDirection: 'column',
  [`${theme.breakpoints.up('lg')}`]: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
}));

const activeStatuses = [
  constants.orderStatus.AWAITING_PAYMENT,
  constants.orderStatus.PAID_NOT_APPROVED,
  constants.orderStatus.APPROVED_NOT_PAID,
  constants.orderStatus.AWAITING_SHIPMENT,
];

function PatientOrdersV2Loading() {
  return (
    <PatientOrdersV2LoadingContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ padding: '1rem 0' }}>
          <Typography variant="h5" fontWeight="bold">
            <Skeleton />
          </Typography>
        </Box>
        <OrderHistoryCardContainer>
          <OrderHistoryCardLoading />
        </OrderHistoryCardContainer>
        <OrderHistoryCardContainer>
          <OrderHistoryCardLoading />
        </OrderHistoryCardContainer>
        <OrderHistoryCardContainer>
          <OrderHistoryCardLoading />
        </OrderHistoryCardContainer>
      </Box>
    </PatientOrdersV2LoadingContainer>
  );
}

export default function PatientOrdersV2() {
  const { data: orders, isError, isLoading } = useGetPatientOrders();

  if (isLoading) {
    return <PatientOrdersV2Loading />;
  }

  if (isError) {
    return (
      <ContentContainer>
        <Error status={500} />
      </ContentContainer>
    );
  }

  const activeOrders: PatientOrder[] = [];
  const pastOrders = new Map<string, PatientOrder[]>();

  orders?.forEach((order: PatientOrder) => {
    if (activeStatuses.includes(order.orderStatus)) {
      activeOrders.push(order);
    } else {
      // map past orders, in desc, grouped by year and month
      const mapKey = moment(order.order_date).format('YYYY-MM');
      if (!pastOrders.has(mapKey)) {
        pastOrders.set(mapKey, []);
      }
      pastOrders.get(mapKey)?.push(order);
    }
  });

  const pastOrdersMapKeys = Array.from(pastOrders.entries());
  return (
    <>
      <Box>
        <ContentContainer>
          <Box sx={{ width: '100%' }}>
            <PageHeadingContainer>
              <Button variant="contained" color="secondary" component={RouterLink} to="/patient/refill">
                Order Medication
              </Button>
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  Active Orders
                </Typography>
              </Box>
            </PageHeadingContainer>
            <Box>
              {activeOrders.length ? (
                activeOrders.map((order) => (
                  <OrderHistoryCardContainer key={order.id}>
                    <OrderHistoryCard
                      orderNumber={order.order_code}
                      cancelledDate={order.order_history_cancelled_date}
                      orderLink={`/patient/order-detail/${order.order_code}`}
                      numberOfItems={order.OrderProducts.length}
                      orderDate={new Date(order.order_date)}
                      pharmacyName={order.pharmacy?.name}
                      orderPrice={order.total_price}
                      orderStatus={order.orderStatus}
                      delivered={order.delivered}
                      trackingLink={generateTrackingLink(order.shipment_carrier, order.shipment_tracking_id)}
                      orderProducts={order.OrderProducts.map((p) => ({
                        id: p.id,
                        name: p.Product.name,
                        quantity: p.quantity,
                        price: p.Product.price,
                      }))}
                      dispensingFee={order.dispensing_fee}
                      splitOrder={!order.orderStatus}
                    />
                  </OrderHistoryCardContainer>
                ))
              ) : (
                <Typography>You have no active orders</Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ padding: '16px 0' }}>
              <Typography variant="h5" fontWeight="bold">
                Past Orders
              </Typography>
            </Box>
            {pastOrders.size ? (
              pastOrdersMapKeys.map(([key, groupedOrders]) => (
                <OrderHistoryCardContainer key={key}>
                  <PastOrderMonthHeadingContainer>
                    <Typography variant="caption" color="primary.contrastText" fontWeight="bold">
                      {getPastOrderMonth(key).toUpperCase()}
                    </Typography>
                  </PastOrderMonthHeadingContainer>
                  {groupedOrders.map((order) => (
                    <OrderHistoryCardContainer key={order.id}>
                      <OrderHistoryCard
                        orderNumber={order.order_code}
                        orderLink={`/patient/order-detail/${order.order_code}`}
                        numberOfItems={order.OrderProducts.length}
                        orderDate={new Date(order.order_date)}
                        pharmacyName={order.pharmacy?.name}
                        orderPrice={order.total_price}
                        orderStatus={order.orderStatus}
                        delivered={order.delivered}
                        trackingLink={generateTrackingLink(order.shipment_carrier, order.shipment_tracking_id)}
                        orderProducts={order.OrderProducts.map((p) => ({
                          id: p.id,
                          name: p.Product.name,
                          quantity: p.quantity,
                          price: p.Product.price,
                        }))}
                        dispensingFee={order.dispensing_fee}
                        splitOrder={!order.orderStatus}
                        cancelledDate={order.order_history_cancelled_date}
                      />
                    </OrderHistoryCardContainer>
                  ))}
                </OrderHistoryCardContainer>
              ))
            ) : (
              <Typography>You have no past orders</Typography>
            )}
          </Box>
        </ContentContainer>
      </Box>
      <BrainfishHelpDeskPopup />
    </>
  );
}
