import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Logger } from '@/utils/logger';

const logger = new Logger('Prescription');

type Props = {
  data: {
    repeats: number;
    thcStrengthForDisplay?: string;
    cbdStrengthForDisplay?: string;
  };
  hideRepeats?: boolean;
};

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  [theme.breakpoints.up('lg')]: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    columnGap: '0.65rem',
  },
}));

const Contents = styled.div(({ theme }) => ({
  backgroundColor: theme.palette?.grey?.[50],
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0.25rem 0.5rem',
  height: 'fit-content',
  marginBottom: '0.25rem',
  marginTop: 0,
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    gridColumnStart: 1,
    gridRowStart: 1,
    padding: 0,
    margin: 0,
  },
}));

const Items = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  '&:first-of-type': {
    marginRight: '0.5rem',
  },
  '&:only-of-type': {
    marginRight: 0,
  },
  [theme.breakpoints.up('lg')]: {
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      marginRight: 0,
    },
  },
}));

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette?.grey?.[800]};
`;

const ItemValue = styled(Typography)`
  font-weight: 800;
  color: ${({ theme }) => theme.palette?.grey?.[800]};
`;

const Quantity = styled.div(({ theme }) => ({
  backgroundColor: theme.palette?.grey?.[50],
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0.5rem',
  height: 'fit-content',
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
    alignSelf: 'center',
    gridColumnStart: 2,
    gridRowStart: 1,
    padding: 0,
  },
}));

function isNotEmptyOrDash(val?: string | null) {
  try {
    if (typeof val !== 'string') return false;
    if (!val) return false;
    if (!val.trim().replace(/-/g, '')) return false;
    return true;
  } catch (e) {
    logger.error('isNotEmptyOrDash', e);
    return false;
  }
}

export default function Prescription({
  data: { repeats, cbdStrengthForDisplay, thcStrengthForDisplay },
  hideRepeats = false,
}: Props) {
  const displayThc = isNotEmptyOrDash(thcStrengthForDisplay);
  const displayCbd = isNotEmptyOrDash(cbdStrengthForDisplay);
  const displayAtLeastThcOrCbd = displayThc || displayCbd;

  return (
    <Container>
      {displayAtLeastThcOrCbd && (
        <Contents>
          {displayThc && (
            <Items>
              <ItemName>THC:&nbsp;</ItemName>
              <ItemValue>{thcStrengthForDisplay}</ItemValue>
            </Items>
          )}
          {displayThc && (
            <Items>
              <ItemName>CBD:&nbsp;</ItemName>
              <ItemValue>{cbdStrengthForDisplay}</ItemValue>
            </Items>
          )}
        </Contents>
      )}
      {!hideRepeats && (
        <Quantity>
          <Items>
            <ItemName>Repeats:&nbsp;</ItemName>
            <ItemValue>{repeats}</ItemValue>
          </Items>
        </Quantity>
      )}
    </Container>
  );
}
