import { useHistory, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

const defaultBackText = 'Back';

export type PartnerBackProps = {
  backText?: string;
  defaultRoute?: string;
};

function PartnerBack({ backText = defaultBackText, defaultRoute }: PartnerBackProps) {
  const history = useHistory();
  const location = useLocation();

  const hasBack = location.key && location.key !== 'default';
  const canNav = Boolean(hasBack || defaultRoute);

  const handleClickBack = () => {
    if (hasBack) {
      history.goBack();
    } else {
      history.push(defaultRoute);
    }
  };

  return (
    <Box sx={{ mb: 3, display: 'flex' }}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ChevronLeft />}
        onClick={handleClickBack}
        disabled={!canNav}
      >
        {backText}
      </Button>
    </Box>
  );
}

export default PartnerBack;
