import { styled } from '@mui/material';

export const BrandedForm = styled('form')(
  ({ theme }) => `
  --bgColor: white;
  input, .form-control {
    border-color: ${theme.palette.primary.main};
    border-radius: 0.25rem;
    border-width: 1px;
    padding: 0.5rem 1rem;
    min-height: 3.5rem;
    font-size: 1.2rem;
    &::placeholder {
      font-weight: 400;
    }
  }

  label, .label {
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};
    margin-bottom: -0.75rem;
    padding: 0 0.25rem;
    margin-left: 1rem;
    font-size: 0.8rem;    
    z-index: 1;
  }
`,
);

export default BrandedForm;
