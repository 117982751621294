import React from 'react';
import { WithSnowplow } from '@montugroup/data-collection';

import { FF_ENABLE_SNOWPLOW_CIRCUIT } from '@/constants/featureFlags';
import { SNOWPLOW_APP_ID, SNOWPLOW_TRACKER_NAME } from '@/constants/snowplow';
import { usePatientIdQuery } from '@/hooks/rest/usePatientIdQuery';
import useFeatureFlags from '@/hooks/useFeatureFlags';

// This component acts as a wrapper around WithSnowplow from data-collection
// It ensures the patientId field is properly set at all times
export const AuthenticatedSnowplow = (props: React.PropsWithChildren) => {
  const { children } = props;
  const { flags } = useFeatureFlags();
  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW_CIRCUIT];
  const patientId = usePatientIdQuery();

  return WithSnowplow(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>,
    {
      appId: SNOWPLOW_APP_ID,
      trackerName: SNOWPLOW_TRACKER_NAME,
      userId: patientId,
    },
    enableSnowplow,
  );
};

export default AuthenticatedSnowplow;
