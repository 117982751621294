import { Box, styled } from '@mui/material';

export const PageContentGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  // Mobile grid
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `"image summary"
  "details details"
  `,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
    // Desktop grid
    gridTemplateColumns: '314px 1fr',
    gridTemplateAreas: `"image summary"
    "image details"
    `,
  },
}));

export default PageContentGrid;
