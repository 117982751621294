import { Step, StepLabel, Stepper, styled, useMediaQuery, useTheme } from '@mui/material';
import { stepIconClasses } from '@mui/material/StepIcon';
import { stepLabelClasses } from '@mui/material/StepLabel';

import { FF_ENABLE_ORDER_PHARMACY_ALLOCATION } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

export type PurchaseStage = 'Order' | 'Pharmacy' | 'Payment';
const StagesInOrder: PurchaseStage[] = ['Order', 'Pharmacy', 'Payment'];

interface PurchaseStepperProps {
  stage: PurchaseStage;
}

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  gap: theme.spacing(1),
  [`& .${stepIconClasses.root}.${stepIconClasses.active}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${stepIconClasses.root}.${stepIconClasses.completed}`]: {
    color: theme.palette.success.main,
  },
  [`& .${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
    marginTop: 0,
  },
}));

export function PurchaseStepper(props: PurchaseStepperProps) {
  const { stage } = props;
  const { flags } = useFeatureFlags();
  const index = StagesInOrder.indexOf(stage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stepper activeStep={index} alternativeLabel={isMobile}>
      <Step key="Order" sx={{ paddingLeft: 0 }}>
        <StyledStepLabel>Order</StyledStepLabel>
      </Step>
      {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? (
        <Step key="Pharmacy">
          <StyledStepLabel>Pharmacy</StyledStepLabel>
        </Step>
      ) : null}
      <Step key="Payment" sx={{ paddingRight: 0 }}>
        <StyledStepLabel>Payment</StyledStepLabel>
      </Step>
    </Stepper>
  );
}
