// import settings from '@/constants/constants';

import { ConsultationResponseDto } from '@montugroup/pms-api-contracts';
import { DateTime } from 'luxon';

/**
 * Generates a URL for a doctor's booking page on Cal.com.
 *
 * @param {string} calcomUsername - The Cal.com username of the doctor.
 * @param {string} consultationSlug - The slug of the consultation.
 * @return {string} The URL of the doctor's booking page.
 */
const buildDoctorBookingLink = (calcomUsername: string | undefined, consultationSlug: string | undefined): string => {
  if (!calcomUsername || !consultationSlug) {
    return '';
  }
  // return `${settings.calendarConfig.calOrigin}/${calcomUsername}/${consultationSlug}`;
  return `/${calcomUsername}/${consultationSlug}`;
};

export default buildDoctorBookingLink;

/**
 * Determines whether a patient can book a consultation with a new doctor.
 * A patient can book a consultation with a new doctor if they have no open orders,
 * have not returned for 4 over months, or if their current doctor is not available
 * within the set timeframe (default 10 days).
 * @param {boolean} currentDoctorIsUnavailable Whether the patient's current doctor is unavailable
 * @param {boolean} recentlyReturnedPatient Whether the patient has returned within the last 4 months
 * @param {number} [openOrdersCount] The number of open orders the patient has
 * @return {boolean} Whether the patient can book a consultation with a new doctor
 */
export function canPatientBookNewDoctor(
  currentDoctorIsUnavailable: boolean,
  recentlyReturnedPatient: boolean,
  openOrdersCount?: number,
): boolean {
  if (openOrdersCount && openOrdersCount > 0) {
    return false;
  }

  return currentDoctorIsUnavailable || !recentlyReturnedPatient;
}

/**
 * Determines whether the patient's last consultation that has a completed status is within the last 4 months.
 * @param {ConsultationResponseDto[]} consultData - An array of consultation objects.
 * @return {boolean} Whether the patient's last consultation is within the last 4 months.
 */
export function isLastConsultationWithinFourMonths(consultData: ConsultationResponseDto[]): boolean {
  const recentlyReturnedPatient = consultData
    .filter((consult) => consult.status.toLowerCase() === 'completed')
    .some((consult) => consult.startTime && DateTime.fromISO(consult.startTime) > DateTime.now().minus({ months: 4 }));

  return recentlyReturnedPatient;
}
