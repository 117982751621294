import { ReactNode } from 'react';
import { Box, Divider, Stack, styled, Typography, TypographyProps } from '@mui/material';

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
}));

function Title(props: Pick<TypographyProps, 'children'>) {
  return (
    <Box>
      <Typography variant="body2" fontWeight="bold" gutterBottom {...props} />
      <Divider />
    </Box>
  );
}

const Content = styled(Box)({});

export type SectionProps = {
  children: ReactNode;
};

export function Section({ children }: SectionProps) {
  return <Container>{children}</Container>;
}

Section.Title = Title;
Section.Content = Content;

export default Section;
