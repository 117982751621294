import { useQuery } from 'react-query';

import { PharmacistOrderTableView } from '@/context/pharmacist/orders/PharmacistOrders';
import {
  getPharmacistOrders,
  PharmacistOrdersResponse,
  PharmacistOrdersSortingOrder,
} from '@/services/pharmacist.service';

export type UseGetPharmacistOrdersParams = {
  page: number;
  pageSize: number;
  sortingOrder: PharmacistOrdersSortingOrder;
  filter: string;
  activeTab: PharmacistOrderTableView;
};

function useGetPharmacistOrders({ page, pageSize, sortingOrder, filter, activeTab }: UseGetPharmacistOrdersParams) {
  const { isLoading, data, isError, isSuccess, refetch } = useQuery<PharmacistOrdersResponse>({
    queryKey: ['getPharmacistOrders', page, pageSize, sortingOrder, filter, activeTab],
    queryFn: async ({ signal }) => {
      try {
        const response = await getPharmacistOrders(pageSize, page, sortingOrder, filter, activeTab, signal);
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch doctors availability: ${(error as { message: string }).message}`);
      }
    },
  });

  return { isLoading, data, isError, isSuccess, refetch };
}

export default useGetPharmacistOrders;
