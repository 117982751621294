import { matchPath } from 'react-router-dom';

export const PATIENT_PORTAL_PARTNER_ROUTES = [
  '/patient/refill/:id?',
  '/patient/refill-pharmacy',
  '/patient/orders',
  '/customer/product-detail/:id',
];

export const isPatientPortalPartnerPage = (pathname: string) =>
  Boolean(
    PATIENT_PORTAL_PARTNER_ROUTES.some((portalPartnerRoute) => matchPath(pathname, { path: portalPartnerRoute })),
  );

export default null;
