import React from 'react';
import { Box } from '@mui/material';

import { AuthService } from '../../services/authentication.service';
import Header from '../layout/header';

function SignOut() {
  React.useEffect(() => {
    AuthService.logout();
  }, []);

  return (
    <>
      <Header />
      <Box>Signing out...</Box>
    </>
  );
}

export default SignOut;
