import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { PharmacyService } from '@/services/pharmacy.service';

import usePharmacyStore, { Coordinates } from './pharmacyAllocationStore';

function isValidPostcode(postcode: string): boolean {
  return /^\d{4}$/.test(postcode.trim());
}

function isValidLatLon(latLon: string): boolean {
  return /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10}),-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$$/.test(latLon.trim());
}

function stringifyLatLon(coordinates: Coordinates | null): string {
  if (!coordinates) {
    return '';
  }

  return `${coordinates.latitude},${coordinates.longitude}`;
}

function parseLatLon(rawLatLon: string): Coordinates | null {
  if (!isValidLatLon(rawLatLon)) {
    return null;
  }
  const [latitude, longitude] = rawLatLon.split(',');

  return {
    latitude,
    longitude,
  };
}

const POSTCODE_SPLIT_REGEX = /,\s*/;

function PharmacyAllocationSettings() {
  const { originalData, modifications, setFetchedData, modifyPharmacy, resetModifications } = usePharmacyStore();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PharmacyService.getPharmacyAllocationSettings();
        setFetchedData(response.data.data);
      } catch (e) {
        setError('Failed to fetch pharmacy allocation settings');
      }
    };
    fetchData();
  }, [setFetchedData]);

  const handleSave = async () => {
    const modifiedEntries = Object.entries(modifications).map(([id, data]) => ({
      pharmacyId: Number(id),
      ...data,
      sameDayDeliveryPostcodes: data.sameDayDeliveryPostcodes?.split(POSTCODE_SPLIT_REGEX)?.filter((p) => p !== ''),
      coordinates: data.coordinates ? parseLatLon(data.coordinates) : undefined,
    }));

    try {
      await PharmacyService.updatePharmacyAllocationSettings(modifiedEntries);
      setError(null);
      const response = await PharmacyService.getPharmacyAllocationSettings();
      setFetchedData(response.data.data);
      resetModifications();
    } catch (e) {
      setError('Failed to update pharmacy allocation settings');
    }
  };

  const isSaveDisabled =
    // There are no modifications
    Object.keys(modifications).length === 0 ||
    // All modifications to postcodes are valid
    Object.entries(modifications).some(
      ([, mod]) =>
        mod.sameDayDeliveryPostcodes &&
        mod.sameDayDeliveryPostcodes.split(POSTCODE_SPLIT_REGEX).some((postcode) => !isValidPostcode(postcode)),
    ) ||
    // There are invalid postcodes in the original data and they are not modified
    originalData?.some(
      (pharmacy) =>
        !modifications[pharmacy.pharmacyId] &&
        pharmacy.sameDayDeliveryPostcodes.some((postcode) => !isValidPostcode(postcode)),
    );

  function getPostcodesBackgroundColor(pharmacyId: number, sameDayDeliveryPostcodes: string | undefined) {
    // eslint-disable-next-line no-undefined
    const isModified = modifications[pharmacyId]?.sameDayDeliveryPostcodes !== undefined;
    const isEmpty = !sameDayDeliveryPostcodes || sameDayDeliveryPostcodes.trim() === '';
    const postcodeError =
      !isEmpty && sameDayDeliveryPostcodes.split(POSTCODE_SPLIT_REGEX).some((postcode) => !isValidPostcode(postcode));

    if (postcodeError) {
      return 'salmon';
    }
    if (
      isModified &&
      sameDayDeliveryPostcodes !==
        originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId)?.sameDayDeliveryPostcodes.join(',')
    ) {
      return 'lightgreen';
    }
    return 'none';
  }

  function getLatLonBackgroundColour(pharmacyId: number) {
    const original = originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId);

    if (!original) {
      throw Error(`Pharmacy not found in formatLatLon. pharmacyId: ${pharmacyId}`);
    }

    const modified = modifications[pharmacyId]?.coordinates;

    if (!modified || modified === stringifyLatLon(original.coordinates)) {
      return 'none';
    }

    if (isValidLatLon(modified)) {
      return 'lightgreen';
    }

    return 'salmon';
  }

  function isToggleDisabled(sameDayDeliveryPostcodes: string | undefined) {
    if (!sameDayDeliveryPostcodes || sameDayDeliveryPostcodes.trim() === '') {
      return true;
    }
    return sameDayDeliveryPostcodes.split(POSTCODE_SPLIT_REGEX).some((postcode) => !isValidPostcode(postcode));
  }

  function formatPostcodes(pharmacyId: number): string {
    return (
      modifications[pharmacyId]?.sameDayDeliveryPostcodes ??
      originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId)?.sameDayDeliveryPostcodes.join(',') ??
      ''
    );
  }

  function getLatLonInputValue(pharmacyId: number): string {
    const modification = modifications[pharmacyId]?.coordinates;

    if (modification) {
      return modification;
    }

    const original = originalData?.find((pharmacy) => pharmacy.pharmacyId === pharmacyId);

    if (!original) {
      throw Error(`Pharmacy not found in formatLatLon. pharmacyId: ${pharmacyId}`);
    }

    return stringifyLatLon(original.coordinates);
  }

  if (!originalData) {
    return <CircularProgress color="info" size={25} />;
  }

  return (
    <Box>
      <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3" gutterBottom>
        Pharmacy Allocation Settings
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button sx={{ align: 2 }} onClick={handleSave} disabled={isSaveDisabled}>
          Save
        </Button>
      </Box>
      <Table sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Pharmacy Code</TableCell>
            <TableCell>Pharmacy Name</TableCell>
            <TableCell>Dispensed Today</TableCell>
            <TableCell>Currently Allocated</TableCell>
            <TableCell>Total Orders Today</TableCell>
            <TableCell>Daily Dispense Limit</TableCell>
            <TableCell>Lat/Lon</TableCell>
            <TableCell>Same Day Delivery Postcodes</TableCell>
            <TableCell>Same Day Delivery Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
          {originalData.map((pharmacy) => (
            <TableRow key={pharmacy.pharmacyId}>
              <TableCell>{pharmacy.pharmacyCode}</TableCell>
              <TableCell>{pharmacy.pharmacyName.replace('(HOME DELIVERY)', '').trim()}</TableCell>
              <TableCell>{pharmacy.stats.counts.dispensedToday}</TableCell>
              <TableCell>{pharmacy.stats.counts.pendingDispense}</TableCell>
              <TableCell>{pharmacy.stats.counts.totalToday}</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={modifications[pharmacy.pharmacyId]?.dailyDispenseLimit ?? pharmacy.stats.limits.daily.limit}
                  onChange={(e) => modifyPharmacy(pharmacy.pharmacyId, { dailyDispenseLimit: Number(e.target.value) })}
                  style={{
                    background: modifications[pharmacy.pharmacyId]?.dailyDispenseLimit ? 'lightgreen' : 'none',
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={getLatLonInputValue(pharmacy.pharmacyId)}
                  onChange={(e) => {
                    const input = e.target.value;
                    modifyPharmacy(pharmacy.pharmacyId, {
                      coordinates: input,
                    });
                  }}
                  style={{
                    background: getLatLonBackgroundColour(pharmacy.pharmacyId),
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={formatPostcodes(pharmacy.pharmacyId)}
                  onChange={(e) => {
                    const input = e.target.value;
                    modifyPharmacy(pharmacy.pharmacyId, {
                      sameDayDeliveryPostcodes: input,
                      sameDayDeliveryEnabled:
                        input !== ''
                          ? modifications[pharmacy.pharmacyId]?.sameDayDeliveryEnabled ??
                            pharmacy.sameDayDeliveryEnabled
                          : false,
                    });
                  }}
                  style={{
                    background: getPostcodesBackgroundColor(pharmacy.pharmacyId, formatPostcodes(pharmacy.pharmacyId)),
                  }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  checked={
                    modifications[pharmacy.pharmacyId]?.sameDayDeliveryEnabled ?? pharmacy.sameDayDeliveryEnabled
                  }
                  onChange={(e) => modifyPharmacy(pharmacy.pharmacyId, { sameDayDeliveryEnabled: e.target.checked })}
                  disabled={isToggleDisabled(formatPostcodes(pharmacy.pharmacyId))}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {error && (
        <Modal open={Boolean(error)} onClose={() => setError(null)}>
          <Alert severity="error">{error}</Alert>
        </Modal>
      )}
    </Box>
  );
}

export default PharmacyAllocationSettings;
