import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Container, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import Error from '@/components/error/Error';
import ProductDetails from '@/components/products/productDetailsV2/ProductDetails';
import ProductDetailsLoadingBlock from '@/components/products/productDetailsV2/ProductDetailsLoadingBlock';
import ProductOverview from '@/components/products/productDetailsV2/ProductOverview';
import ProductWhiteLabelImage from '@/components/products/productDetailsV2/ProductWhiteLabelImage';
import { DEFAULT_NO_VALUE_STR, getProductBrand } from '@/components/products/utils';
import useProductDetails from '@/hooks/products/useProductDetails';
import BackButton from '@/ui-library/backButton/BackButton';
import { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';

const ProductContainer = styled(Box)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    gap: theme.spacing(6),
  },
}));

const ContentContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    display: 'block',
    flex: 1,
    marginBottom: theme.spacing(0),
  },
}));

const BackLinkContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  display: 'flex',
}));

export default function ProductDetailsPageV2() {
  const { id: productId } = useParams();

  const history = useHistory();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { product, isError, isLoading } = useProductDetails(productId);

  if (isLoading) {
    return <ProductDetailsLoadingBlock />;
  }

  if (isError || !product) {
    return (
      <ContentContainer>
        <Error
          status={product ? 500 : 404}
          buttons={[
            <Button variant="contained" onClick={() => history.goBack()}>
              Back to Medicine information
            </Button>,
          ]}
        />
      </ContentContainer>
    );
  }

  const imageUrl = getWhiteLabelProductImageUrl({
    whiteLabelImageUrl: product.ProductFormulation?.whitelabel_image_url,
  });

  return (
    <Box>
      <ProductContainer>
        {isMobileView && (
          <BackLinkContainer>
            <BackButton />
            <Typography variant="h5" component="h5" color="primary.main">
              Medicine information
            </Typography>
          </BackLinkContainer>
        )}

        <DetailsContainer>
          <Box flex={1} flexBasis={{ xs: '40%', md: 'auto' }}>
            <ProductWhiteLabelImage whiteLabelImageUrl={imageUrl} />
          </Box>
          {isMobileView && (
            <Box flex={1} flexBasis={{ xs: '60%', md: 'auto' }}>
              <ProductDetails
                product={{
                  productFormulationName: product.ProductFormulation?.name ?? null,
                  thcStrengthForDisplay: product.thc_strength_for_display,
                  cbdStrengthForDisplay: product.cbd_strength_for_display,
                  isConcession: product.is_concession,
                  name: product.name,
                }}
              />
            </Box>
          )}
        </DetailsContainer>

        <Stack flex={2} gap={6}>
          {!isMobileView && (
            <ProductDetails
              product={{
                productFormulationName: product.ProductFormulation?.name ?? null,
                thcStrengthForDisplay: product.thc_strength_for_display,
                cbdStrengthForDisplay: product.cbd_strength_for_display,
                isConcession: product.is_concession,
                name: product.name,
              }}
            />
          )}
          <ProductOverview
            product={{
              brand: getProductBrand(product.Suppliers),
              productIngredientName: product.ProductIngredient?.name ?? null,
              contents: product.contents,
              descriptionForDisplay: product.description_for_display ?? DEFAULT_NO_VALUE_STR,
              productScheduleName: product.ProductSchedule?.name ?? DEFAULT_NO_VALUE_STR,
              productStrainName: product.ProductStrain?.name ?? DEFAULT_NO_VALUE_STR,
              spectrumId: product.spectrum_id,
              howToUse: product.how_to_use,
            }}
          />
        </Stack>
      </ProductContainer>
    </Box>
  );
}
