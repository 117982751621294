import { apiCustomerPortalContract } from '@montugroup/circuit-api-contracts';

import TsRestClient from './ts-rest.client';

let cachedClient: TsRestClient<typeof apiCustomerPortalContract> | null = null;

export const getTsCustomerPortalClient = () => {
  if (!cachedClient) {
    cachedClient = new TsRestClient(apiCustomerPortalContract);
  }

  return cachedClient.getClient();
};

export default null;
