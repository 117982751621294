import { Box, Skeleton } from '@mui/material';

import { PageContent, PageSection } from './PatientRefill.styles';

function PatientRefillLoadingSkeleton() {
  return (
    <PageContent data-testid="loading-skeleton">
      <PageSection sx={{ gridArea: 'error' }} />
      <PageSection sx={{ gridArea: 'title' }}>
        <Skeleton variant="text" />
      </PageSection>
      <PageSection sx={{ gridArea: 'steps' }}>
        <Skeleton variant="rectangular" />
      </PageSection>
      <PageSection sx={{ gridArea: 'products' }}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </PageSection>
      <Box sx={{ gridArea: 'purchase' }}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="text" />
      </Box>
    </PageContent>
  );
}

export default PatientRefillLoadingSkeleton;
