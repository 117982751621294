import { Box, Typography, useTheme } from '@mui/material';

import { RequestButton } from './common';

type RequestAlternativeProductProps = {
  rescriptRequestDate?: string | null;
  handleRequestAlternativeProduct: () => void;
};

export default function RequestAlternativeProduct({
  rescriptRequestDate,
  handleRequestAlternativeProduct,
}: RequestAlternativeProductProps) {
  const theme = useTheme();

  return (
    <Box>
      {rescriptRequestDate && (
        <>
          <Typography variant="h6" marginBottom="8px">
            Request processing
          </Typography>
          <Typography>
            Your alternative product request made on <span style={{ fontWeight: 700 }}>{rescriptRequestDate}</span> is
            being processed by your doctor
          </Typography>
        </>
      )}

      <RequestButton
        data-testid="request-btn"
        color="primary"
        disabled={!!rescriptRequestDate}
        variant="contained"
        onClick={handleRequestAlternativeProduct}
      >
        Request alternative product
      </RequestButton>

      <Typography color={rescriptRequestDate ? theme.palette.grey[500] : {}}>
        Once your request is sent, your doctor will be notified and look at alternative options
      </Typography>
    </Box>
  );
}
