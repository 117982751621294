import React from 'react';
import TopBanner from './TopBanner';

function CPDSessions() {
  return (
    <React.Fragment>
      <div className="h-100 resources-content">
        <TopBanner pText="CANNABIS PRESCRIBER DISCUSSIONS" h1Text="Watch Our Latest Roundtable Session" />
        <section className="bg-blue py-4 text-white">
          <div className="container">
            <div className="text-center">
              <p>LATEST</p>
              <h2>Learn how Complex Pain, Anxiety and Sleep can be improved with Medical Cannabis</h2>
              <p className="mb-1">Presented By Dr Kevin Cheng (MBBS)</p>
              <video
                controls
                className="mt-4"
                style={{ maxHeight: '870px', maxWidth: '880px' }}
                src="https://circuit.montu.com.au/assets/videos/20210326+CPD+Recording+Dr+Cheng_vF.mp4"
              ></video>
              <p className="m-0 mt-4">
                One in three Australian adults with severe pain, have high or very high levels of psychological
                distress. Chronic Pain and Anxiety related disorders account for more than 85% of all Medical Cannabis
                prescriptions in Australia.
              </p>
              <p className="m-0">
                Medical cannabis has shown to be complementary to conventional pain management and in many cases led to
                a significant reduction in side effects associated with opioid, NSAID and SSRI intake.
              </p>
              <br />
              <p className="m-0">
                <b>Benefits from this session:</b>
              </p>
              <p className="m-0">
                - Understand how Medical Cannabis is being used to treat highly prevalent but complex comorbidities
              </p>
              <p className="m-0">- How Medical Cannabis improves sleep and daily function</p>
              <p className="m-0">- Understand the place of Schedule 4 cannabis products and driving</p>
            </div>
            <hr className="my-5 bg-white" />
            <div className="text-center">
              <p>PAST SESSIONS</p>
              <h2>Learn how Arthritis, Fibromyalgia and Endometriosis patients can benefit from Medical Cannabis</h2>
              <p className="mb-1">Presented By Dr Anjali Didi</p>
              <small className="d-block">
                (MBBS. MRCGP. BSc. DFSRH (lon). DipGeriatrics. DipDerm. Lociut. LocSdi.)
              </small>
              <video
                controls
                className="mt-4"
                style={{ maxHeight: '870px', maxWidth: '880px' }}
                src="https://circuit.montu.com.au/assets/videos/20200827+Montu+-+Dr+Didi+Recording_vF2.mp4"
              ></video>
              <p className="m-0 mt-4">
                Chronic Pain accounts for more than 70% of all Medical Cannabis prescriptions in Australia, making it
                the number one indication approved by the TGA. Medical cannabis has shown to be complimentary to
                conventional pain management and in many cases led to a significant reduction in opioid intake. Learn
                how it can be applied to patients with Arthritis, Fibromyalgia, and Endometriosis.
              </p>
              <br />
              <p>
                <b>Benefits from this session:</b>
              </p>
              <p className="m-0">- Realize the relevance of Medical Cannabis for chronic pain</p>
              <p className="m-0">- Learn about the opioid sparing effect</p>
              <p className="m-0">- Know how to initiate Medical Cannabis with your patients</p>
              <p className="m-0">- Be aware of relevant considerations when prescribing</p>
            </div>
            <hr className="my-5 bg-white" />
            <div className="text-center">
              <h2>Learn how 100 Australian doctors prescribe Medical Cannabis</h2>
              <p className="mb-1">Presented By Mariam Sener (Medical Liaison, B.Pharm.Hons)</p>
              <video
                controls
                className="mt-4"
                style={{ maxHeight: '870px', maxWidth: '880px' }}
                src="https://circuit.montu.com.au/assets/videos/20200723+Montu+-+CPD+Insights+Recording_vF.mp4"
              ></video>
              <p className="m-0 mt-4">
                We have put together insights into the typical characteristics of Medical Cannabis prescribing in
                Australia from a randomly selected sample of 100 doctors within our network. Find out what doctors
                prescribe for, why they prescribe Medical Cannabis and what their experience has been so far. Learn
                about the patient profiles, their treatment regimes and other practical insights.
              </p>
              <br />
              <p>
                <b>Benefits from this session:</b>
              </p>
              <p className="m-0">- Receive insights into the Australian medical cannabis prescription landscape</p>
              <p className="m-0">- Learn how leading prescribers select medical cannabis</p>
              <p className="m-0">- Gain understanding of the application process and typical treatment regimes</p>
            </div>
            <hr className="my-5 bg-white" />
            <div className="text-center">
              <h2>Learn how your Chronic Pain patients can benefit from Medical Cannabis</h2>
              <p className="mb-1">Presented By Dr Hilda Jessop (MBBS, FRACGP, FRCGP, PGCMedEd)</p>
              <video
                controls
                className="mt-4"
                style={{ maxHeight: '870px', maxWidth: '880px' }}
                src="https://circuit.montu.com.au/assets/videos/20200604+Montu+-+CPD+Hilda+Recording_vF.mp4"
              ></video>
              <p className="m-0 mt-4">
                Chronic Pain is one of the most prevalent conditions, affecting 1 in every 5 Australians. Medical
                Cannabis can complement existing treatment regimes, where conventional medication is not providing
                sufficient relief. We will provide practical advice on how to initiate your patients and have invited Dr
                Hilda Jessop to share insights and the experiences of her Chronic Pain patients.
              </p>
              <br />
              <p>
                <b>Benefits from this session:</b>
              </p>
              <p className="m-0">- Realize the relevance of Medical Cannabis for chronic pain</p>
              <p className="m-0">- Learn about the opioid sparing effect</p>
              <p className="m-0">- Know how to initiate Medical Cannabis with your patients</p>
              <p className="m-0">- Be aware of relevant considerations when prescribing</p>
            </div>
            <hr className="my-5 bg-white" />
            <div className="text-center">
              <h2>Learn how to evaluate and prescribe Medical Cannabis to your patients</h2>
              <p className="mb-1">Presented By Dr Ram Sakkera (MBBS, MD, FRACGP)</p>
              <video
                controls
                className="mt-4"
                style={{ maxHeight: '870px', maxWidth: '880px' }}
                src="https://circuit.montu.com.au/assets/videos/20200430+Montu+-+CDP+Dr+Ram_Recording.mp4"
              ></video>
              <p className="m-0 mt-4">
                Starting into Medical Cannabis can seem complicated and confusing. We will cover the basics to get you
                started and have invited Dr Ram to share his experience in prescribing Medical Cannabis by walking us
                through patient case studies from his clinic.
              </p>
              <br />
              <p>
                <b>Benefits from this session:</b>
              </p>
              <p className="m-0">- Understand the relevance of Medical Cannabis for your clinic and patients</p>
              <p className="m-0">- Have an overview of the applicable treatment areas</p>
              <p className="m-0">- Be aware of relevant considerations when prescribing</p>
              <p className="m-0">- Know how to initiate and titrate Medical Cannabis with your patient</p>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default CPDSessions;
