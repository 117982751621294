import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Button, styled } from '@mui/material';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

const PartnerHeaderContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(5, 32),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '60px',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
}));

function PartnerHeader() {
  const history = useHistory();
  const { Logo: PartnerLogo } = usePortalPartnerBrand();

  return (
    <PartnerHeaderContainer>
      <PartnerLogo />
      {history.location.pathname !== '/patient/orders' ? (
        <Button variant="contained" color="secondary" component={RouterLink} to="/patient/orders">
          Order history
        </Button>
      ) : null}
    </PartnerHeaderContainer>
  );
}

export default PartnerHeader;
