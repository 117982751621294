import { Link, styled } from '@mui/material';

const Paragraph = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  lineHeight: theme.spacing(6),
  '& p': {
    margin: theme.spacing(3, 0),
  },
  '& ul, & ol': {
    margin: theme.spacing(6, 0),
    paddingLeft: theme.spacing(9),
  },
  '& li': {
    marginBottom: theme.spacing(1),
  },
}));

const FAQConsultationData = [
  {
    id: 1,
    question: 'What if my doctor has no availability?',
    content: (
      <Paragraph>
        <p>
          If availability with your current doctor is limited or fully booked for an extended period of time, you will
          be given the option to book with a new doctor with more availability.
        </p>
        <p>
          If your doctor has availability but you can’t find a time that suits your schedule, request an appointment
          with a new doctor through our{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-How-do-I-contact-Alternaleaf"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            customer service team
          </Link>
          . We will be in contact within 3 business days to organise an appointment for you.
        </p>
        <p>
          <strong>Please note:</strong> If you are assigned a new doctor and a new prescription is issued, any existing
          prescriptions will be removed from the system and will no longer be available for dispensing. If you wish to
          continue with any previously prescribed products, you will need to discuss this with your doctor during your
          consultation.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 2,
    question: 'How often should I see my doctor?',
    content: (
      <Paragraph>
        <p>
          We recommend seeing your Alternaleaf doctor every 6-8 weeks. This allows the doctor to track your progress and
          make sure your treatment plan is working as intended. It’s also an opportunity for you to ask questions, or
          discuss potential changes to your prescription.
        </p>
        <p>
          You’ll receive an email as a reminder to book in your follow-up consultation. It’s completely up to you if you
          choose to do this. Prescriptions usually remain valid for 6 months. If you have an active prescription with
          remaining repeats, you can continue to place orders as usual.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 3,
    question: 'What if I’m a returning patient?',
    content: (
      <Paragraph>
        <p>
          If you haven’t seen your Alternaleaf doctor in over 8 months, you’ll need to{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-How-do-I-contact-Alternaleaf"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            contact us
          </Link>{' '}
          before booking in a follow-up consultation. Our clinical team will contact you to complete a re-engagement
          assessment and make sure your details are up to date. They will then organise an appointment for you.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 4,
    question: 'How will my consultation be completed?',
    content: (
      <Paragraph>
        <p>
          Consultations are completed online via a video call through “Coviu”. You can join the video call by selecting
          “Start consultation” from above. Or, through the meeting link sent to your email address.
        </p>
        <p>
          If a video call isn’t suitable for you, you're welcome to complete your consultation over the phone instead.
          If you’re not present in the video call, the doctor will call the phone number saved on your profile. They
          will usually try twice, so please wait for another phone call if you've missed the first one.
        </p>
        <p>
          Make sure the contact details on your profile are up to date. You can do this by selecting "Profile" from the
          menu. It’s also worth checking that you don’t have private or unknown numbers blocked in your phone settings.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 5,
    question: 'Can I reschedule or cancel my consultation?',
    content: (
      <Paragraph>
        <p>
          Yes, you can reschedule or cancel your consultation up until the scheduled appointment time. To do so, select
          “Manage, reschedule or cancel your booking” from above and choose the relevant option.
        </p>
        <p>
          In order to provide the most up-to-date availability for all patients, we kindly ask that you give as much
          notice as possible. There are currently no cancellation fees.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 6,
    question: 'Do I book in if my medication is out of stock?',
    content: (
      <Paragraph>
        <p>
          If your medication is out of stock, there is no need to book another appointment. We will provide a temporary
          replacement prescription for you free of charge.
        </p>
        <p>To request a replacement prescription, please follow the steps below.</p>
        <ol>
          <li>Navigate to the menu and select “Create order”.</li>
          <li>There will be a message showing the product is out of stock. Select “Request Replacement”.</li>
          <li>Choose the option “Request alternative product”.</li>
          <li>Then select “Send request” to confirm.</li>
          <li>You will see a confirmation message stating that your request is processing.</li>
        </ol>
        <p>
          Replacement prescriptions need to be authorised by your doctor. Please allow up to 5 business days. You will
          be notified via email once your replacement prescription is uploaded.
        </p>
      </Paragraph>
    ),
  },
  {
    id: 7,
    question: 'When do I pay for my consultation?',
    content: (
      <Paragraph>
        <p>
          You’ll be charged after you attend your consultation. If you have a card on file, we’ll deduct the amount from
          your bank account. Otherwise, you’ll receive an invoice via email with the option to “Pay now”.
        </p>
        <p>
          You won’t be charged for consultations that didn’t go ahead. This includes if the doctor takes unexpected
          leave, or you accidentally miss your appointment. If you have a concession card or promo code and were
          incorrectly charged for your consultation, please{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-How-do-I-contact-Alternaleaf"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            contact us
          </Link>{' '}
          .
        </p>
      </Paragraph>
    ),
  },
  {
    id: 8,
    question: 'Do you offer concession benefits?',
    content: (
      <Paragraph>
        <p>
          Yes, we offer concession benefits for all Commonwealth concession, health and Department of Veteran Affairs
          card holders. You can find a list of approved cards on the{' '}
          <Link
            href="https://www.servicesaustralia.gov.au/concession-and-health-care-cards?context=60091"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            Services Australia website
          </Link>
          .
        </p>
        <p>Our concession program offers:</p>
        <ul>
          <li>10% discount on consultation costs</li>
          <li>10% discount on prescribed medication and devices through Montu</li>
          <li>Access to our concession product range</li>
        </ul>
        <p>
          To join our concession program,{' '}
          <Link
            href="https://support.alternaleaf.com.au/hc/en-au/articles/34017993658905-How-do-I-contact-Alternaleaf"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            contact us
          </Link>{' '}
          and provide a clear photo of your valid concession card. We’ll upload it to your profile within 1-3 business
          days.
        </p>
        <p>
          Discounts apply to future consultations and orders, up until the expiry date. To access our concession
          products, you’ll need to speak to your doctor during your next appointment.
        </p>
      </Paragraph>
    ),
  },
];

export default FAQConsultationData;
