import { Box, Skeleton, styled } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderRadius: theme.shape.borderRadius,
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export function PharmacyOrderDetailsLoading() {
  return (
    <Wrapper>
      <Container>
        <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
        <Skeleton variant="rectangular" width={2} height={24} sx={{ marginBottom: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
        <Skeleton variant="rectangular" width={2} height={24} sx={{ marginBottom: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ marginBottom: 2 }} />
      </Container>
    </Wrapper>
  );
}

export default PharmacyOrderDetailsLoading;
