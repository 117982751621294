import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import axios, { isAxiosError } from 'axios';
import moment from 'moment';

import {
  FF_ENABLE_INACTIVE_PHARMACY_FILTER,
  FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY,
  FF_ENABLE_SHOPIFY_CHECKOUT,
  FF_ORDER_FORM_DISABLE_EDIT_IF_PAID,
} from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Logger } from '@/utils/logger';

import Button from '@/components/common/button';
import CircleLoader from '@/components/common/circleLoader';
import FormInput from '@/components/common/FormInput';
import OrderDiscountModal from '@/components/order/orderDiscountModal';
import ProductRows from '@/components/order/productRows';
import Switch from '@/components/toggle/switch';
import settings from '@/constants/constants';
import { AuthService } from '@/services/authentication.service';
import { getOrderCreationReasons } from '@/services/data.service';
import { getProducts } from '@/services/product.service';
import USER_ROLES from '@/constants/userRoles';
import { InventoryService } from '@/services/inventory.service';

const API_URL = `${settings.url}/order`;
const ORDER_NOTES_CHAR_LIMIT = 999;

const logger = new Logger('OrderForm');

// The `row` arg is a custom object with no typing
function transformRowToProductSelectOption(row) {
  return {
    label: row.data.product_name,
    value: row.data.product_id,
    // Concession products are not eligible for any further discounts
    isDisabled: row.data.product_is_concession,
  };
}

function OrderForm(props) {
  const { user } = AuthService.getUser();

  const { flags, loading: ffLoading } = useFeatureFlags();

  const [state, setState] = useState({
    patient_code: '',
    order_code: '',
    order_id: props.match.params.id ? props.match.params.id : null,
    refill: !!props.match.params.refill,
    patient_id: props.match.params.patientId ? props.match.params.patientId : null,
    gp_id: null,
    patient: null,
    patient_note: '',
    pharmacy_name: null,
    refillPharmacy: null,
    prescription_url: '',
    payment_status: '',
    payment_initiated_path: null,
    condition: '',
    treatmentPlan: '',
    pms_order_code: null,
    pms_client: null,
    order_date: null,
    order_history_cancelled_date: null,
    pharmacy: null,
    newPharmacyName: '',
    newPharmacyAddress: '',
    pharmacies: [{ value: 'Choose Pharmacy', label: 'Choose Pharmacy' }],
    GP: user.role_id === USER_ROLES.doctor ? { label: user.first_name, value: user.id } : null,
    baseOrder: null,
    order_status_id: '',
    orderDiscounts: [],
    patientGPs: [],
    priority: false,
    hasPharmacist: false,
    created_date: '',
    created_location: '',
    consignment_id: '',
    tracking_id: '',
    first_dispensed_location: '',
    last_dispensed_location: '',
    order_notes: '',
    reason: '',
    shipped_date: null,
  });

  const [showPatientPharmacy, setShowPatientPharmacy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDiscount, setShowDiscount] = useState(false);
  const [productList, setProductList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [showViewMode, setShowViewMode] = useState(true);
  const [discountAvaialbleProducts, setDiscountAvailableProducts] = useState([]);
  const [newOrderNotes, setNewOrderNotes] = useState('');

  const [selectedProducts, setSelectedProducts] = useState([
    {
      id: 1,
      data: {
        product_id: null,
        quantity: null,
        repeats: null,
        interval: null,
        base_quantity: 12,
        dispensed_date: null,
        intervalEndDate: null,
        shipped_date: null,
        doctor_name: null,
        prescription_code: null,
      },
    },
  ]);
  const [inventoryStock, setInventoryStock] = useState([]);
  const [isHomePharmacy, setHomePharmacy] = React.useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const [sendInvoiceStatus, setSendInvoiceStatus] = useState('idle');
  const validator = new SimpleReactValidator();
  const errorDefaults = {
    GP: validator.message('Patient GP', state.GP, 'required'),
    ...(flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY] &&
    (state.payment_initiated_path === 'ADMIN_INITIATED' || state.patient_id)
      ? { reason: validator.message('reason', state.reason, 'required') }
      : {}),
    ...selectedProducts.forEach((row) => {
      validator.message(`product${row.id}`, row.data.product_id, 'required');
      validator.message(`quantity${row.id}`, row.data.quantity, 'required');
    }),
  };
  const [errors, setErrors] = useState(errorDefaults);

  const fetchData = React.useCallback(async () => {
    document.title = 'Create Prescription - Montu Group | Making Medical Cannabis Accessible';

    logger.info('fetchData flags: ', flags);

    if (flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY]) {
      getOrderCreationReasons().then((response) => {
        setReasonList(response.data);
      });
    }
    if (state.refill) setShowViewMode(false);
    if (state.order_id) {
      // for refill or editing an existing order
      document.title = 'Edit Prescription - Montu Group | Making Medical Cannabis Accessible';
      const response = await axios.get(`${API_URL}/${state.order_id}`);
      const proceedLoad = checkResponse(response);
      if (!proceedLoad) {
        return;
      }
      const orderDetails = response.data;
      setPrescriptions(orderDetails.patientActivePrescriptions);
      const pharmacyList = orderDetails.pharmacies
        .filter((pharmacy) => !flags[FF_ENABLE_INACTIVE_PHARMACY_FILTER] || pharmacy.active !== false)
        .map((pharmacy) => ({
          label: `${pharmacy.name} | ${pharmacy.address.split('$$$$')[0]} | ${pharmacy.city} | ${pharmacy.zip_code}`,
          value: pharmacy.id,
        }));
      const pharm =
        state.refill && orderDetails.refill_pharmacy_id ? orderDetails.RefillPharmacy : orderDetails.Pharmacy;
      const patient_preferred_pharmacy = orderDetails.Patient.Pharmacy;
      const orderDoctor = orderDetails.patientGPs.find((x) => x.GeneralPractitioner.id === orderDetails.gp_id);
      if (!orderDetails.pharmacy_id && orderDetails.Patient.patient_pharmacy_name) {
        setShowPatientPharmacy(true);
      }
      setState({
        ...state,
        order_notes: orderDetails.order_notes,
        reason: orderDetails.admin_order_creation_reason_id,
        first_dispensed_location: orderDetails.dispensed_pharmacy_name,
        last_dispensed_location: pharm ? pharm.name : '',
        created_date: moment(orderDetails.created_date).format('DD-MMM-YYYY'),
        created_location: '',
        consignment_id: orderDetails.consignmentID,
        tracking_id: orderDetails.trackingID,
        orderDiscounts: orderDetails.OrderDiscounts,
        order_code: orderDetails.order_code,
        patient: orderDetails.patient_id,
        patient_note: orderDetails.Patient.patient_note,
        order_status_id: orderDetails.order_status_id,
        condition: orderDetails.medical_condition,
        payment_status: orderDetails.payment_status,
        shipped_date: orderDetails.shipped_date,
        treatmentPlan: orderDetails.prescription_notes,
        pms_order_code: orderDetails.pms_order_code,
        pms_client: orderDetails.Patient.client_id,
        baseOrder: props.match.params.id,
        order_date: orderDetails.order_date,
        gp_id: orderDetails.gp_id,
        patient_code: orderDetails.Patient.patient_code,
        prescription_url: orderDetails.prescription_url,
        patient_name: `${orderDetails.Patient.PatientUser.first_name} ${orderDetails.Patient.PatientUser.last_name}`,
        hasPharmacist: orderDetails.Pharmacy ? orderDetails.Pharmacy.Pharmacists.length > 0 : false,
        priority: orderDetails.priority,
        patientGPs: orderDetails.patientGPs.map((x) => ({
          label: `${x.GeneralPractitioner.Doctor.first_name} ${x.GeneralPractitioner.Doctor.last_name}`,
          value: x.GeneralPractitioner.Doctor.id,
        })),
        ...(pharm && {
          pharmacy: pharm.id,
          pharmacy_name: `${pharm.name} | ${pharm.address.split('$$$$')[0]} | ${pharm.city} | ${pharm.zip_code}`,
        }),
        ...(state.refill && {
          pharmacy: patient_preferred_pharmacy ? patient_preferred_pharmacy.id : pharm.id,
          pharmacy_name: patient_preferred_pharmacy
            ? `${patient_preferred_pharmacy.name} | ${patient_preferred_pharmacy.address.split('$$$$')[0]} | ${
                patient_preferred_pharmacy.city
              } | ${patient_preferred_pharmacy.zip_code}`
            : `${pharm.name} | ${pharm.address.split('$$$$')[0]} | ${pharm.city} | ${pharm.zip_code}`,
        }),
        ...(orderDetails.refill_pharmacy_id && {
          refillPharmacy: {
            value: orderDetails.RefillPharmacy.id,
            label: `${orderDetails.RefillPharmacy.name} | ${orderDetails.RefillPharmacy.address.split('$$$$')[0]} | ${
              orderDetails.RefillPharmacy.city
            } | ${orderDetails.RefillPharmacy.zip_code}`,
          },
        }),
        GP: {
          label: `${orderDoctor?.GeneralPractitioner.Doctor.first_name} ${orderDoctor?.GeneralPractitioner.Doctor.last_name}`,
          value: orderDoctor?.GeneralPractitioner.Doctor.id,
        },
        ...(!orderDetails.pharmacy_id && { newPharmacyName: orderDetails.Patient.patient_pharmacy_name }),
        ...(!orderDetails.pharmacy_id && { newPharmacyAddress: orderDetails.Patient.patient_pharmacy_address }),
        pharmacies: pharmacyList,
        orderManagementShopify: orderDetails.orderManagementShopify,
        payment_initiated_path: orderDetails.payment_initiated_path,
        order_history_cancelled_date: orderDetails.order_history_cancelled_date,
      });
      setProductList(orderDetails.products);
      const rows = [];
      for (const prdt of orderDetails.OrderProducts) {
        const product = orderDetails.products.find((x) => x.id === prdt.product_id);
        if (!product || product.remaining_units === 0 || product.reasoning_toggle) {
          toast.warn(
            `Skipped ${prdt.Product.name} as it is inactive or not available for the ${
              orderDetails.Patient.client_id ? 'Patient' : 'Doctor'
            }`,
          );
          continue;
        }
        if (state.refill && (!product || product.remaining_units === 0 || product.reasoning_toggle)) {
          continue;
        }
        let quantity = prdt.quantity || product.quantity || product.quantity_base_order;
        const dispensedDate = orderDetails.dispensedDate;

        const availability = {
          repeats: product.remaining_units / quantity,
          intervalEndDate: dispensedDate ? getIntervalEndDate(dispensedDate, product.interval) : '-',
          base_quantity:
            orderDetails.Patient.client_id && product.formulation_id !== 5
              ? Math.max(isNaN(product.quantity_base_order) ? 0 : product.quantity_base_order, quantity)
              : 12,
          dispensed_date: moment(dispensedDate).isValid() ? moment(dispensedDate).format('DD-MMM-YYYY') : '-',
        };

        if (product.formulation_id !== 5 && product.remaining_units) {
          quantity = quantity
            ? Math.min(quantity, product.remaining_units)
            : Math.min(product.quantity_base_order, product.remaining_units);
        }

        rows.push({
          id: rows.length + 1,
          data: {
            product_id: prdt.product_id,
            product_name: product.name,
            product_is_concession: product.is_concession,
            remainingUnits: product.remaining_units,

            quantity,

            ...availability,

            ...(user.role_id !== USER_ROLES.doctor &&
              orderDetails.Patient.client_id && { interval: prdt?.PrescriptionProduct?.interval }),
            shipped_date: orderDetails.shipped_date ? moment(orderDetails.shipped_date).format('DD-MMM-YYYY') : null,
            ...(user.role_id !== USER_ROLES.doctor && orderDetails.Patient.client_id && prdt.PrescriptionProduct
              ? {
                  doctor_name: `${prdt?.PrescriptionProduct?.Prescription?.GeneralPractitioner?.Doctor?.first_name} ${prdt?.PrescriptionProduct?.Prescription?.GeneralPractitioner?.Doctor?.last_name}`,
                }
              : '-'),
            ...(user.role_id !== USER_ROLES.doctor && orderDetails.Patient.client_id && prdt.PrescriptionProduct
              ? { prescription_code: prdt?.PrescriptionProduct?.Prescription?.prescription_code }
              : '-'),
          },
        });
      }
      const pharmacyId = state.refill ? patient_preferred_pharmacy?.id : pharm?.id;
      const inventory = await fetchInventoryStatus(rows, pharmacyId);
      setHomePharmacy(inventory.isHomePharmacy);
      setInventoryStock(inventory.products);
      setSelectedProducts([...rows]);
      const productsAvailableForDiscount = rows.map(transformRowToProductSelectOption);
      setDiscountAvailableProducts(productsAvailableForDiscount);
      logger.debug('rows: ', rows);
    }
    if (state.patient_id) {
      // for a new patient Order
      const response = await axios.get(`${API_URL}/patient-order/${state.patient_id}`);
      const proceedLoad = checkResponse(response);
      if (!proceedLoad) {
        return;
      }
      const patientDetails = response.data;
      const pharmacyList = patientDetails.pharmacies
        .filter((pharmacy) => !flags[FF_ENABLE_INACTIVE_PHARMACY_FILTER] || pharmacy.active !== false)
        .map((pharmacy) => ({
          label: `${pharmacy.name} | ${pharmacy.address.split('$$$$')[0]} | ${pharmacy.city} | ${pharmacy.zip_code}`,
          value: pharmacy.id,
        }));
      const GP = patientDetails.patientGPs[0];
      const pharm =
        patientDetails.patient.pharmacy_id &&
        patientDetails.pharmacies.find((x) => x.id === patientDetails.patient.pharmacy_id);
      setState({
        ...state,
        orderDiscounts: patientDetails.patient.PatientDiscounts,
        patient: patientDetails.patient.id,
        patient_note: patientDetails.patient.patient_note,
        pms_client: patientDetails.patient.client_code,
        patient_code: patientDetails.patient.patient_code,
        prescription_url: patientDetails.prescription_url,
        patient_name: `${patientDetails.patient.PatientUser.first_name} ${patientDetails.patient.PatientUser.last_name}`,
        patientGPs: patientDetails.patientGPs.map((x) => ({
          label: `${x.GeneralPractitioner.Doctor.first_name} ${x.GeneralPractitioner.Doctor.last_name}`,
          value: x.id,
        })),
        ...(pharm && {
          pharmacy: pharm.id,
          pharmacy_name: `${pharm.name} | ${pharm.address.split('$$$$')[0]} | ${pharm.city} | ${pharm.zip_code}`,
        }),
        GP: {
          label: `${GP.GeneralPractitioner.Doctor.first_name} ${GP.GeneralPractitioner.Doctor.last_name}`,
          value: GP.GeneralPractitioner.Doctor.id,
        },
        ...(patientDetails.patient.patient_pharmacy_name && {
          newPharmacyName: patientDetails.patient.patient_pharmacy_name,
        }),
        ...(patientDetails.patient.patient_pharmacy_address && {
          newPharmacyAddress: patientDetails.patient.patient_pharmacy_address,
        }),
        pharmacies: pharmacyList,
      });
      setProductList(patientDetails.products);
    }

    setLoading(false);
  });

  function getIntervalEndDate(lastDispensedDate, interval) {
    if (lastDispensedDate === null) return '-';
    const intervalEndDate = moment(lastDispensedDate).add(interval, 'days');

    if (moment().isAfter(intervalEndDate)) {
      return '-';
    }
    return moment(intervalEndDate).format('DD-MMM-YYYY');
  }

  const canEditProductsInOrder = () => {
    if (!flags[FF_ORDER_FORM_DISABLE_EDIT_IF_PAID]) return true;
    return state.payment_status !== 'PAID' && state.payment_status != 'VOIDED';
  };

  const getOrderPrescriptionDetails = (prescriptions, product_id, quantity) => {
    const initial = {
      patientPrescriptions: [],
      prescription: {},
      doctor_name: null,
      prescription_code: null,
      interval: null,
    };

    const prescriptionExactQuantity = prescriptions.filter(
      (pr) => pr.product_id === product_id && pr.quantity >= quantity && pr.remaining_units >= quantity,
    );

    if (prescriptionExactQuantity.length === 0) {
      initial.patientPrescriptions = prescriptions.filter(
        (pr) => pr.product_id === product_id && pr.quantity <= quantity,
      );
    } else {
      initial.patientPrescriptions = prescriptionExactQuantity;
    }
    if (initial.patientPrescriptions.length) {
      initial.prescription = initial.patientPrescriptions.reduce((prev, curr) =>
        prev.interval < curr.interval ? prev : curr,
      );
    }

    if (initial.patientPrescriptions.length && initial.prescription) {
      initial.doctor_name = `${initial.prescription.Prescription.GeneralPractitioner.Doctor.first_name} ${initial.prescription.Prescription.GeneralPractitioner.Doctor.last_name}`;
      initial.prescription_code = initial.prescription.Prescription.prescription_code;
      initial.interval = initial.prescription.interval;
    }

    return { initial };
  };

  const fetchInventoryStatus = async (orderProducts, pharmacyId = state.pharmacy) => {
    if (!state.pharmacy) {
      // orders are no longer assigned a pharmacy until after payment.
      // I'm not sure if checking inventory levels on this page makes sense
      // any more because by the time an order is assigned a pharmacy it
      // is paid for and moving through the dispensing flow.
      return [];
    }

    const products = orderProducts.map((prd) => ({
      id: prd.data.product_id,
      quantity: prd.data.quantity,
    }));

    const response = await InventoryService.getInventoryStock(products, state.order_id, pharmacyId, state.refill);
    return response?.data || [];
  };

  React.useEffect(() => {
    if (ffLoading) return;

    fetchData();
  }, [ffLoading]);

  const checkResponse = (response) => {
    if (response.status === 404) {
      props.history.push('/404');
      return false;
    }
    if (!response.data) {
      toast.error('Failed to load Page. Please try again later!');
      logger.error('Failed to load Page. Please try again later! !response.data');
      return false;
    }
    return true;
  };

  const handlePatientGP = (selected) => {
    setState({ ...state, GP: selected, gp_id: selected.value });
    if (state.pms_client) {
      return;
    } // PMS patients will have same set of products with all assigned Doctors.
    getProducts(selected.value).then((products) => setProductList(products.data.map((x) => x.Product)));
  };

  const handleReason = ({ value }) => {
    setState({
      ...state,
      reason: value,
    });
  };

  const addRow = () => {
    const rows = selectedProducts;
    const maxId = Math.max.apply(
      Math,
      rows.map((row) => row.id),
    );
    rows.push({
      id: maxId + 1,
      data: {
        product_id: null,
        quantity: null,
        repeats: null,
        base_quantity: 12,
        dispensed_date: null,
        intervalEndDate: null,
        doctor_name: null,
        prescription_code: null,
      },
    });
    setSelectedProducts([...rows]);
  };

  const deleteRow = (id) => {
    const rows = selectedProducts.filter((itm) => itm.id !== id);
    const productsAvailableForDiscount = rows.map(transformRowToProductSelectOption);
    const data = [];
    let c = 1;
    for (let i = 0; i < rows.length; i++) {
      data.push({
        id: c,
        data: rows[i].data,
      });
      c += 1;
    }
    setSelectedProducts([...data]);
    setDiscountAvailableProducts(productsAvailableForDiscount);
  };

  const handleQuantity = async (e, id) => {
    const rows = selectedProducts;
    const row = rows.filter((r) => r.id === id)[0];
    const product = productList.find((x) => x.id === row.data.product_id);
    if (product) {
      let newRowData;

      const { doctor_name, prescription_code } = getOrderPrescriptionDetails(
        prescriptions,
        product.id,
        e.value,
      ).initial;

      newRowData = {
        quantity: e.value,
        repeats: product.remaining_units / e.value,
        interval: product.interval,
        doctor_name,
        prescription_code,
        base_quantity: row.data.base_quantity,
      };

      let quantity = e.value;
      if (product.formulation_id !== 5) {
        quantity = Math.min(quantity, newRowData.base_quantity);
      }

      row.data = {
        ...row.data,
        ...newRowData,
        quantity,
      };
    } else {
      row.data = {
        ...row.data,
        quantity: e.value,
      };
    }
    const inventory = await fetchInventoryStatus(rows);
    setInventoryStock(inventory.products);
    setSelectedProducts([...rows]);
  };

  const handleInput = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleNewNoteChange = (e) => {
    if (ORDER_NOTES_CHAR_LIMIT - (state.order_notes || '').length - e.target.value < 1) return;
    setNewOrderNotes(e.target.value);
  };

  const handleProduct = async (e, id) => {
    const productId = e.value;
    logger.debug('selected product id: ', productId);
    const product = productList.find((x) => x.id === productId);

    // TODO: Please treat React state as immutable, `selectedProducts` is a state reference
    // https://react.dev/learn/updating-objects-in-state#treat-state-as-read-only
    const rows = selectedProducts;
    const selectedProduct = rows.find((row) => row.id === id);

    let availability;
    let quantity = product.quantity || product.quantity_base_order;

    const prescriptionDetails = getOrderPrescriptionDetails(prescriptions, e.value, quantity).initial;
    availability = {
      repeats: product.remaining_units / e.value,
      base_quantity: state.pms_client ? Math.max(product.quantity_base_order, quantity) : 12,
      dispensed_date: product.dispensed_date,
      interval: prescriptionDetails.interval,
      doctor_name: prescriptionDetails.doctor_name,
      prescription_code: prescriptionDetails.prescription_code,
    };

    if (product.formulation_id !== 5) {
      quantity = quantity
        ? Math.min(quantity, availability.base_quantity)
        : Math.min(product.quantity_base_order, availability.base_quantity);
    }
    logger.debug('quantity: ', quantity);

    const { shipped_date } = product;

    if (selectedProduct) {
      selectedProduct.data = {
        ...selectedProduct.data,
        product_id: e.value,
        product_name: e.label,
        quantity,
        ...availability,
        shipped_date,
      };
    } else {
      rows.push({
        id,
        data: {
          product_id: product.id,
          product_name: product.name,
          product_is_concession: product.is_concession,
          quantity,
          ...availability,
          shipped_date,
        },
      });
    }
    const inventory = await fetchInventoryStatus(rows);
    setInventoryStock(inventory.products);

    logger.debug('rows: ', rows);

    setSelectedProducts([...rows]);
    const productsAvailableForDiscount = rows.map(transformRowToProductSelectOption);
    setDiscountAvailableProducts(productsAvailableForDiscount);
  };

  const handlePharmChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      ...(state.order_status_id !== 5 && {
        pharmacy: null,
        pharmacy_name: 'select...',
      }),
    });
  };

  const productListForDropdown = useMemo(() => {
    return productList?.map((product) => {
      const supplier = product?.Suppliers[0];
      return {
        ...product,
        supplier_id: supplier?.id || null,
        supplier_name: supplier?.supplier_name || 'Others',
      };
    });
  }, [productList]);

  const validateSubmission = (forRefill) => {
    let success = true;
    if (!validator.allValid()) {
      validator.showMessages();
      const errorMessages = {
        GP: validator.message('Patient GP', state.GP, 'required'),
        ...(flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY] &&
        (state.payment_initiated_path === 'ADMIN_INITIATED' || state.patient_id)
          ? { reason: validator.message('reason', state.reason, 'required') }
          : {}),
      };
      selectedProducts.forEach((row) => {
        errorMessages[`product${row.id}`] = validator.message(`product${row.id}`, row.data.product_id, 'required');
        errorMessages[`quantity${row.id}`] = validator.message(`quantity${row.id}`, row.data.quantity, 'required');
      });

      setErrors(errorMessages);
      success = false;
    }

    const products = selectedProducts.map((prdt) => ({
      quantity: prdt.data.quantity,
      product_id: prdt.data.product_id,
      ...(state.pms_client && {
        repeats: prdt.data.repeats,
        interval: prdt.data.interval,
        remainingUnits: prdt.data.remainingUnits,
      }),
    }));
    // checking for products
    if (forRefill && products.length === 0) {
      toast.error('Prescription should atleast have one product to be submitted');
      logger.error('Prescription should atleast have one product to be submitted forRefill && products.length === 0');
      success = false;
    }
    return { products, success };
  };

  const handleSuccessMessage = (response, submit = false) => {
    if (response.data.level === 'success' || !response.data.level) {
      toast.success(response.data.message);
    }
    if (response.data.level === 'error') {
      toast.error(response.data.message);
      logger.error('handleSuccessMessage', response?.data?.message);
    }
    if (response.data.level === 'warning') {
      toast.warn(response.data.message);
    }
    setLoading({ loading: false });

    if (AuthService.isAdmin() && state.patient_id) {
      props.history.push('/orders');
    }
    if (AuthService.isDoc()) {
      props.history.push('/orders');
    }
    if (submit || state.refill) {
      props.history.push('/orders');
      return;
    }
    fetchData();
    setShowViewMode(true);
  };

  const updateInvoice = async (e) => {
    e.preventDefault();
    const { products, success } = validateSubmission(true);
    if (!success) {
      return;
    }

    setLoading(true);
    await axios
      .put(`${API_URL}/v1/update/${state.order_id}`, {
        condition: state.condition,
        prescription: state.treatmentPlan,
        pharmacy: state.pharmacy,
        refill_pharmacy_id: state.refillPharmacy ? state.refillPharmacy.value : null,
        base_order_id: state.baseOrder,
        tz: moment().format('Z'),
        gp: state.gp_id,
        order_date: moment(new Date()).valueOf(),
        newPharmacyName: !state.pharmacy ? state.newPharmacyName : '',
        newPharmacyAddress: !state.pharmacy ? state.newPharmacyAddress : '',
        order_notes: getOrderNotesToSave(),
        admin_order_creation_reason_id: state.reason ?? null,
        products,
      })
      .then((response) => {
        if (response) {
          handleSuccessMessage(response, true);
          setNewOrderNotes('');
        } else {
          toast.error('Failed to create an order. Please try again later.');
          setLoading({ loading: false });
          logger.error('Failed to create an order with response'); // TODO throw here? or just guard with if(!response) throw...
        }
      })
      .catch((err) => {
        logger.error('Failed to create an order.', err);
        toast.error('Failed to create an order. Please try again later.');
        setLoading({ loading: false });
      });
  };

  const handleClear = async () => {
    setShowViewMode(true);
  };

  const resetPage = async (e) => {
    e.preventDefault();
    await fetchData();
    await handleClear();
  };

  const getOrderNotesToSave = () => {
    if (!state.order_notes && !newOrderNotes) return '';
    return `${state.order_notes || ''}${state.order_notes && newOrderNotes ? '|' : ''}${newOrderNotes}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isRefill = state.refill || state.patient_id;
    const { products, success } = validateSubmission(isRefill);
    if (!success) {
      return;
    }
    setLoading(true);
    const orderData = {
      patient: state.patient,
      condition: state.condition,
      prescription: state.treatmentPlan,
      pms_order_code: state.pms_order_code,
      payment_status: state.payment_status,
      order_status: state.order_status_id,
      pms_client: state.pms_client,
      refill_pharmacy: state.refillPharmacy ? state.refillPharmacy.value : null,
      gp: state.GP.value,
      order_date: moment(new Date()).valueOf(),
      tz: moment().format('Z'),
      products,
      request_type: state.refill ? 'refill' : 'new',
      base_order_id: state.baseOrder,
      priority: state.priority,
      order_notes: getOrderNotesToSave(),
      ...(flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY] ? { admin_order_creation_reason_id: state.reason ?? '' } : {}),
    };
    // POST/PUT request based on refil or update
    const order = isRefill
      ? await axios.post(`${settings.url}/order/v1`, orderData)
      : await axios.put(`${API_URL}/v1/edit/${state.order_id}`, orderData);

    if (order) {
      handleSuccessMessage(order);
      setNewOrderNotes('');
      return;
    }
    toast.error('Failed to create an order');
    setLoading(false);
  };

  const handleCollectPayment = async () => {
    if (state.orderManagementShopify?.shopify_draft_order_invoice_url) {
      window.open(state.orderManagementShopify.shopify_draft_order_invoice_url);
    } else {
      toast.error('Invoice URL could not be found');
    }
  };
  const handleSendPatientInvoice = async () => {
    setSendInvoiceStatus('loading');
    try {
      await axios.post(`${settings.url}/order/${state.order_id}/send-invoice`);
      setSendInvoiceStatus('success');
      toast.success('Invoice sent to patient');
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        toast.error(e.response.message || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
      setSendInvoiceStatus('idle');
    }
  };

  const getFormName = () => {
    if (state.refill) {
      return 'Refill Order';
    }
    if (state.order_id) {
      return 'Edit Order';
    }
    if (state.patient_id) {
      return 'New Order';
    }
    return 'Order';
  };

  const isUnpaidOrder = state.payment_status !== 'PAID';

  const getPrescriptionUrl = async (prescription) => {
    await axios.get(`${settings.url}/data/prescriptionUrl`, { params: { key: prescription } }).then((res) => {
      if (!res || !res.data) {
        toast.warn('Precription Not found');
        return;
      }
      window.open(res.data);
    });
  };

  return loading || ffLoading ? (
    <CircleLoader />
  ) : (
    <div className={AuthService.isAdmin() && !state.patient_id ? 'container-fluid' : 'container'}>
      {/* Discount Button -> to show only for superAdmins and if the order is not paid */}
      <div className="d-flex">
        <div className="w-100">
          {AuthService.isAdmin() && !state.patient_id ? '' : <h2>{getFormName()} Form</h2>}
          <div className="w-100 d-flex justify-content-between align-items-center">
            <h3>{state.order_code}</h3>
            {AuthService.isAdmin() && !state.patient_id ? (
              <>
                <h6>Consignment ID: {state.consignment_id}</h6>
                <h6>Tracking ID: {state.tracking_id}</h6>
              </>
            ) : (
              ''
            )}
            <div className="d-flex align-items-end">
              {/* if Admin,  pharmacy has pharmacist, its not a refill order and if not dispenced or completed */}
              {AuthService.isAdmin() &&
                state.hasPharmacist &&
                !state.refill &&
                ![15, 17].includes(state.order_status_id) && (
                  <>
                    <label className="mb-2 pb-0 mr-3">Dispense Priority</label>
                    <Switch
                      className="mr-3"
                      isOn={state.priority}
                      disabled={!!showViewMode}
                      handleToggle={() => {
                        setState({
                          ...state,
                          priority: !state.priority,
                        });
                      }}
                    />
                  </>
                )}
              {AuthService.isAdmin() && !state.patient_id && showViewMode ? (
                <div className="d-flex" style={{ gap: '0.5rem' }}>
                  {flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY] &&
                    state.payment_status === 'AUTHORISED' &&
                    state.payment_initiated_path === 'ADMIN_INITIATED' && (
                      <>
                        <Button
                          text="Send Patient Invoice"
                          icon="fa fa-shopify"
                          className="btn btn-outline-primary"
                          disabled={sendInvoiceStatus !== 'idle'}
                          style={{ width: '200px', height: 'fit-content' }}
                          onClick={handleSendPatientInvoice}
                        />
                        {state.orderManagementShopify?.shopify_draft_order_invoice_url && (
                          <Button
                            text="Collect Payment"
                            icon="fa fa-shopify"
                            className="btn btn-outline-primary"
                            disabled={sendInvoiceStatus !== 'idle'}
                            style={{ width: '200px', height: 'fit-content' }}
                            onClick={handleCollectPayment}
                          />
                        )}
                      </>
                    )}
                  {isUnpaidOrder && (
                    <Button
                      text="Edit"
                      className="btn btn-primary"
                      style={{ width: '150px', height: 'fit-content' }}
                      onClick={() => setShowViewMode(false)}
                    />
                  )}
                </div>
              ) : (
                (AuthService.isSuperAdmin() || AuthService.isAdminOnly()) &&
                !state.refill &&
                state.reason !== null &&
                state.payment_status === 'AUTHORISED' && (
                  <Button
                    text="Discount"
                    className="btn  btn-outline-primary"
                    style={{ width: '150px', height: 'fit-content' }}
                    onClick={() => setShowDiscount(true)}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {state.order_id && !state.refill && (
          <ul className="ml-3 p-1 mt-2">
            {state.orderDiscounts.map((ele) => (
              <li key={ele.id}>
                {ele.discount_percentage &&
                  `${ele.discount_percentage}% - ${ele.DiscountType.name} 
                  ${ele.DiscountReason ? `- ${ele.DiscountReason.reason}` : ''}
                  ${ele.discount_note ? `- ${ele.discount_note}` : ''}
                  ${ele.Product ? `- ${ele.Product.name}` : ''}`}
                {ele.discount_amount &&
                  `${ele.discount_amount}$ - ${ele.DiscountType.name} ${ele.Product ? `- ${ele.Product.name}` : ''}`}
              </li>
            ))}
          </ul>
        )}
      </div>
      {state.order_id && !state.refill && (
        <OrderDiscountModal
          show={showDiscount}
          close={() => setShowDiscount(false)}
          patient={state.patient}
          availableProducts={discountAvaialbleProducts}
          orderId={state.order_id}
          history={props.history}
        />
      )}
      <form>
        <fieldset>
          <div>
            {AuthService.isAdmin() && !state.patient_id ? (
              ''
            ) : (
              <div className="d-flex">
                <h6 className="mr-auto mt-3">
                  Patient ID: <Link to={`/onboard/${state.patient}`}>{state.patient_code}</Link>
                </h6>
              </div>
            )}
            <div>
              <h5 className="mx-auto p-2 mt-3">Prescription details</h5>
            </div>
            <div className="row p-2">
              <div className="col-md-12">
                {AuthService.isAdmin() && !state.patient_id ? (
                  <div className="row px-3">
                    <div className="form-group p-1 col-md-2">
                      <label>Patient Id</label>
                      <Link to={`/onboard/${state.patient}`}>
                        <p style={{ height: '38px' }} className="d-flex align-items-center">
                          {state.patient_code}
                        </p>
                      </Link>
                    </div>
                    <div className="form-group p-1 col-md-2">
                      <FormInput
                        type="text"
                        style={{ height: '38px' }}
                        name="pat_name"
                        readOnly
                        onChange={handleInput}
                        label="Patient Name"
                        value={state.patient_name || ''}
                        showViewMode
                      />
                    </div>
                    {!state.pms_client && (
                      <div className="form-group p-1 ml-1 col-md-2" style={{ height: '30px' }}>
                        <label>Patient's Doctor</label>
                        <Select
                          name="PatientGp"
                          value={state.GP}
                          className={`${errors.GP ? 'rounded dropdown-border' : ''}`}
                          onChange={handlePatientGP}
                          isSearchable
                          placeholder="Doctor Name"
                          options={state.patientGPs}
                          isDisabled={!!showViewMode}
                        />
                        {errors.GP}
                      </div>
                    )}
                    <div className="form-group p-1 col-md-2">
                      <FormInput
                        type="text"
                        style={{ height: '38px' }}
                        name="payment_status"
                        readOnly
                        label="Payment Status"
                        value={state.payment_status || ''}
                        showViewMode
                      />
                    </div>
                    {FF_ENABLE_SHOPIFY_CHECKOUT && (
                      <div className="form-group p-1 col-md-2">
                        <FormInput
                          type="text"
                          style={{ height: '38px' }}
                          name="shopify_order_confirmation_number"
                          readOnly
                          label="Shopify Order Confirmation #"
                          value={state.orderManagementShopify?.shopify_order_confirmation_number || ''}
                          showViewMode
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="form-group p-1 col-md-12">
                    <FormInput
                      type="text"
                      name="pat_name"
                      readOnly
                      onChange={handleInput}
                      label="Patient Name"
                      value={state.patient_name || ''}
                    />
                  </div>
                )}
                <div className="mb-4">
                  <ProductRows
                    productList={productListForDropdown}
                    selectedProducts={selectedProducts}
                    handleProduct={handleProduct}
                    handleQuantity={handleQuantity}
                    validator={validator}
                    pms_client={state.pms_client}
                    addRow={addRow}
                    deleteRow={deleteRow}
                    user={user}
                    pharmacyId={state.pharmacy}
                    pharmacyName={state.last_dispensed_location}
                    errors={errors}
                    setErrors={setErrors}
                    errorDefaults={errorDefaults}
                    showView={showViewMode || !canEditProductsInOrder()}
                    isAdmin={AuthService.isAdmin()}
                    orderStatus={state.order_status_id}
                    patientID={state.patient_id}
                    getPrescriptionUrl={getPrescriptionUrl}
                    inventoryStock={inventoryStock}
                    isHomePharmacy={isHomePharmacy}
                  />
                </div>
                <div className="form-row p-1">
                  <div className="form-group p-1 col-md-6">
                    <label className="mb-0 pb-0">Condition (Medication Is Prescribed For)</label>
                    <FormInput
                      type="text"
                      name="condition"
                      value={state.condition || ''}
                      onChange={handleInput}
                      showViewMode={AuthService.isAdmin() && !state.patient_id && showViewMode}
                      readOnly={!!(AuthService.isAdmin() && !state.patient_id && showViewMode)}
                      maxlength="500"
                    />
                  </div>
                  <div className="form-group p-1 col-md-6">
                    <label className="mb-0 pb-0">Dosing/Treatment Plan</label>
                    <FormInput
                      name="treatmentPlan"
                      value={state.treatmentPlan || ''}
                      onChange={handleInput}
                      readOnly={!!(AuthService.isAdmin() && !state.patient_id && showViewMode)}
                      maxLength="500"
                      showViewMode={AuthService.isAdmin() && !state.patient_id && showViewMode}
                    />
                  </div>
                </div>
                {state.patient_note && !state.patient_id && (
                  <div className="form-group p-1">
                    <label>Note</label>
                    <FormInput
                      type="text"
                      style={{ height: '38px' }}
                      name="pat_name"
                      readOnly
                      showViewMode
                      onChange={handleInput}
                      value={state.patient_note || ''}
                    />
                  </div>
                )}
                {flags[FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY] &&
                  (state.payment_initiated_path === 'ADMIN_INITIATED' || state.patient_id) && (
                    <div className="form-group p-1">
                      <label>Reason</label>
                      <Select
                        className={`${errors.reason ? 'rounded dropdown-border' : ''}`}
                        value={
                          state.reason && reasonList
                            ? { label: reasonList.find((r) => r.id === state.reason)?.reason, value: state.reason }
                            : ''
                        }
                        name="reason"
                        onChange={handleReason}
                        options={reasonList.map(({ id, reason }) => ({ label: reason, value: id }))}
                        isLoading={reasonList.length === 0}
                        isDisabled={(!state.patient_id && showViewMode) || reasonList.length === 0}
                      />
                      {errors.reason}
                    </div>
                  )}

                {AuthService.isAdmin() && (
                  <div className="form-group px-1 col-md-12">
                    <div className="form-row">
                      <div className="w-100">
                        <label className="float-left mr-2 label" htmlFor="order_notes">
                          Order notes
                        </label>
                      </div>
                      {state.order_notes && (
                        <div className="col-md-12 px-4">
                          <ul>
                            {state.order_notes
                              .split('|')
                              .filter((note) => !!note)
                              .map((note, i) => (
                                <li key={`${note}${i}`}>{note}</li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {(!showViewMode || !!state.patient_id) && (
                        <>
                          <FormInput
                            type="text"
                            className="col-md-12"
                            placeholder="Add order note"
                            noLabel
                            name="order_notes"
                            rows={2}
                            maxlength={ORDER_NOTES_CHAR_LIMIT - (state.order_notes || '').length}
                            onChange={handleNewNoteChange}
                            value={newOrderNotes}
                            showViewMode={showViewMode && !state.patient_id}
                          />
                          {state.order_notes || newOrderNotes
                            ? (!showViewMode || !state.order_id) && (
                                <div className="w-100 d-flex justify-content-end">
                                  <small>
                                    {ORDER_NOTES_CHAR_LIMIT -
                                      (state.order_notes || '').length -
                                      (newOrderNotes || '').length}
                                  </small>
                                </div>
                              )
                            : null}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {AuthService.isAdmin() && !state.patient_id && showViewMode ? (
              ''
            ) : (
              <div className="text-right">
                <div className="d-inline-block text-right">
                  <Button
                    text={`${!state.refill && state.order_id ? 'Save' : 'Submit'}`}
                    type="submit"
                    loading={loading}
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  />
                </div>
                {AuthService.isAdmin() && !state.patient_id && (
                  <div className="d-inline-block text-right ml-4">
                    <Button
                      text="Cancel"
                      type="button"
                      loading={loading}
                      className="btn btn-primary"
                      onClick={resetPage}
                    />
                  </div>
                )}
                {!state.refill && state.order_id && (
                  <div className="d-inline-block text-right ml-4">
                    <Button
                      text="Submit"
                      type="submit"
                      disabled={!canEditProductsInOrder()}
                      loading={loading}
                      className="btn btn-primary"
                      onClick={updateInvoice}
                    />
                  </div>
                )}
                <p className="invoice-edit">(Patient will receive an invoice on submit)</p>
              </div>
            )}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default OrderForm;
