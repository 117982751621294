import React, { lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import axios, { AxiosError, AxiosResponse } from 'axios';

import PharmacistOrderFCContainer from '@/components/pharmacist/PharmacistOrderFCContainer';
import Research from '@/components/resources/Research';
import AuthenticatedRoute from '@/components/routes/AuthenticatedRoute';
import ComingSoon from '@/components/routes/ComingSoon';
import SupplierTable from '@/components/supplier/supplierTable';
import ProfileDetailsContainer from '@/components/user/ProfileDetailsContainer';
import ProfileResetPasswordContainer from '@/components/user/ResetPasswordContainer';
import settings from '@/constants/constants';
import {
  FF_ENABLE_404_AND_500_ERROR_PAGES,
  FF_ENABLE_CONSULTATION_PAGE,
  FF_ENABLE_HELP_PAGE,
  FF_ENABLE_HOME_PAGE,
  FF_ENABLE_ORDER_PHARMACY_ALLOCATION,
  FF_ENABLE_PATIENT_ORDERS_PAGE,
  FF_ENABLE_PHARMACIST_ORDERS_TS_MIGRATION,
  FF_ENABLE_PRESCRIPTION_PAGE,
  FF_ENABLE_SUPER_ADMIN_INVENTORY,
  FF_ENABLE_TS_SUPPLIERS_PAGE,
  FF_ENABLE_UMEDS_REBRAND,
  FF_ORDERMEDICATIONPAGE2024,
  FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE,
} from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import ResetPassword from '@/pages/auth/ResetPassword';
import SignIn from '@/pages/auth/SignIn';
import PatientRebrandConsultation from '@/pages/consultations/PatientRebrandConsultation';
import Error404 from '@/pages/error/404';
import PatientHelp from '@/pages/help/PatientHelp';
import MedicineInformationContainer from '@/pages/medicine-information/MedicineInformationContainer';
import PatientHome from '@/pages/patient/PatientHome';
import PatientOrderDetails from '@/pages/patient/patientOrderDetails';
import PatientOrders from '@/pages/patient/patientOrders';
import PatientPrescriptions from '@/pages/patient/PatientPrescriptions';
import PatientRebrandHome from '@/pages/patient/PatientRebrandHome';
import PatientRebrandPrescription from '@/pages/patient/PatientRebrandPrescription';
import PatientRefill from '@/pages/patient/PatientRefill';
import PatientOrdersV2 from '@/pages/patient/umeds_rebrand/PatientOrders';
import PatientRefillV2 from '@/pages/patient/umeds_rebrand/patientRefill/PatientRefill';
import PayByInvoice from '@/pages/payment/shopify/payByInvoice';
import PaymentConfirm from '@/pages/payment/shopify/paymentConfirm';
import PaymentSuccessAuth from '@/pages/payment/shopify/paymentSuccessAuth';
import PaymentSuccessUnauth from '@/pages/payment/shopify/paymentSuccessUnauth';
import PharmacistOrders from '@/pages/pharmacist/PharmacistOrders';
import PharmacyAllocationSettings from '@/pages/pharmacy-allocation-settings/PharmacyAllocationSettings';
import PharmacyInventory from '@/pages/pharmacy-inventory/PharmacyInventory';
import ProductDetailsPage from '@/pages/products/portal-partner/ProductDetails';
import ProductCatalogContainer from '@/pages/products/ProductCatalogContainer';
import ProductDetailsPageV2 from '@/pages/products/ProductDetailsPageV2';
import ProductOfferingContainer from '@/pages/products/productOfferingContainer';
import { AuthService } from '@/services/authentication.service';
import montuTheme, { alternaleafTheme } from '@/theme';
import Brand from '@/utils/brand';

import ApApprovalForm from '../components/ap/apApprovalForm';
import confirmGPBySuperAdmins from '../components/auth/confirmGPAdmin';
import ConfirmUser from '../components/auth/confirmUser';
import SignOut from '../components/auth/SignOut';
import TermsAndPolicy from '../components/auth/TermsAndPolicy';
import ClinicForm from '../components/clinic/clinicForm';
import ClinicView from '../components/clinic/clinicView';
import Campaign from '../components/gp/campaign';
import GPregister from '../components/gp/gpProfile';
import GpList from '../components/gp/gpView'; // Admin view
import Refillordersuccess from '../components/layout/Refillordersuccess';
import mlForm from '../components/ml/mlForm';
import mlView from '../components/ml/mlView';
import OrderView from '../components/order/orderView';
import AdminOnboardPatient from '../components/patient/adminOnboardPatient';
import PatientList from '../components/patient/patientView'; // Gp view list
import ViewPatient from '../components/patient/viewAllPatient'; // Admin view list
import Inventory from '../components/pharmacist/inventory';
import SingleProductOverview from '../components/pharmacist/singleProductOverview';
import PharmacyAccountSetup from '../components/pharmacy/pharmacyAccountSetup';
import PharmacyReport from '../components/pharmacy/pharmacyReport';
import PharmacySuccess from '../components/pharmacy/PharmacySuccess';
import PharmacyUpdateHistory from '../components/pharmacy/pharmacyUpdateHistory';
import PharmacyView from '../components/pharmacy/pharmacyView';
import viewPharmacy from '../components/pharmacy/viewPharmacyReport';
import CPDSessions from '../components/resources/cpdSessions';
import ProductResource from '../components/resources/products';
import Resourses from '../components/resources/resources';
import SasApprovalForm from '../components/sas/sasApprovalForm';
import SuppliersOnboarding from '../components/supplier/supplierOnboarding';
import CreditView from '../pages/credit/creditView';
import faqs from '../pages/faq/faq';
import OrderForm from '../pages/order/orderForm';
import PatientConsultations from '../pages/patient/PatientConsultations';
import PatientCredits from '../pages/patient/patientCreditView';
import PatientOnboard from '../pages/patient/patientOnboard';
import ReferPatient from '../pages/patient/patientReferFriend';
import eWayOrders from '../pages/payment/eWay/eWayTransaction';
import paymentCheckout from '../pages/payment/eWay/paymentCheckout';
import ProductDetails from '../pages/products/productDetails';
import Products from '../pages/products/products';
import SupportSelfService from '../pages/supportSelfService/SupportSelfService';
import Mixpanel from '../services/mixpanel.service';

// Importing the interim Alternaleaf rebranding stylesheet here, rather than requiring it in every page being reworked.
// TODO: Once the rebranding is rolled out, the contents in alternalead.scss should be baselined, and then the file + import removed.
import '../scss/alternaleaf.scss';

import HealthCheck from './healthCheck';

const PatientRefillPharmacy = lazy(() => import('@/pages/patient/umeds_rebrand/PatientRefillPharmacy'));

const OrderAllocationTool = lazy(() => import('@/pages/order-allocation-tool/orderAllocationTool'));

const Suppliers = lazy(() => import('@/pages/supplier/Suppliers'));

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const status = error?.response?.status;
    if (status === UNAUTHORIZED && AuthService.isLoggedIn()) {
      AuthService.logout(true);
    }
    // eslint-disable-next-line no-param-reassign
    delete error.config?.headers?.Authorization;
    // eslint-disable-next-line no-param-reassign
    delete error.response?.config?.headers?.Authorization;
    return Promise.reject(error);
  },
);

function PharmacyAccountSetupWrapper(props: any) {
  return <PharmacyAccountSetup {...props} />;
}

// will track any route changes
function useTrackRoutes() {
  const location = useLocation();
  useEffect(() => {
    Mixpanel(location.pathname);
  }, [location]);
}

type Props = {
  [x: string]: any;
};

function PaymentSuccessAuthWrapper(props: Props) {
  const { flags } = useFeatureFlags();
  const enabledRedirect = flags[FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE];

  if (enabledRedirect) {
    return <Redirect to="/patient/orders" />;
  }

  return <PaymentSuccessAuth {...props} />;
}

function Routes(props: Props) {
  useTrackRoutes();
  const { flags } = useFeatureFlags();

  const location = useLocation();
  const [currentTheme, setCurrentTheme] = useState(Brand.isRebrand() ? alternaleafTheme : montuTheme);
  const enableUmedsRebrand = flags[FF_ENABLE_UMEDS_REBRAND];
  const enableUmeds404And500Pages = flags[FF_ENABLE_404_AND_500_ERROR_PAGES];

  useEffect(() => {
    setCurrentTheme(Brand.isRebrand() ? alternaleafTheme : montuTheme);
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const NoMatch = enableUmeds404And500Pages ? () => <Error404 /> : () => {};

  // redirect to external url for anyone who has bookmarked/accesses the removed 'patient/credit' page
  if (location.pathname === '/patient/credit') {
    window.location.replace(`${settings.support.site.url}/hc/en-au/articles/34907935744153-How-have-credits-changed`);
    return null;
  }

  return (
    <CssVarsProvider theme={currentTheme}>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="/login/:loginType?/:onBoardKey?" component={SignIn} />
        <Route path="/forgot-password/:loginType/" component={ForgotPassword} />
        <Route path="/reset-password/:id" component={ResetPassword} />
        <Route path="/confirm-user/:id" component={ConfirmUser} />
        <Route path="/health-check" component={HealthCheck} />
        <Route path="/policy" component={TermsAndPolicy} />
        <Route path="/pharmacyaccount" component={(data: any) => PharmacyAccountSetupWrapper({ flags, ...data })} />
        <Route path="/pharmacysuccess" component={PharmacySuccess} />
        <Route path="/verify-doctor/:id" component={confirmGPBySuperAdmins} />
        <Route path="/pay-by-invoice/:token" component={PayByInvoice} />
        <Route path="/payment-confirm/account" render={() => <Redirect to={{ pathname: '/home' }} />} />
        <Route path="/payment-confirm/cart" render={() => <Redirect to={{ pathname: '/patient/refill' }} />} />
        <Route path="/payment-confirm/login" render={() => <Redirect to={{ pathname: '/patient/login' }} />} />
        <Route path="/payment-confirm/logout" render={() => <Redirect to={{ pathname: '/logout' }} />} />
        <Route path="/payment-confirm" component={PaymentConfirm} />
        <Route path="/payment-success" component={PaymentSuccessUnauth} />
        <AuthenticatedRoute
          path="/product-catalog"
          component={ProductCatalogContainer}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin]}
          {...props}
        />
        <AuthenticatedRoute
          path="/medicine-information"
          component={MedicineInformationContainer}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        <AuthenticatedRoute
          path="/product-detail/:id"
          component={enableUmedsRebrand ? ProductDetailsPageV2 : ProductOfferingContainer}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/customer/product-detail/:id"
          component={ProductDetailsPage}
          allowedRoles={[USER_ROLES.patient]}
        />
        <AuthenticatedRoute
          path="/patient/orders"
          displayGlobalContentCard
          component={flags[FF_ENABLE_PATIENT_ORDERS_PAGE] ? PatientOrdersV2 : PatientOrders}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        <AuthenticatedRoute
          path="/patient/order-detail/:orderId"
          displayGlobalContentCard
          component={PatientOrderDetails}
          allowedRoles={[USER_ROLES.patient]}
        />
        <AuthenticatedRoute
          path="/refillordersuccess"
          component={Refillordersuccess}
          allowedRoles={[USER_ROLES.patient]}
        />
        <AuthenticatedRoute
          path="/home"
          component={flags[FF_ENABLE_HOME_PAGE] ? PatientRebrandHome : PatientHome}
          allowedRoles={[USER_ROLES.patient]}
        />
        <AuthenticatedRoute
          path="/logout"
          component={SignOut}
          allowedRoles={[
            USER_ROLES.superAdmin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
            USER_ROLES.ml,
            USER_ROLES.pharmacist,
            USER_ROLES.admin,
          ]}
        />
        <AuthenticatedRoute
          path="/gp-register/:id?"
          component={GPregister}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/onboard/:id?"
          component={PatientOnboard}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
          {...props}
        />
        <AuthenticatedRoute
          path="/onboard-patient/:gpId"
          value
          component={AdminOnboardPatient}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/order-patient/:patientId?"
          value
          component={OrderForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/order/:id?/:refill?"
          component={OrderForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/patient/refill/:id?"
          displayGlobalContentCard
          component={flags[FF_ORDERMEDICATIONPAGE2024] ? PatientRefillV2 : PatientRefill}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? (
          <AuthenticatedRoute
            path="/patient/refill-pharmacy"
            component={PatientRefillPharmacy}
            allowedRoles={[USER_ROLES.patient]}
            {...props}
          />
        ) : null}
        <AuthenticatedRoute path="/gp/patients" component={PatientList} allowedRoles={[USER_ROLES.doctor]} />
        <AuthenticatedRoute
          path="/ap-approval/:id"
          value
          component={ApApprovalForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.pharmacist, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/approval/:id"
          value
          component={SasApprovalForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.pharmacist, USER_ROLES.admin]}
        />
        <AuthenticatedRoute path="/approval" value component={SasApprovalForm} allowedRoles={[USER_ROLES.doctor]} />
        <AuthenticatedRoute
          path="/pharmacy-account/:id?"
          component={(data: any) => PharmacyAccountSetupWrapper({ flags, ...data })}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/doctors/"
          component={GpList}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/pharmacy/report/:id"
          component={PharmacyReport}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        {flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] && (
          <AuthenticatedRoute
            path="/pharmacy/:id/inventory"
            component={Inventory}
            allowedRoles={[USER_ROLES.superAdmin]}
          />
        )}
        <AuthenticatedRoute
          path="/pharmacy"
          component={PharmacyView}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/patients"
          component={ViewPatient}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/password/:loginType?/"
          component={ProfileResetPasswordContainer}
          allowedRoles={[
            USER_ROLES.superAdmin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
            USER_ROLES.ml,
            USER_ROLES.pharmacist,
            USER_ROLES.admin,
          ]}
        />
        <AuthenticatedRoute
          path="/orders"
          component={OrderView}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/credits"
          component={CreditView}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
          {...props}
        />
        <AuthenticatedRoute path="/patientcredits" component={PatientCredits} allowedRoles={[USER_ROLES.patient]} />
        <AuthenticatedRoute
          path="/viewclinics"
          component={ClinicView}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/editclinic/:id"
          component={ClinicForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/profile-detail"
          component={ProfileDetailsContainer}
          allowedRoles={[
            USER_ROLES.superAdmin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
            USER_ROLES.ml,
            USER_ROLES.pharmacist,
            USER_ROLES.admin,
          ]}
        />
        <AuthenticatedRoute
          path="/pharmacy-report"
          component={viewPharmacy}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/bd"
          exact
          component={mlView}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/bd/onboard/:id"
          value
          component={mlForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/bd/onboard/"
          component={mlForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/suppliers"
          exact
          component={flags[FF_ENABLE_TS_SUPPLIERS_PAGE] ? Suppliers : SupplierTable}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/suppliers/onboard/:id"
          value
          component={SuppliersOnboarding}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/suppliers/onboard/"
          component={SuppliersOnboarding}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/resources/research"
          component={Research}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/resources/cpd-sessions"
          component={CPDSessions}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/resources/products"
          component={ProductResource}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/resources"
          component={Resourses}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/study/"
          exact
          component={Campaign}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/study/order/"
          exact
          campaign={false}
          component={OrderForm}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor]}
        />
        <AuthenticatedRoute
          path="/study/onboard/:id?"
          exact
          campaign={false}
          component={PatientOnboard}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.doctor]}
        />
        <AuthenticatedRoute
          path="/products"
          component={Products}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/productEdit/:id"
          component={ProductDetails}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute path="/comingSoon" component={ComingSoon} allowedRoles={[USER_ROLES.patient]} />
        <AuthenticatedRoute path="/pharmacist/inventory" component={Inventory} allowedRoles={[USER_ROLES.pharmacist]} />
        <AuthenticatedRoute
          path="/pharmacist/orders"
          component={FF_ENABLE_PHARMACIST_ORDERS_TS_MIGRATION ? PharmacistOrders : PharmacistOrderFCContainer}
          allowedRoles={[USER_ROLES.pharmacist]}
          {...props}
        />
        <AuthenticatedRoute
          path="/pharmacist/productOverview/:id"
          component={SingleProductOverview}
          allowedRoles={[USER_ROLES.pharmacist]}
        />
        <AuthenticatedRoute
          path="/paymentcheckout"
          component={paymentCheckout}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        <AuthenticatedRoute
          path="/patient/payment-success"
          component={PaymentSuccessAuthWrapper}
          allowedRoles={[USER_ROLES.patient]}
        />
        <AuthenticatedRoute
          path="/invoices"
          component={eWayOrders}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/patient/prescription"
          component={flags[FF_ENABLE_PRESCRIPTION_PAGE] ? PatientRebrandPrescription : PatientPrescriptions}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        <AuthenticatedRoute
          path="/patient/consultations"
          component={flags[FF_ENABLE_CONSULTATION_PAGE] ? PatientRebrandConsultation : PatientConsultations}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />

        <AuthenticatedRoute
          path="/patient/refer-friend"
          component={ReferPatient}
          allowedRoles={[USER_ROLES.patient]}
          {...props}
        />
        <AuthenticatedRoute
          path="/faq"
          component={flags[FF_ENABLE_HELP_PAGE] ? PatientHelp : faqs}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/support-self-service"
          component={SupportSelfService}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/order-allocation-tool"
          component={OrderAllocationTool}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/beta-pharmacy-inventory"
          component={PharmacyInventory}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/pharmacy-allocation-settings"
          component={PharmacyAllocationSettings}
          allowedRoles={[USER_ROLES.superAdmin, USER_ROLES.admin]}
        />
        <AuthenticatedRoute
          path="/updatehistory"
          component={PharmacyUpdateHistory}
          allowedRoles={[USER_ROLES.superAdmin]}
        />

        <Route component={NoMatch} />
      </Switch>
    </CssVarsProvider>
  );
}

export default Routes;
