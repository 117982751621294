/* eslint-disable no-undefined */
import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import settings from '@/constants/constants';
import { PharmacyService } from '@/services/pharmacy.service';
import { AuthService } from '@/services/authentication.service';
import Footer from '../layout/footer';
import DatePicker from 'react-datepicker';
import TopBanner from '../resources/TopBanner';
import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import USER_ROLES from '@/constants/userRoles';
import { FF_ENABLE_SUPER_ADMIN_INVENTORY, FF_FF_ENABLE_UMEDS_CHECKBOX } from '@/constants/featureFlags';

import logo from '../layout/montu.png';

import { Logger } from '@/utils/logger';

const logger = new Logger('pharmacyAccountSetup');

const url = settings.url;

class pharmacyAccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isremove: false,
      loading: false,
      none: this.props.params,
      formType: '',
      companyName: '',
      ABN: '',
      address: '',
      sub_address: '',
      defaultCity: '',
      defaultState: { value: '', label: 'Select State' },
      defaultCountry: { value: 'australia', label: 'Australia' },
      city: '',
      state: '',
      postal_code: '',
      country: 13,
      businessPersonName: '',
      businessPersonLastName: '',
      businessPersonPosition: '',
      businessPersonPhone: '',
      businessPersonEmail: '',
      pharmacistName: '',
      pharmacistlastName: '',
      pharmacistPhone: '',
      pharmacistAhpraNumber: '',
      countries: [],
      states: [],
      cities: [],
      data: [],
      date: new Date(),
      script: 1,
      accountName: '',
      accountNo: '',
      bsb: '',
      bankNumber: '',
      authorised_firstName: '',
      authorised_lastName: '',
      active: 0,
      home_delivery: '',
      formValue: 0,
      captchaValue: '',
      field_duplicate: 0,
      pharmacistEmail: '',
      identifierName: '',
      pharmacistSecondaryEmail: '',
      role_id: AuthService.getUser()?.user?.role_id,
      is_umeds: false,
    };
    this.initialState = this.state;
    this.step1validator = new SimpleReactValidator({
      validators: {
        ahpra: {
          message: 'The field requires 3 letters and 10 numbers',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, '^[A-Za-z]{3}\\d{10}$') && params.indexOf(val) === -1;
          },
          required: true,
        },
        account_number: {
          message: 'Account Number should only contain numbers',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, '^[0-9]*$') && params.indexOf(val) === -1;
          },
          required: true,
        },
        identifier_name: {
          message: 'The field should be of 4-7 upper-case letters',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, '^[A-Z]{4,7}$') && params.indexOf(val) === -1;
          },
        },
      },
    });
    this.step2validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    document.title = 'Pharmacy - Montu Group | Making Medical Cannabis Accessible';
    const { country } = this.state;
    const countryList = await PharmacyService.getPharmacyCountries();
    if (countryList) {
      this.setState({
        countries: countryList,
        content: false,
      });
    }
    const stateList = await PharmacyService.getPharmacyStates(country);
    if (stateList) {
      this.setState({
        states: stateList,
      });
    }
    if (this.props.match.params.id && AuthService.isAdmin()) {
      if (this.props.match.params.id) {
        await axios.get(url + `/pharmacy/${this.props.match.params.id}`).then((data) => {
          let address, sub_address;
          if (data.data.address !== null) {
            let splitaddress = data.data.address || '';
            splitaddress = splitaddress.split('$$$$');
            address = splitaddress[0];
            sub_address = splitaddress[1];
          }
          let splitname = data.data.business_contact_person || '';
          splitname = splitname.split('$$$$');
          let first_name = splitname[0];
          let last_name = splitname[1];
          let pharmacistname = data.data.pharmacist_name;
          pharmacistname = pharmacistname.split('$$$$');
          let pharmacist_first_name = pharmacistname[0];
          let pharmacist_last_name = pharmacistname[1];
          this.setState({
            data: data.data,
            companyName: data.data.name,
            ABN: data.data.abn,
            identifierName: data.data.identifier_name,
            address: address ? address : '',
            sub_address: sub_address,
            city: data.data.city,
            state: data.data.state,
            country: data.data.country_id,
            postal_code: data.data.zip_code,
            businessPersonName: first_name,
            businessPersonLastName: last_name,
            businessPersonPosition: data.data.business_contact_position,
            businessPersonPhone: data.data.business_contact_phone,
            businessPersonEmail: data.data.business_contact_email,
            pharmacistEmail: data.data.pharmacist_email,
            pharmacistName: pharmacist_first_name,
            pharmacistlastName: pharmacist_last_name,
            pharmacistPhone: data.data.pharmacist_phone,
            pharmacistAhpraNumber: data.data.pharmacist_ahpraNumber,
            accountName: data.data.account_name,
            accountNo: data.data.account_number,
            bsb: data.data.bsb,
            bankNumber: data.data.bank_name,
            authorised_firstName: data.data.authorised_first_name,
            authorised_lastName: data.data.authorised_last_name,
            active: data.data.active,
            home_delivery: data.data.home_delivery,
            defaultCountry: { label: data.data.Country.name, value: data.data.country_id },
            defaultState: { label: data.data.state, value: data.data.state },
            fetched: false,
            content: true,
            pharmacistSecondaryEmail: data.data.pharmacy_secondary_email,
            is_umeds: data.data.is_umeds,
          });
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        content: true,
      });
      this.setState(this.initialState);
      const { country } = this.state;
      const { countryList } = await PharmacyService.getPharmacyCountries();
      if (countryList) {
        this.setState({
          countries: countryList,
        });
      }
      const stateList = await PharmacyService.getPharmacyStates(country);
      if (stateList) {
        this.setState({
          states: stateList,
        });
      }
    }
  }

  //entire country list
  handleCountry = async (e) => {
    this.setState({
      country: e.value,
      defaultCountry: { value: e.value, label: e.label },
    });
    const value = e.value;
    const stateList = await PharmacyService.getPharmacyStates(value);
    this.setState({ states: stateList });
  };

  handleState = async (e) => {
    this.setState({
      state: e.value,
      defaultState: { value: e.value, label: e.label },
    });
  };

  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };

  handleIdentifierNameInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  handleBsnInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    var str = value;
    str = str.replace('-', '');
    if (str.length > 3) {
      str = str.substring(0, 3) + '-' + str.substring(3);
    }
    if (e.target.value > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
    this.setState({
      [name]: str,
    });
  };
  handleABNInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value > e.target.maxLength) {
      e.target.value = value.slice(0, e.target.maxLength);
    }
    this.setState({
      [name]: value,
    });
  };

  onChange = (value) => {
    this.setState({
      captchaValue: value,
    });
  };

  onSubmission = async (e) => {
    e.preventDefault();
    if (!this.step2validator.allValid() || !this.step1validator.allValid()) {
      this.step2validator.showMessages();
      this.step1validator.showMessages();
      this.forceUpdate();
      return;
    }
    if (!this.props.match.params.id) {
      this.setState({
        loading: true,
      });
      let pharmacy = await AuthService.pharmacy(
        this.state.companyName,
        this.state.ABN,
        this.state.address + '$$$$' + this.state.sub_address,
        this.state.city,
        this.state.state,
        this.state.country,
        this.state.postal_code,
        this.state.field_duplicate === 1
          ? this.state.businessPersonName
          : this.state.businessPersonName + '$$$$' + this.state.businessPersonLastName,
        this.state.businessPersonPosition,
        this.state.businessPersonPhone,
        this.state.businessPersonEmail,
        this.state.pharmacistName + '$$$$' + this.state.pharmacistlastName,
        this.state.pharmacistEmail,
        this.state.pharmacistPhone,
        this.state.pharmacistAhpraNumber,
        this.state.accountName,
        this.state.accountNo,
        this.state.bsb,
        this.state.bankNumber,
        this.state.authorised_firstName,
        this.state.authorised_lastName,
        this.state.script,
        this.state.date,
        this.state.active,
        this.state.home_delivery,
        this.state.identifierName.length === 0 ? 'OTHERS' : this.state.identifierName,
        this.state.pharmacistSecondaryEmail,
        this.state.is_umeds,
      );
      if (pharmacy) {
        this.props.history.push('/pharmacysuccess');
        this.setState({ none: 'd-none' });
        this.forceUpdate();
        this.setState({
          loading: false,
        });
      } else {
        toast.error('Pharmacy details are not added');
        this.setState({
          loading: false,
        });
        return 'Error';
      }
    } else {
      await axios
        .put(url + `/pharmacy/${this.props.match.params.id}`, {
          body: JSON.stringify({
            companyName: this.state.companyName,
            ABN: this.state.ABN,
            address: this.state.address + '$$$$' + this.state.sub_address,
            city: this.state.city,
            state: this.state.state,
            postal_code: this.state.postal_code,
            country: this.state.country,
            businessPersonName: this.state.businessPersonName + '$$$$' + this.state.businessPersonLastName,
            businessPersonPosition: this.state.businessPersonPosition,
            businessPersonPhone: this.state.businessPersonPhone,
            businessPersonEmail: this.state.businessPersonEmail,
            pharmacistName: this.state.pharmacistName + '$$$$' + this.state.pharmacistlastName,
            pharmacistPhone: this.state.pharmacistPhone,
            pharmacistEmail: this.state.pharmacistEmail,
            pharmacistAhpraNumber: this.state.pharmacistAhpraNumber,
            accountName: this.state.accountName,
            accountNo: this.state.accountNo,
            bsb: this.state.bsb,
            bankNumber: this.state.bankNumber,
            authorised_firstName: this.state.authorised_firstName,
            authorised_lastName: this.state.authorised_lastName,
            date: this.state.date,
            active: this.state.active,
            home_delivery: this.state.home_delivery,
            identifierName: this.state.identifierName.length === 0 ? 'OTHERS' : this.state.identifierName,
            pharmacistSecondaryEmail: this.state.pharmacistSecondaryEmail,
            is_umeds: this.state.is_umeds,
          }),
        })
        .then(async (res) => {
          if (res.status === 200) {
            this.setState({
              submitted: true,
              loading: false,
              formType: '',
              companyName: '',
              ABN: '',
              identifierName: '',
              address: '',
              sub_address: '',
              city: '',
              state: '',
              postal_code: '',
              country: '',
              businessPersonName: '',
              businessPersonLastName: '',
              businessPersonPosition: '',
              businessPersonPhone: '',
              businessPersonEmail: '',
              pharmacistName: '',
              pharmacistEmail: '',
              pharmacistlastName: '',
              pharmacistPhone: '',
              pharmacistAhpraNumber: '',
              active: this.state.active,
              home_delivery: this.state.home_delivery,
              field_duplicate: this.state.field_duplicate,
              pharmacistSecondaryEmail: '',
            });
            this.props.history.push('/pharmacy');
            toast.success('Pharmacy details are updated');
          }
        })
        .catch((err) => {
          toast.error('Pharmacy details not updated');
          logger.error('Pharmacy details not updated', err);
          this.setState({
            loading: false,
          });
          return err;
        });
    }
  };

  handleClick = () => {
    this.setState({ none: 'd-none' });
  };

  deletefile = async (e) => {
    e.preventDefault();
    toast(
      <ToastConfirmModal
        onConfirm={async () =>
          await axios.delete(url + `/pharmacy/${this.props.match.params.id}`).then((response) => {
            if (response.data.status === 200) {
              toast.success('Pharmacy removed successfully');
              this.props.history.push('/pharmacy');
            } else {
              toast.success('Pharmacy could not be removed');
            }
            this.forceUpdate();
          })
        }
      >
        <p>Are you sure you want to delete this?</p>
      </ToastConfirmModal>,
      toastOptions,
    );
  };

  redirectToInventoryInsights = async () => {
    const pharmacy_id = this.props.match.params.id;
    this.props.history.push(`/pharmacy/${pharmacy_id}/inventory`);
  };

  render() {
    const {
      loading,
      companyName,
      ABN,
      address,
      sub_address,
      country,
      defaultCountry,
      countries,
      state,
      postal_code,
      city,
      businessPersonName,
      businessPersonLastName,
      businessPersonEmail,
      businessPersonPosition,
      pharmacistEmail,
      businessPersonPhone,
      pharmacistName,
      pharmacistlastName,
      pharmacistPhone,
      pharmacistAhpraNumber,
      active,
      script,
      home_delivery,
      authorised_firstName,
      authorised_lastName,
      date,
      captchaValue,
      field_duplicate,
      defaultState,
      states,
      identifierName,
      pharmacistSecondaryEmail,
      is_umeds,
    } = this.state;

    let CountryOp = countries.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
    let StateOp = states.map((state) => {
      return {
        label: state.name,
        value: state.name,
      };
    });
    let fieldduplicateChecked = field_duplicate === 1 ? true : false;
    return (
      <React.Fragment>
        {this.props.match.params.id ? (
          ''
        ) : (
          <div>
            <nav className="navbar fixed-top p-0 shadow navbar-expand-lg navbg justify-content-between d-flex">
              <a href="https://www.montu.com.au" className="navbar-brand nav-response" target="blank">
                <img src={logo} alt="montu" className="p-2 montu-logo" />
              </a>
            </nav>
            <div className="h-100 main-content resources-content">
              <TopBanner h1Text="Fill out the form to join our partner network" pText="PHARMACY ACCOUNT SETUP" />
            </div>
          </div>
        )}

        <div>
          {this.props.state === false ? (
            ''
          ) : (
            <div className={`container ${this.state.none}`}>
              <div className="row pharmacy">
                <div className="col-md-12">
                  {this.props.match.params.id ? (
                    <>
                      <Button
                        text="Delete"
                        type="submit"
                        loading={loading}
                        className="btn btn-primary float-right mt-5"
                        onClick={this.deletefile}
                      />
                      {this.props.flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] &&
                        this.state.role_id === USER_ROLES.superAdmin && (
                          <Button
                            text="Inventory Insights"
                            loading={loading}
                            className="btn btn-primary float-right mt-5 mr-3"
                            onClick={this.redirectToInventoryInsights}
                          />
                        )}
                    </>
                  ) : (
                    ''
                  )}
                  <h3 className="mt-5">{this.props.match.params.id ? 'Pharmacy Account' : 'Pharmacy Account Setup'}</h3>
                  <div className="form-group sub-text pl-3 pull-right">
                    <div className={this.props.match.params.id ? 'row mr-1' : 'd-none'}>
                      <div className="mr-5">
                        <input
                          type="checkbox"
                          className="form-check-input checkbox"
                          name="active"
                          id="active"
                          onChange={this.handleCheckbox}
                          checked={active}
                        />
                        <label htmlFor="active" className="checkbox">
                          Active
                        </label>
                      </div>
                      <div className={this.props.flags[FF_FF_ENABLE_UMEDS_CHECKBOX] === true ? 'mr-5' : 'mr-auto'}>
                        <input
                          type="checkbox"
                          className="form-check-input checkbox"
                          name="home_delivery"
                          id="home_delivery"
                          onChange={this.handleCheckbox}
                          checked={home_delivery}
                        />
                        <label htmlFor="home_delivery" className="checkbox">
                          Home Delivery
                        </label>
                      </div>
                      {this.props.flags[FF_FF_ENABLE_UMEDS_CHECKBOX] === true && (
                        <div>
                          <input
                            type="checkbox"
                            className="form-check-input checkbox"
                            name="is_umeds"
                            id="is_umeds"
                            onChange={this.handleCheckbox}
                            checked={is_umeds}
                          />
                          <label htmlFor="is_umeds" className="checkbox">
                            uMeds Enabled
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <form className={this.props.match.params.id ? 'mt-4' : ''}>
                    <span className="h5 pharmacy-title-hide">
                      Business Details
                      <h1 onClick={this.handleClick} className="float-right">
                        <Button text="close X" className="btn btn-primary btn-md ml-3 px-3 mb-5 d-none"></Button>
                      </h1>
                    </span>
                    <hr />
                    <div className="form-row">
                      <div className="form-group col-md-6 mt-3 mb-0 md-2">
                        <FormInput
                          type="text"
                          name="companyName"
                          label={this.props.match.params.id ? 'Business / Company Name' : 'Pharmacy name'}
                          value={companyName}
                          onChange={this.handleUserInput}
                          error={this.step1validator.message('companyName', companyName, 'required')}
                          testname={companyName}
                        />
                      </div>
                      <div className="form-group col-md-6 mt-3 mb-0">
                        <FormInput
                          type="text"
                          name="ABN"
                          label={'ABN'}
                          value={ABN ? ABN : ''}
                          maxLength="11"
                          onChange={this.handleABNInput}
                          error={this.step1validator.message('abn', ABN, 'required|min:11|max:11')}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 mb-0 md-2">
                        <FormInput
                          type="text"
                          name="identifierName"
                          label={'Identifier Name'}
                          value={identifierName ? identifierName : ''}
                          maxlength="7"
                          onChange={this.handleIdentifierNameInput}
                          error={this.step1validator.message('identifier name', identifierName, 'identifier_name')}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-0">
                        <FormInput
                          type="email"
                          name="pharmacistSecondaryEmail"
                          label={'Pharmacist Secondary Email '}
                          value={pharmacistSecondaryEmail ? pharmacistSecondaryEmail : ''}
                          onChange={this.handleUserInput}
                          error={this.step1validator.message('email', pharmacistSecondaryEmail, 'required|email')}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <FormInput
                        type="text"
                        name="address"
                        label={'Pharmacy / Delivery Address'}
                        value={address ? address : ''}
                        info="Street Address"
                        onChange={this.handleUserInput}
                        error={this.step1validator.message('address', address, 'required')}
                      />
                    </div>
                    <div className="d-none">
                      <FormInput
                        type="text"
                        name="sub_address"
                        placeholder=""
                        info="Address Line 2"
                        value={sub_address ? sub_address : ''}
                        onChange={this.handleUserInput}
                      />
                    </div>
                    <div className="form-row mb-0">
                      <div className="form-group col-md-6 mb-0 md-2">
                        <FormInput
                          type="text"
                          name="city"
                          placeholder="City"
                          value={city ? city : ''}
                          info="City"
                          onChange={this.handleUserInput}
                          error={this.step1validator.message('city', city, 'required')}
                        />
                      </div>
                      <div className="form-group col-md-6 my-auto">
                        <Select
                          name="state"
                          className={`${
                            this.step1validator.message('state', state, 'required') ? 'rounded dropdown-border' : ''
                          }`}
                          value={defaultState}
                          onChange={this.handleState}
                          isSearchable={true}
                          placeholder="Select State"
                          options={StateOp}
                        />
                        {this.step1validator.message('State', state, 'required')}
                        <small className="form-text text-muted ml-1">State / Province / Region</small>
                      </div>
                    </div>
                    <div className="form-row mb-0">
                      <div className="form-group col-md-6 my-auto">
                        <FormInput
                          type="text"
                          name="postal_code"
                          placeholder="Postcode"
                          value={postal_code ? postal_code : ''}
                          info="Postcode"
                          onChange={this.handleUserInput}
                          error={this.step1validator.message('zip-code', postal_code, 'required')}
                        />
                      </div>
                      <div className="form-group col-md-6 pharmacy-dropdown">
                        <Select
                          name="country"
                          value={defaultCountry}
                          onChange={this.handleCountry}
                          isSearchable={true}
                          placeholder="Select Country"
                          options={CountryOp}
                        />
                        {this.step1validator.message('Country', country, 'required')}
                        <small className="form-text text-muted ml-1">Country</small>
                      </div>
                    </div>
                    <hr />

                    <div className="mb-0">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <p className="h5 mb-0">PHARMACIST CONTACT DETAILS</p>
                          <small className="text-muted">DETAILS OF DISPENSING PHARMACIST</small>
                          <label className=" mb-0 mt-sm-3 mt-md-4 d-block"> Pharmacist Name</label>
                          <div className="form-group row mb-0">
                            <FormInput
                              type="text"
                              name="pharmacistName"
                              info="First"
                              className="col-md-6"
                              value={pharmacistName ? pharmacistName : ''}
                              onChange={this.handleUserInput}
                              error={this.step1validator.message('first name', pharmacistName, 'required')}
                            />
                            <FormInput
                              type="text"
                              name="pharmacistlastName"
                              info="Last"
                              className="col-md-6"
                              value={pharmacistlastName ? pharmacistlastName : ''}
                              onChange={this.handleUserInput}
                              error={this.step1validator.message('last name', pharmacistlastName, 'required')}
                            />
                          </div>
                          <div className="form-group">
                            <FormInput
                              type="text"
                              name="pharmacistAhpraNumber"
                              placeholder="e.g. PHA0001234567"
                              label={'AHPRA Number '}
                              value={pharmacistAhpraNumber ? pharmacistAhpraNumber : ''}
                              onChange={this.handleUserInput}
                              error={this.step1validator.message('aphra-number', pharmacistAhpraNumber, 'ahpra')}
                            />
                          </div>
                          <div className="form-group">
                            <FormInput
                              type="email"
                              name="pharmacistEmail"
                              label={'Pharmacist Email '}
                              value={pharmacistEmail ? pharmacistEmail : ''}
                              onChange={this.handleUserInput}
                              error={this.step1validator.message('email', pharmacistEmail, 'required|email')}
                            />
                          </div>
                          <div className="form-group">
                            <FormInput
                              type="text"
                              name="pharmacistPhone"
                              label={'Pharmacist Phone'}
                              value={pharmacistPhone ? pharmacistPhone : ''}
                              onChange={this.handleUserInput}
                              error={this.step1validator.message(
                                'pharmacist-phone',
                                pharmacistPhone,
                                'required|regex:^[0-9+()-\\s]+$',
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="h5 mb-0">BUSINESS CONTACT DETAILS </p>
                          <small className="text-muted">DETAILS OF PHARMACY OWNER OR MANAGER</small>
                          <div className="form-group sub-text ml-0">
                            <input
                              type="checkbox"
                              className="form-check-input checkbox ml-0"
                              name="field_duplicate"
                              id="field_duplicate"
                              onChange={this.handleCheckbox}
                              checked={fieldduplicateChecked}
                            />
                            <label htmlFor="field_duplicate" className="checkbox ml-4">
                              same as Pharmacist Contact Details
                            </label>
                          </div>
                          {this.state.field_duplicate === 1 ? (
                            <div style={{ marginTop: '-25px' }}>
                              <label className=" mb-0 mt-sm-3 mt-md-4 d-block"> Business Contact Person</label>
                              <div className="form-group row mb-0">
                                <FormInput
                                  type="text"
                                  name="businessPersonName"
                                  info="First"
                                  className="col-md-6"
                                  value={pharmacistName ? pharmacistName : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message(
                                    'first name',
                                    field_duplicate === 1 ? pharmacistName : businessPersonName,
                                    'required',
                                  )}
                                />
                                <FormInput
                                  type="text"
                                  name="businessPersonLastName"
                                  info="Last"
                                  className="col-md-6"
                                  value={pharmacistlastName ? pharmacistlastName : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message(
                                    'last name',
                                    field_duplicate === 1 ? pharmacistlastName : businessPersonLastName,
                                    'required',
                                  )}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="businessPersonPosition"
                                  label={'Position'}
                                  value={businessPersonPosition ? businessPersonPosition : ''}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="email"
                                  name="businessPersonEmail"
                                  label={'Business Contact Email '}
                                  value={pharmacistEmail ? pharmacistEmail : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message(
                                    'email',
                                    field_duplicate === 1 ? pharmacistEmail : businessPersonEmail,
                                    'required|email',
                                  )}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="businessPersonPhone"
                                  label={'Business Contact Phone'}
                                  value={pharmacistPhone ? pharmacistPhone : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message(
                                    'phone-number',
                                    field_duplicate === 1 ? pharmacistPhone : businessPersonPhone,
                                    'required|regex:^[0-9+()-\\s]+$',
                                  )}
                                />
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginTop: '-25px' }}>
                              <label className=" mb-0 mt-sm-3 mt-md-4 d-block"> Business Contact Person</label>
                              <div className="form-group row mb-0">
                                <FormInput
                                  type="text"
                                  name="businessPersonName"
                                  info="First"
                                  className="col-md-6"
                                  value={businessPersonName ? businessPersonName : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message('first name', businessPersonName, 'required')}
                                />
                                <FormInput
                                  type="text"
                                  name="businessPersonLastName"
                                  info="Last"
                                  className="col-md-6"
                                  value={businessPersonLastName ? businessPersonLastName : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message('last name', businessPersonLastName, 'required')}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="businessPersonPosition"
                                  label={'Position'}
                                  value={businessPersonPosition ? businessPersonPosition : ''}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="email"
                                  name="businessPersonEmail"
                                  label={'Business Contact Email '}
                                  value={businessPersonEmail ? businessPersonEmail : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message('email', businessPersonEmail, 'required|email')}
                                />
                              </div>
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="businessPersonPhone"
                                  label={'Business Contact Phone'}
                                  value={businessPersonPhone ? businessPersonPhone : ''}
                                  onChange={this.handleUserInput}
                                  error={this.step1validator.message(
                                    'phone-number',
                                    businessPersonPhone,
                                    'required|regex:^[0-9+()-\\s]+$',
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="mb-0">
                      <p className="h5 mb-0">PHARMACY BANK ACCOUNT DETAILS </p>
                      <small className="text-muted">DISPENSING FEE WILL BE TRANSFERRED TO THIS ACCOUNT</small>
                      <div className="form-row">
                        <div className="form-group col-md-6 mt-3 mb-0 md-2">
                          <FormInput
                            type="text"
                            name="accountName"
                            label="Account Name"
                            value={this.state.accountName}
                            onChange={this.handleUserInput}
                            error={this.step1validator.message('account-name', this.state.accountName, 'required')}
                          />
                        </div>
                        <div className="form-group col-md-6 mt-3 mb-0">
                          <FormInput
                            type="text"
                            placeholder="XXX-XXX"
                            id="myinput"
                            maxLength="7"
                            name="bsb"
                            label="BSB"
                            value={this.state.bsb}
                            onChange={this.handleBsnInput}
                            error={this.step1validator.message('bsb', this.state.bsb, 'required|min:7|max:7')}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 mt-3 mb-0 md-2">
                          <FormInput
                            type="text"
                            name="accountNo"
                            label="Account Number"
                            value={this.state.accountNo}
                            onChange={this.handleUserInput}
                            error={this.step1validator.message(
                              'account-number',
                              this.state.accountNo,
                              'account_number',
                            )}
                          />
                        </div>
                        <div className="form-group col-md-6 mt-3 mb-0">
                          <FormInput
                            type="text"
                            name="bankNumber"
                            label="Bank Name"
                            value={this.state.bankNumber}
                            onChange={this.handleUserInput}
                            error={this.step1validator.message('bank-name', this.state.bankNumber, 'required')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={this.props.match.params.id ? '' : 'd-none'}>
                      <h5>EXECUTED BY AN AUTHORISED REPRESENTATIVE OF THE PHARMACY</h5>
                      <div className="form-group pl-3">
                        <div className="p-1">
                          <input
                            type="checkbox"
                            className="form-check-input checkbox"
                            name="script"
                            id="script"
                            onChange={this.handleCheckbox}
                            checked={script}
                          />
                          <label htmlFor="script" className="checkbox sub-text">
                            I ACKNOWLEDGE TERMS AND CONDITIONS{' '}
                          </label>
                        </div>
                      </div>
                      <label>Authorised Representative Name</label>

                      <div className="form-row">
                        <FormInput
                          type="text"
                          name="authorised_firstName"
                          className="col-md-4"
                          value={authorised_firstName}
                          info="First"
                          onChange={this.handleUserInput}
                        />
                        <FormInput
                          type="text"
                          name="authorised_lastName"
                          className="col-md-4"
                          info="Last"
                          value={authorised_lastName}
                          onChange={this.handleUserInput}
                        />
                      </div>
                      <div className={this.props.match.params.id ? 'form-group p-1' : 'd-none'}>
                        <label className="d-block"> Date </label>
                        <DatePicker
                          className="form-control"
                          id="dataPicker"
                          selected={this.state.date}
                          onChange={this.handleChange}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <label htmlFor={'dataPicker'}>
                          <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                        </label>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <Button
                        text={this.props.match.params.id ? 'Update' : 'Next Page'}
                        type="submit"
                        loading={loading}
                        className="btn btn-primary btn-md ml-3 px-3 mb-5 float-right"
                        onClick={this.onSubmission}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.props.match.params.id ? '' : <Footer />}
      </React.Fragment>
    );
  }
}

export default pharmacyAccountSetup;
