type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
  errorMessageTitle?: string;
};

export default function useAddressValidation() {
  const validatePostcode = (postcode: string): ValidationResult => {
    const postcodePattern = /^\d{4}$/;
    if (!postcodePattern.test(postcode)) {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid 4-digit postcode.',
      };
    }
    return { isValid: true };
  };

  const validateParcelLocker = (value: string): ValidationResult => {
    const parcelLockerPattern = /(\d{5}\s?\d{5})?\s?(parcel\s*?locker)\s?(\d{5}\s?\d{5})?/i;
    if (parcelLockerPattern.test(value)) {
      return {
        isValid: false,
        errorMessageTitle: 'Parcel Lockers are not allowed.',
        errorMessage: 'Use a residential, business, or PO Box address.',
      };
    }
    return { isValid: true };
  };

  // Add other validation functions as needed

  return {
    validatePostcode,
    validateParcelLocker,
  };
}
