import { lazy, useEffect, useState } from 'react';
import { Alert, Box, Stack, styled, Typography } from '@mui/material';

import useGetUserProfileData from '@/hooks/user/useGetUserProfile';
import PasswordReset from '@/pages/profile/PasswordReset';
import ProfileFormLoadingSkeleton from '@/pages/profile/ProfileFormLoadingSkeleton';
import BackButton from '@/ui-library/backButton/BackButton';

const ProfileForm = lazy(() => import('@/pages/profile/ProfileForm'));

interface IAlert {
  severity: 'success' | 'error';
  message: string;
}

export const AlertResponse = styled(Alert)<{ type: 'success' | 'error' }>(({ theme, type }) => ({
  backgroundColor: type === 'success' ? theme.palette.success.light : theme.palette.error.light,
  color: type === 'success' ? theme.palette.success.dark : theme.palette.error.dark,
  fontWeight: theme.typography.fontWeightBold,
  margin: theme.spacing(4, 0),
  width: '100%',
  '& .MuiAlert-message': {
    padding: theme.spacing(2.5, 0),
  },
  [theme.breakpoints.up('md')]: {
    width: '33%',
    minWidth: '375px',
  },
}));

export default function PatientRebrandProfile() {
  const { data, isLoading, isSuccess, isError } = useGetUserProfileData();
  const [alert, setAlert] = useState<IAlert>({ severity: 'success', message: '' });

  const handleClose = () => {
    setAlert({ severity: 'success', message: '' });
  };

  const profileFormContent = () => {
    if (isLoading) return <ProfileFormLoadingSkeleton />;
    if (isSuccess && data) {
      return <ProfileForm data={data} />;
    }
    return null;
  };

  useEffect(() => {
    if (!data && !isLoading && isError) {
      setAlert({ severity: 'error', message: 'Error retrieving profile data' });
    }
  }, [data, isLoading, isError]);

  return (
    <Box>
      <Stack direction="row">
        <BackButton />
        <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(10)}>
          Profile
        </Typography>
      </Stack>
      {alert.message && (
        <AlertResponse severity={alert.severity} type={alert.severity} onClose={handleClose}>
          {alert.message}
        </AlertResponse>
      )}

      {profileFormContent()}
      <PasswordReset />
    </Box>
  );
}
