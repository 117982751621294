import { Box, styled } from '@mui/material';

import StarRating from '@/components/common/starRating';
import { FF_PRODUCT_IMAGE_CAROUSEL, FF_USE_SHOPIFY_PRODUCT_IMAGES } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import getProductImageUrl from '@/utils/getProductImageUrl';

import ProductImagesCarousel from './ProductImagesCarousel';
import ProductStatusLabel from './ProductStatusLabel';
import { Product } from './types';

const ProductOverviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const ProductImagesContainer = styled(Box)`
  flex: 1;
  min-width: 0;
  position: relative;
`;

const ProductImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid lightGray',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    flex: 1,
    minWidth: '10rem',
  },
}));

const ProductOverviewDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-start',
    flex: 2,
  },
}));

const SupplierName = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
}));

const ProductName = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '3rem',
  fontWeight: 500,
  lineHeight: '3.25rem',
}));

const ProductRatioText = styled('span')`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
`;

const ProductPrice = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
}));

const ProductHighlight = styled(Box)`
  display: flex;
  width: 150px;
  border: 2px solid #ceedff;
  border-radius: 10px;
  margin: 3px;
  padding: 4px;
  text-align: center;
  justify-content: center;
  background-color: white;
`;

const ProductDescription = styled('p')`
  font-size: 1.1rem;
  line-height: 1.5rem;
`;

const OutOfStockText = styled('p')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.warning.main,
}));

const ProductBadgeContainer = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface ProductOverviewProps {
  product: Product;
  productRating: string;
}

export function ProductOverview(props: ProductOverviewProps) {
  const { product, productRating } = props;
  const displayName = product.short_name || product.name;

  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES] || ffDefaults[FF_USE_SHOPIFY_PRODUCT_IMAGES];

  const useProductImageCarousel = flags[FF_PRODUCT_IMAGE_CAROUSEL] || ffDefaults[FF_PRODUCT_IMAGE_CAROUSEL];

  const productImages = product?.ProductImages?.map((img) => img.image_url) || [];
  return (
    <ProductOverviewContainer gap={useProductImageCarousel ? '4rem' : '1.5rem'}>
      {useShopifyProductImages && useProductImageCarousel && productImages.length > 1 ? (
        <ProductImagesContainer>
          <ProductImagesCarousel images={productImages} />
          <ProductBadgeContainer>
            <ProductStatusLabel
              newAtLowPrice={product.new_at_lowprice}
              comingSoon={product.coming_soon}
              isConcessionProduct={product.is_concession}
              isOutOfStock={product.is_out_of_stock || product.reasoning_toggle}
              newProduct={product.new_product}
              campaignDiscountPrice={product.campaign_discount_price}
              isLimitedAvailability={product.is_excluded_from_new_prescriptions}
            />
          </ProductBadgeContainer>
        </ProductImagesContainer>
      ) : (
        <ProductImageContainer>
          <img
            src={getProductImageUrl(useShopifyProductImages, product.image_url, product.ProductImages)}
            className="img-fluid mx-auto position-relative"
            style={{ objectFit: 'cover' }}
            alt={`${displayName} preview`}
          />
          <ProductBadgeContainer>
            <ProductStatusLabel
              newAtLowPrice={product.new_at_lowprice}
              comingSoon={product.coming_soon}
              isConcessionProduct={product.is_concession}
              isOutOfStock={product.is_out_of_stock || product.reasoning_toggle}
              newProduct={product.new_product}
              campaignDiscountPrice={product.campaign_discount_price}
              isLimitedAvailability={product.is_excluded_from_new_prescriptions}
            />
          </ProductBadgeContainer>
        </ProductImageContainer>
      )}

      <ProductOverviewDetails>
        <div className="mb-2">
          <StarRating value={productRating} small starCount={productRating} displayOnly />
        </div>
        <div className="mb-2">
          <SupplierName>{product.Suppliers[0] ? product.Suppliers[0].supplier_name : '-'}</SupplierName>
        </div>
        <div className="mb-4">
          <ProductName>{displayName}</ProductName>
        </div>
        <div className="mb-2">
          <ProductRatioText>
            THC {product.thc_strength_for_display} : CBD {product.cbd_strength_for_display}
          </ProductRatioText>
        </div>
        <div className="mb-4">
          <ProductPrice>${product.price}</ProductPrice>
        </div>
        <Box display="flex" flexWrap="wrap" marginBottom="0.5rem">
          {product.ProductFormulation ? (
            <ProductHighlight>
              {product.ProductFormulation.name}
              <img
                src={product.ProductFormulation.image_url}
                className="img-fluid ml-1"
                style={{ width: '16px', objectFit: 'contain' }}
                alt={product.ProductFormulation.name}
              />
            </ProductHighlight>
          ) : null}
          {product.size && <ProductHighlight>{product.size}</ProductHighlight>}
          {product.spectrum_id && (
            <ProductHighlight>{product.spectrum_id === 1 ? 'Broad-Spectrum' : 'Full-Spectrum'}</ProductHighlight>
          )}
        </Box>
        <div className="mb-2">
          <ProductDescription>{product.description_for_display}</ProductDescription>
          {product.reasoning_toggle && <OutOfStockText>{`Out of Stock: ${product.reasoning} `}</OutOfStockText>}
        </div>
      </ProductOverviewDetails>
    </ProductOverviewContainer>
  );
}

export default ProductOverview;
