import { Close as CloseIcon } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';

interface IMergePatientModalHeader {
  onClick: () => void;
}

function MergePatientModalHeader({ onClick }: IMergePatientModalHeader) {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom="1.5rem"
        alignItems="center"
        sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[500]}` })}
      >
        <DialogTitle
          variant="h2"
          fontSize="1.5rem"
          maxWidth="80%"
          sx={{
            paddingLeft: '0rem',
          }}
        >
          Merge Duplicate Patient Profiles
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography marginBottom="1.5rem">
        Orders, Consultations, and other information on the Secondary profile(s) will be merged into one profile.
      </Typography>
    </>
  );
}

export default MergePatientModalHeader;
