import { SyntheticEvent, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import settings from '@/constants/constants';
import useGetOrderDetails from '@/hooks/order/useGetOrderDetails';
import useGetOrderReceipt from '@/hooks/order/useGetOrderReceipt';
import { Button } from '@/ui-library';

import OrderBreakdown, { Product } from '../order-breakdown/OrderBreakdown';
import OrderStatusChip, { getOrderStatusChipProps } from '../OrderStatusChip/OrderStatusChip';
import PharmacyOrderDetails, { OrderStage } from '../pharmacy-order-details/PharmacyOrderDetails';
import PharmacyOrderDetailsLoading from '../pharmacy-order-details/PharmacyOrderDetailsLoading';

import {
  ActionText,
  DesktopReceiptButtonContainer,
  Faq,
  FaqButton,
  FaqButtonContainer,
  MobileReceiptButtonContainer,
  OrderDetailsContainer,
  OrderInvoiceButtonContainer,
  OrderItemsContainer,
  OrderNumberContainer,
  OrderStatusChipMobile,
  OrderStatusContainer,
  OrderSummaryContainer,
  OrderTrackingButtonContainer,
  PharmacyDetailsContainer,
  ReceiptButton,
  StyledAccordionDetails,
  StyledAccordionSummary,
  Wrapper,
} from './OrderHistoryCard.styles';
import SplitOrderDetails from './SplitOrderDetails';

type OrderProduct = {
  name: string;
  quantity: number;
  price: number;
};

export type OrderHistoryCardProps = {
  orderNumber: string;
  orderLink: string;
  receiptLink?: string;
  numberOfItems: number;
  orderDate: Date;
  pharmacyName: string;
  pharmacyLogoUrl?: string;
  orderPrice: string;
  orderStatus: number;
  trackingLink?: string;
  delivered: boolean;
  orderProducts: OrderProduct[];
  dispensingFee: string;
  splitOrder?: boolean;
  cancelledDate?: string | null;
};

function ViewReceiptButton({ orderCode, expanded }: { orderCode: string; expanded: boolean }) {
  const { data: receiptData, isError } = useGetOrderReceipt(orderCode, expanded);

  if (receiptData && !isError) {
    return (
      <OrderInvoiceButtonContainer>
        <ReceiptButton href={receiptData.signedUrl} fullWidth variant="outlined">
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontWeight: 600,
              padding: theme.spacing(2),
              color: theme.palette.primary.main,
              borderColor: 'var(--mui-palette-primary-_states-disabled)',
            })}
          >
            View receipt
          </Typography>
        </ReceiptButton>
      </OrderInvoiceButtonContainer>
    );
  }

  return null;
}

export function OrderHistoryCard(props: OrderHistoryCardProps) {
  const {
    orderNumber,
    orderDate,
    pharmacyName,
    orderPrice,
    orderStatus,
    pharmacyLogoUrl,
    orderProducts,
    delivered,
    trackingLink,
    dispensingFee,
    splitOrder = false,
    cancelledDate,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const orderCancelled = orderStatus === settings.orderStatus.CANCELLED;

  const {
    data: orderDetails,
    isError: orderDetailsError,
    isLoading: orderDetailsLoading,
  } = useGetOrderDetails(orderNumber, expanded);

  const handleAccordionChange = (_e: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const readableOrderDate = orderDate
    .toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
    .replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3');

  const formattedCancelledDate = cancelledDate
    ? new Date(cancelledDate)
        .toLocaleDateString('en-GB', {
          weekday: 'short',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
        .replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3')
    : null;

  const orderStatusChipProps = getOrderStatusChipProps({ status: orderStatus, delivered, splitOrder });
  const renderReceiptButton = orderDetails && !orderDetailsError && !orderCancelled;
  const renderSplitOrderDetails = splitOrder && orderDetails && !orderDetailsError;

  return (
    <Wrapper>
      <Accordion defaultExpanded={false} onChange={handleAccordionChange} elevation={0}>
        <StyledAccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          <Box sx={{ flexDirection: 'column' }}>
            <OrderNumberContainer>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Order #{orderNumber}
              </Typography>
              <OrderStatusContainer>
                <span>
                  <OrderStatusChip {...orderStatusChipProps} />
                </span>
              </OrderStatusContainer>
            </OrderNumberContainer>
            <OrderDetailsContainer>
              <Typography variant="body2">Placed on {readableOrderDate}</Typography>
            </OrderDetailsContainer>
            {orderCancelled ? (
              <OrderDetailsContainer>
                <Typography variant="body2">Cancelled on {formattedCancelledDate}</Typography>
              </OrderDetailsContainer>
            ) : null}
            <OrderStatusChipMobile>
              <OrderStatusChip {...orderStatusChipProps} />
            </OrderStatusChipMobile>
          </Box>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <OrderSummaryContainer>
            {!orderCancelled &&
              (renderSplitOrderDetails ? (
                <SplitOrderDetails orderDetails={orderDetails} isLoading={orderDetailsLoading} />
              ) : (
                <PharmacyDetailsContainer>
                  {orderDetailsLoading && <PharmacyOrderDetailsLoading />}
                  {!orderDetailsLoading && orderDetails && !orderDetailsError && (
                    <PharmacyOrderDetails
                      pharmacyName={pharmacyName}
                      pharmacyLogoUrl={pharmacyLogoUrl}
                      fullOrderCode={orderDetails[0].order_code}
                      stage={orderStatusChipProps.status as OrderStage}
                    />
                  )}
                  {trackingLink && (
                    <OrderTrackingButtonContainer>
                      <Button href={trackingLink} fullWidth variant="outlined">
                        <ActionText variant="body1">Track delivery</ActionText>
                      </Button>
                    </OrderTrackingButtonContainer>
                  )}
                </PharmacyDetailsContainer>
              ))}

            <OrderItemsContainer>
              <OrderBreakdown
                orderData={{
                  totalPrice: Number(orderPrice),
                  products: orderProducts as Product[],
                  dispensingFee: Number(dispensingFee),
                }}
                cancelled={orderCancelled}
              />

              {renderReceiptButton && (
                <DesktopReceiptButtonContainer>
                  <ViewReceiptButton expanded={expanded} orderCode={orderDetails[0].order_code} />
                </DesktopReceiptButtonContainer>
              )}
            </OrderItemsContainer>
          </OrderSummaryContainer>

          {renderReceiptButton && (
            <MobileReceiptButtonContainer>
              <ViewReceiptButton expanded={expanded} orderCode={orderDetails[0].order_code} />
            </MobileReceiptButtonContainer>
          )}

          <FaqButtonContainer>
            <Faq>
              <Typography
                variant="subtitle1"
                fontWeight="semibold"
                sx={(theme) => ({ marginBottom: theme.spacing(6) })}
              >
                How can we help you?
              </Typography>
              <FaqButtonContainer>
                <Link
                  href="https://support.montu.com.au/hc/en-au/articles/23256708939929-How-long-will-my-order-take"
                  target="_blank"
                >
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1">Why is my order taking so long?</ActionText>
                  </FaqButton>
                </Link>

                <Link
                  href="https://support.montu.com.au/hc/en-au/articles/22616018335641-How-do-I-get-my-tracking-number"
                  target="_blank"
                >
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1"> How do I track my order?</ActionText>
                  </FaqButton>
                </Link>

                <Link href="https://support.montu.com.au/hc/en-au" target="_blank">
                  <FaqButton variant="outlined" fullWidth>
                    <ActionText variant="body1"> Get Help</ActionText>
                  </FaqButton>
                </Link>
              </FaqButtonContainer>
            </Faq>
          </FaqButtonContainer>
        </StyledAccordionDetails>
      </Accordion>
    </Wrapper>
  );
}

export default OrderHistoryCard;
