import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

const { url } = settings;
const logger = new Logger('useGetPatientId');

export const usePatientIdQuery = () => {
  const [userId, setUserId] = useState<number | undefined>();

  // Actively monitor for changes to the current user (eg login, logout).
  useEffect(() => {
    const callback = () => {
      const userResponse = AuthService.getUser();
      setUserId(userResponse?.user?.id || undefined);
    };
    window.addEventListener('user', callback);
    // Ensure the userId is set initially
    callback();

    return () => window.removeEventListener('user', callback);
  }, []);

  const { data } = useQuery<string | undefined>({
    queryKey: ['patientId', userId],
    queryFn: async () => {
      try {
        const response = await axios.get(`${url}/user`);
        return response.data.patNumber;
      } catch {
        logger.error(`unable to fetch patient id for user: ${userId}`);
        return undefined;
      }
    },
    enabled: Boolean(userId),
  });

  return data;
};

export default usePatientIdQuery;
