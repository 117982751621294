import { SyntheticEvent, useState } from 'react';
import { MgPrescription } from '@montugroup/icons';
import { Container, Stack, styled, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';

import { getUniqueKey, Prescription } from './common';
import PrescriptionAccordion from './PrescriptionAccordion';

export interface IPrescriptionContainer {
  prescriptionCode: string;
  prescription: Prescription[];
}

const MainContainer = styled(Container)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2, 2, 0, 2),
  borderRadius: theme.spacing(2),
  marginLeft: 0,
  marginBottom: theme.spacing(5),
  backgroundColor: theme.palette.common.white,
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '60px',
    height: '60px',
    backgroundColor: '#FFFCF2', // TODO: Change to theme warm white once design system is updated
  },
}));

const HeadingTitle = styled(Stack)(({ theme }) => ({
  marginY: 'auto',
  flexDirection: 'row',
  justifyContent: 'space-around',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    gap: 0,
  },
}));

const HeadingContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2, 3, 2),
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

export function PrescriptionContainer({ prescriptionCode, prescription }: IPrescriptionContainer) {
  const [expanded, setExpanded] = useState<number | false>(false);
  const t = useTheme();
  const isMobile = useMediaQuery(t.breakpoints.down('md'));

  const handleOnAccordionChange = (productId: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? productId : false);
  };

  return (
    <MainContainer maxWidth="md">
      <HeadingContainer direction="row">
        <IconWrapper>
          <SvgIcon component={MgPrescription} color="primary" />
        </IconWrapper>
        <HeadingTitle>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium} lineHeight="1.5rem">
            {isMobile ? 'Script I.D: ' : 'Script I.D'}
          </Typography>
          <Typography lineHeight="1.5rem">{prescriptionCode}</Typography>
        </HeadingTitle>
      </HeadingContainer>
      {prescription.map((prescriptionItem) => (
        <PrescriptionAccordion
          key={getUniqueKey(prescriptionItem)}
          prescription={prescriptionItem}
          expanded={expanded}
          onChange={handleOnAccordionChange}
        />
      ))}
    </MainContainer>
  );
}

export default PrescriptionContainer;
