import { AppRouter, initClient, tsRestFetchApi } from '@ts-rest/core';

import getAuthBearerToken from '@/utils/getAuthBearerToken';

import { apiConfig } from './api.config';
/**
 *  The class that initializes a ts-rest core client for interacting with Circuit API endpoints.
 * @param contract {T extends AppRouter} - the contract to initialise with this client.
 */
class TsRestClient<T extends AppRouter> {
  private client;

  constructor(contract: T) {
    this.client = initClient(contract, {
      validateResponse: true,
      baseUrl: apiConfig().baseApiUrl,
      baseHeaders: {
        'Content-Type': 'application/json',
      },
      api: async (args) => {
        const authBearerToken = getAuthBearerToken();
        return tsRestFetchApi({
          ...args,
          headers: {
            ...args.headers,
            authorization: authBearerToken,
          },
        });
      },
    });
  }

  getClient() {
    return this.client;
  }
}

export default TsRestClient;
