const accordionData = [
  {
    title: 'Austism Spectrum Disorder',
    links: [
      {
        path: 'Autism+Spectrum+Disorder/ASD+-+Austism+Spectrum+Disorder.+A+Genetics+Review+-+2011.pdf',
        text: 'ASD - Austism Spectrum Disorder. A Genetics Review - 2011',
      },
      {
        path: 'Autism+Spectrum+Disorder/ASD+-+Cannabinoids+for+People+with+ASD+A+Systematic+Review+of+Published+and+Ongoing+Studies+-+2020.pdf',
        text: 'ASD - Cannabinoids for People with ASD: A Systematic Review of Published and Ongoing Studies - 2020',
      },
      {
        path: 'Autism+Spectrum+Disorder/Autism+Spectrum+Disorder+-+Cannabis+and+Autism+-+A+Complex+Pairing+-+CBD+and+THC+Ease+Behavioural+and+Health+Problems+Linked+to+Autism+Spectrum+Disorder+-+2021.pdf',
        text: 'Autism Spectrum Disorder - Cannabis and Autism: A Complex Pairing - CBD and THC Ease Behavioural and Health Problems Linked to Autism Spectrum Disorder - 2021',
      },
    ],
  },
  {
    title: 'Cancer',
    links: [
      {
        path: 'Cancer/Breast+Cancer+-+Cannabinoids+Reduce+ErbB2-Driven+Breast+Cancer+Progression+Through+Akt+Inhibition+-+2010.pdf',
        text: 'Breast Cancer - Cannabinoids Reduce ErbB2-Driven Breast Cancer Progression Through Akt Inhibition - 2010',
      },
      {
        path: 'Cancer/Cancer+-+A+User\'s+Guide+to+Cannabinoid+Therapies+in+Oncology+-+2016.pdf',
        text: 'Cancer - A User\'s Guide to Cannabinoid Therapies in Oncology - 2016',
      },
      {
        path: 'Cancer/Cancer+-+Integrating+Cannabis+into+Clinical+Cancer+Care+-+2016.pdf',
        text: 'Cancer - Integrating Cannabis into Clinical Cancer Care - 2016',
      },
      {
        path: 'Cancer/Cancer+-+Rates+of+Cannabis+Use+in+Patients+with+Cancer+-+2018.pdf',
        text: 'Cancer - Rates of Cannabis Use in Patients with Cancer - 2018',
      },
      {
        path: 'Cancer/Cancer+(Gynecologic)+-+Prescribed+Medical+Cannabis+in+Women+with+Gynecologic+Malignancies+a+Single-Institution+Survey-Based+Study+-+2020.pdf',
        text: 'Cancer (Gynecologic) - Prescribed Medical Cannabis in Women with Gynecologic Malignancies a Single-Institution Survey-Based Study - 2020',
      },
      {
        path: 'Cancer/Cancer+(Melanoma)+-+Roles+of+Cannabinoids+in+Melanoma+Evidence+From+in+vivo+Studies+-+2020.pdf',
        text: 'Cancer (Melanoma) - Roles of Cannabinoids in Melanoma Evidence From in vivo Studies - 2020',
      },
      {
        path: 'Cancer/Cancer+(Paediatric)+-+The+Role+of+Cannabinoids+as+Anticancer+Agents+in+Pediatric+Oncology+-+2021.pdf',
        text: 'Cancer (Paediatric) - The Role of Cannabinoids as Anticancer Agents in Pediatric Oncology - 2021',
      },
      {
        path: 'Cancer/Cancer+(Paeds+Neuro)+-+Assessment+of+Cannabidiol+and+9-Tetrahydrocannabiol+in+Mouse+Models+of+Medulloblastoma+and+Ependymoma+-+2021.pdf',
        text: 'Cancer (Paeds Neuro) - Assessment of Cannabidiol and 9-Tetrahydrocannabiol in Mouse Models of Medulloblastoma and Ependymoma - 2021',
      },
      {
        path: 'Cancer/Cancer%2C+Microbial+Disease+and+Immunity+-+Cannabinoids+in+Medicine%3A+Cancer%2C+Immunity+and+Microbial+Diseases+-+2021.pdf',
        text: 'Cancer, Microbial Disease and Immunity - Cannabinoids in Medicine: Cancer, Immunity and Microbial Diseases - 2021',
      },
    ],
  },
  {
    title: 'Cardiovascular Disease',
    links: [
      {
        path: 'Cardiovascular+Disease/Cardiovascular+Disease+-+The+Effects+of+Cannabidiol%2C+a+Non-Intoxicating+Compound+of+Cannabis%2C+on+the+Cardiovascular+System+in+Health+and+Disease+-+2020.pdf',
        text: 'Cardiovascular Disease - The Effects of Cannabidiol, a Non-Intoxicating Compound of Cannabis, on the Cardiovascular System in Health and Disease - 2020',
      },
    ],
  },
  {
    title: 'CINV - Chemotherapy Induced Nausea and Vomiting',
    links: [
      {
        path: 'Chemotherapy+Induced+Nausea+and+Vomiting+(CINV)/CINV+-+Oral+THC%3ACBD+Cannabis+Extract+for+Refractory+Chemotherapy-Induced+Nausea+and+Vomiting+-+a+Randomised%2C+Placebo-Controlled%2C+Phase+II+Crossover+Trial+-+2020.pdf',
        text: 'CINV - Oral THC/CBD Cannabis Extract for Refractory Chemotherapy-Induced Nausea and Vomiting - a Randomised, Placebo-Controlled, Phase II Crossover Trial - 2020',
      },
      {
        path: 'Chemotherapy+Induced+Nausea+and+Vomiting+(CINV)/CINV+-+Recent+Developments+in+the+Prevention+of+CINV.+A+Comprehensive+Review+-+2015.pdf',
        text: 'CINV - Recent Developments in the Prevention of CINV. A Comprehensive Review - 2015',
      },
    ],
  },
  {
    title: 'Chronic Pain',
    links: [
      {
        path: 'Chronic+Pain/Arthritis+-+The+Non-Psychoactive+Cannabis+Constituent+Cannabidiol+is+an+Oral+Anti-Arthritic+Therapeutic+in+Murine+Collagen-Induced+Arthritis+-+2000.pdf',
        text: 'Arthritis - The Non-Psychoactive Cannabis Constituent Cannabidiol is an Oral Anti-Arthritic Therapeutic in Murine Collagen-Induced Arthritis - 2000',
      },
      {
        path: 'Chronic+Pain/Chronic+Pain+-+Cannabinoid-Opioid+Interaction+in+Chronic+Pain+-+2011.pdf',
        text: 'Chronic Pain - Cannabinoid-Opioid Interaction in Chronic Pain - 2011',
      },
      {
        path: 'Chronic+Pain/Chronic+Pain+and+Opioid+Reduction+-+Medical+Cannabis+for+the+Reduction+of+Opioid+Dosage+in+the+Treatment+of+Non-Cancer+Chronc+Pain+-+A+Systematic+Review+-+2020.pdf',
        text: 'Chronic Pain and Opioid Reduction - Medical Cannabis for the Reduction of Opioid Dosage in the Treatment of Non-Cancer Chronic Pain - A Systematic Review - 2020',
      },
      {
        path: 'Chronic+Pain/Fibromyalgia+-+Association+of+Concomitant+Fibromyalgia+With+Worse+Disease+Activity+Score+in+28+Joints%2C+Health+Assessment+Questionnaire%2C+and+Short+Form+36+Scores+in+Patients+with+Rheumatoid+Arthritis+-+2009.pdf',
        text: 'Fibromyalgia - Association of Concomitant Fibromyalgia With Worse Disease Activity Score in 28 Joints, Health Assessment Questionnaire, and Short Form 36 Scores in Patients with Rheumatoid Arthritis - 2009',
      },
      {
        path: 'Chronic+Pain/Fibromyalgia+-+Effect+of+Adding+Medical+Cannabis+to+Analgesic+Treatment+in+Patients+with+Low+Back+Pain+Related+to+Fibromyalgia%3A+An+Observational+Cross-Over+Single+Centre+Study+-+2019.pdf',
        text: 'Fibromyalgia - Effect of Adding Medical Cannabis to Analgesic Treatment in Patients with Low Back Pain Related to Fibromyalgia: An Observational Cross-Over Single Centre Study - 2019',
      },
      {
        path: 'Chronic+Pain/Fibromyalgia+-+Medical+Cannabis+for+the+Treatment+of+Fibromyalgia+-+2018.pdf',
        text: 'Fibromyalgia - Medical Cannabis for the Treatment of Fibromyalgia - 2018',
      },
      {
        path: 'Chronic+Pain/Fibromyalgia+-+Safety+and+Efficacy+of+Medical+Cannabis+in+Fibromyalgia+-+2019.pdf',
        text: 'Fibromyalgia - Safety and Efficacy of Medical Cannabis in Fibromyalgia - 2019',
      },
      {
        path: 'Chronic+Pain/Medical+management+of+osteoarthritis+of+the+knee+and+hip+joints.pdf',
        text: 'Medical management of osteoarthritis of the knee and hip joints',
      },
      {
        path: 'Chronic+Pain/National+Action+Plan+for+Pain+Management+-+2019.pdf',
        text: 'National Action Plan for Pain Management - 2019',
      },
      {
        path: 'Chronic+Pain/Neuro%3AChronic+Pain+-+Migraine+Frequency+Decrease+Following+Prolonged+Medical+Cannabis+Treatment+A+Cross-Sectional+Study+-+2020.pdf',
        text: 'Neuro/Chronic Pain - Migraine Frequency Decrease Following Prolonged Medical Cannabis Treatment A Cross-Sectional Study - 2020',
      },
      {
        path: 'Chronic+Pain/Pain+-+Cannabidiol+for+Pain+Treatment+Focus+on+Pharmacology+and+Mechanism+of+Action+-+2020.pdf',
        text: 'Pain - Cannabidiol for Pain Treatment Focus on Pharmacology and Mechanism of Action - 2020',
      },
      {
        path: 'Chronic+Pain/Pain+-+Management+of+Chronic+Pain+-+1999.pdf',
        text: 'Pain - Management of Chronic Pain - 1999',
      },
      {
        path: 'Chronic+Pain/Pain+-+The+Effectiveness+of+Self-Directed+Medical+Cannabis+Treatment+for+Pain+-+2019.pdf',
        text: 'Pain - The Effectiveness of Self-Directed Medical Cannabis Treatment for Pain - 2019',
      },
      {
        path: 'Chronic+Pain/Pain+(Arthritis)+-+The+Prevalence%2C+Cost+and+Disease+Burden+of+Arthritis+in+Australia+-+2001.pdf',
        text: 'Pain (Arthritis) - The Prevalence, Cost and Disease Burden of Arthritis in Australia - 2001',
      },
      {
        path: 'Chronic+Pain/Pain+(Neuro)+-+Pharmacologic+Management+of+Neuropathic+Pain+Evidence-Based+Recommendations+-+2007.pdf',
        text: 'Pain (Neuro) - Pharmacologic Management of Neuropathic Pain Evidence-Based Recommendations - 2007',
      },
      {
        path: 'Chronic+Pain/Pain+(Sickle+Cell+Disease)+-+Considerations+for+Cannabis+Use+to+Treat+Pain+in+Sickle+Cell+Disease+-+2020.pdf',
        text: 'Pain (Sickle Cell Disease) - Considerations for Cannabis Use to Treat Pain in Sickle Cell Disease - 2020',
      },
    ],
  },
  {
    title: 'Dementia',
    links: [
      {
        path: 'Dementia/Alzheimer\'s+-+A+Review+on+Studies+of+Marijuana+for+Alzheimer\'s+Disease+-+Focusing+on+CBD%2C+THC+-+2019.pdf',
        text: 'Alzheimer\'s - A Review on Studies of Marijuana for Alzheimer\'s Disease - Focusing on CBD, THC - 2019',
      },
      {
        path: 'Dementia/Alzheimer\'s+-+Cannabidiol+Amerliorates+Cognitive+Function+via+Regulation+of+IL-33+and+TREM2+Upregulation+in+a+Murine+Model+of+Alzheimer\'s+Disease+-+2021.pdf',
        text: 'Alzheimer\'s - Cannabidiol Amerliorates Cognitive Function via Regulation of IL-33 and TREM2 Upregulation in a Murine Model of Alzheimer\'s Disease - 2021',
      },
      {
        path: 'Dementia/Alzheimer\'s+-+Destabilisation+of+the+Alzheimer\'s+Amyloid-Beta+Protofibrils+by+THC%3A+Molecular+Dynamics+Simulation+Study+-+2021.pdf',
        text: 'Alzheimer\'s - Destabilisation of the Alzheimer\'s Amyloid-Beta Protofibrils by THC: Molecular Dynamics Simulation Study - 2021',
      },
    ],
  },
  {
    title: 'Drug Interactions',
    links: [
      {
        path: 'Drug+Interactions/Benzodiazepines+-+Reduction+of+Benzodiazepine+Use+in+Patients+Prescribed+Medical+Cannabis+-+2019.pdf',
        text: 'Benzodiazepines - Reduction of Benzodiazepine Use in Patients Prescribed Medical Cannabis - 2019',
      },
      {
        path: 'Drug+Interactions/CBD+Drug+Interactions+-+Potential+Adverse+Drug+Events+and+Drug-Drug+Interactions+with+Medical+and+Consumer+Cannabidiol+(CBD)+Use+-+2019.pdf',
        text: 'CBD Drug Interactions - Potential Adverse Drug Events and Drug-Drug Interactions with Medical and Consumer Cannabidiol (CBD) Use - 2019',
      },
      {
        path: 'Drug+Interactions/Chronic+Pain+and+Opioid+Reduction+-+Medical+Cannabis+for+the+Reduction+of+Opioid+Dosage+in+the+Treatment+of+Non-Cancer+Chronc+Pain+-+A+Systematic+Review+-+2020.pdf',
        text: 'Chronic Pain and Opioid Reduction - Medical Cannabis for the Reduction of Opioid Dosage in the Treatment of Non-Cancer Chronic Pain - A Systematic Review - 2020',
      },
      {
        path: 'Drug+Interactions/Opioid+Use+Disorder+-+Emerging+Evidence+for+Cannabis\' Role+in+Opioid+Use+Disorder+-+2018.pdf',
        text: 'Opioid Use Disorder - Emerging Evidence for Cannabis\' Role in Opioid Use Disorder - 2018',
      },
      {
        path: 'Drug+Interactions/THC+Drug+Interactions+-+Potential+Adverse+Drug+Events+with+Tetrahydrocannabinol+(THC)+Due+to+Drug-Drug+Interactions+-+2020.pdf',
        text: 'THC Drug Interactions - Potential Adverse Drug Events with Tetrahydrocannabinol (THC) Due to Drug-Drug Interactions - 2020',
      },
    ],
  },
  {
    title: 'Epilepsy',
    links: [
      {
        path: 'Epilepsy/Epilepsy+-+Epilepsy+and+Cannabis.+A+literaute+review+2018.pdf',
        text: 'Epilepsy - Epilepsy and Cannabis. A literaute review - 2018',
      },
      {
        path: 'Epilepsy/Epilepsy+-+Evidence+for+cannabis+and+cannabinoids+for+epilepsy.+A+systematic+review+of+controlled+and+observational+evidence.+2018.pdf',
        text: 'Epilepsy - Evidence for cannabis and cannabinoids for epilepsy. A systematic review of controlled and observational evidence - 2018',
      },
      {
        path: 'Epilepsy/Epilepsy+-+Trial+of+Cannabidiol+for+Drug-Resistant+Seizures+in+the+Dravet+Syndrome.+2017.pdf',
        text: 'Epilepsy - Trial of Cannabidiol for Drug-Resistant Seizures in the Dravet Syndrome - 2017',
      },
      {
        path: 'Epilepsy/Epilepsy+-+Use+of+Cannabidiol+in+the+Treatment+of+Epilepsy%3A+Efficacy+and+Security+in+Clinical+Trials+-+2019.pdf',
        text: ' Epilepsy - Use of Cannabidiol in the Treatment of Epilepsy: Efficacy and Security in Clinical Trials - 2019',
      },
      {
        path: 'Epilepsy/Epilepsy+-+Use+of+Cannabidiol+in+the+Treatment+of+Epilepsy.+Efficacy+and+Security+in+Clinical+Trials.+2019.pdf',
        text: 'Epilepsy - Use of Cannabidiol in the Treatment of Epilepsy. Efficacy and Security in Clinical Trials - 2019',
      },
    ],
  },
  {
    title: 'Geriatrics',
    links: [
      {
        path: 'Geriatrics/Geriatrics+-+Cannabis+an+Emerging+Treatment+for+Common+Symptoms+in+Older+Adults+-+2021.pdf',
        text: 'Geriatrics - Cannabis an Emerging Treatment for Common Symptoms in Older Adults - 2021',
      },
      {
        path: 'Geriatrics/Geriatrics+-+Characteristics+of+Older+Adults+Who+Were+Early+Adopters+of+Medical+Cannabis+in+the+Florida+Medical+Marijuana+Use+Registry+-+2020.pdf',
        text: ' Geriatrics - Characteristics of Older Adults Who Were Early Adopters of Medical Cannabis in the Florida Medical Marijuana Use Registry - 2020',
      },
    ],
  },
  {
    title: 'Inflammatory Bowel Syndrome or Disease',
    links: [
      {
        path: 'Inflammatory+Bowel+Syndrome+or+Disease/Gastrointestinal+Disorders+-+Cannabidiol+and+Other+Non-Psychoactive+Cannabinoids+for+Prevention+and+Treatment+of+Gastrointestinal+Disorders+Useful+Nutraceuticals%3F+-+2020.pdf',
        text: 'Gastrointestinal Disorders - Cannabidiol and Other Non-Psychoactive Cannabinoids for Prevention and Treatment of Gastrointestinal Disorders Useful Nutraceuticals? - 2020',
      },
      {
        path: 'Inflammatory+Bowel+Syndrome+or+Disease/IBD+-+Cannabis+and+Canabidinoids+on+the+Inflammatory+Bowel+Diseases+Going+Beyond+Misuse+-+2020.pdf',
        text: 'IBD - Cannabis and Canabidinoids on the Inflammatory Bowel Diseases Going Beyond Misuse - 2020',
      },
      {
        path: 'Inflammatory+Bowel+Syndrome+or+Disease/Inflammatory+Bowel+Disease+(IBD)+-+Medicinal+Cannabis+for+Inflammatory+Bowel+Disease%3A+A+Survey+of+Perspectives%2C+Experiences+and+Current+Use+in+Australian+Patients+-+2020.pdf',
        text: 'Inflammatory Bowel Disease (IBD) - Medicinal Cannabis for Inflammatory Bowel Disease: A Survey of Perspectives, Experiences and Current Use in Australian Patients - 2020',
      },
    ],
  },
  {
    title: 'Insomnia',
    links: [
      {
        path: 'Insomnia/Insomnia+-+Cannabis+and+Insomnia+-+2011.pdf',
        text: ' Insomnia - Cannabis and Insomnia - 2011',
      },
      {
        path: 'Insomnia/Insomnia+-+Effectiveness+of+Raw%2C+Natural+Medical+Cannabis+Flower+for+Treating+Insomnia+Under+Naturalistic+Conditions+-+2018.pdf',
        text: 'Insomnia - Effectiveness of Raw, Natural Medical Cannabis Flower for Treating Insomnia Under Naturalistic Conditions - 2018',
      },
      {
        path: 'Insomnia/Insomnia+-+Prevelence+of+DSM-IV+Diagnostic+Critera+of+Insomnia.+Distinguishing+Insomnia+Related+to+Mental+Disorders+from+Sleep+Disorders+-+1996.pdf',
        text: ' Insomnia - Prevelence of DSM-IV Diagnostic Critera of Insomnia. Distinguishing Insomnia Related to Mental Disorders from Sleep Disorders - 1996',
      },
    ],
  },
  {
    title: 'Mental Health',
    links: [
      {
        path: 'Mental+Health/Psychiatric+Disorders+-+New+Perspectives+on+the+Use+of+Cannabis+in+the+Treatment+of+Psychiatric+Disorders+-+2018.pdf',
        text: ' Psychiatric Disorders - New Perspectives on the Use of Cannabis in the Treatment of Psychiatric Disorders - 2018',
      },
      {
        path: 'Mental+Health/PTSD+-+Use+of+Medicinal+Cannabis+and+Synthetic+Cannabinoids+in+Post-Traumatic+Stress+Disorder+(PTSD)%3A+A+Systematic+Review+-+2019.pdf',
        text: ' PTSD - Use of Medicinal Cannabis and Synthetic Cannabinoids in Post-Traumatic Stress Disorder (PTSD): A Systematic Review - 2019',
      },
    ],
  },
  {
    title: 'Multiple Scelrosis',
    links: [
      {
        path: 'Multiple+Scelrosis/Multiple+Sclerosis+-+The+Effect+of+Cannabis+on+the+Clinical+and+Cytokine+Profiles+in+Patients+with+Multiple+Sclerosis+-+2021.pdf',
        text: ' Multiple Sclerosis - The Effect of Cannabis on the Clinical and Cytokine Profiles in Patients with Multiple Sclerosis - 2021',
      },
    ],
  },
  {
    title: 'Neurological Disorders',
    links: [
      {
        path: 'Neurological+Disorders/Gilles+de+la+Tourette+Symdrome+-+Speechlessness+in+Gilles+de+la+Tourette+Syndrome+Cannabis+Based+Medicines+Improve+Severe+Vocal+Blocking+Tics+in+Two+Patients+-+2017.pdf',
        text: 'Gilles de la Tourette Symdrome - Speechlessness in Gilles de la Tourette Syndrome Cannabis Based Medicines Improve Severe Vocal Blocking Tics in Two Patients - 2017',
      },
      {
        path: 'Neurological+Disorders/Neuro+Activity+Diseases+-+Structure+Activity+Relationship+of+Cannabis+Derived+Compounds+for+the+Treatment+of+Neuronal+Activity+Related+Diseases+-+2018.pdf',
        text: ' Neuro Activity Diseases - Structure Activity Relationship of Cannabis Derived Compounds for the Treatment of Neuronal Activity Related Diseases - 2018',
      },
      {
        path: 'Neurological+Disorders/Neuro%3AChronic+Pain+-+Migraine+Frequency+Decrease+Following+Prolonged+Medical+Cannabis+Treatment+A+Cross-Sectional+Study+-+2020.pdf',
        text: 'Neuro:Chronic Pain - Migraine Frequency Decrease Following Prolonged Medical Cannabis Treatment A Cross-Sectional Study - 2020',
      },
      {
        path: 'Neurological+Disorders/Parkinson\'s+-+Neuroprotection+or+Neurotoxicity+of+Illicit+Drugs+on+Parkinson\'s+Disease+-+2020.pdf',
        text: 'Parkinson\'s - Neuroprotection or Neurotoxicity of Illicit Drugs on Parkinson\'s Disease - 2020',
      },
    ],
  },
  {
    title: 'Palliative Care',
    links: [
      {
        path: 'Palliative+Care/Palliative+Cancer+-+Short-Term+Medical+Cannabis+Treatment+Regimens+Produced+Beneficial+Effects+Among+Palliative+Cancer+Patients+-+2020.pdf',
        text: ' Palliative Cancer - Short-Term Medical Cannabis Treatment Regimens Produced Beneficial Effects Among Palliative Cancer Patients - 2020',
      },
      {
        path: 'Palliative+Care/Palliative+Care+-+Cannabis+in+palliative+care.+Current+Challenges+and+Practical+Recommendations+-+2018.pdf',
        text: 'Palliative Care - Cannabis in palliative care. Current Challenges and Practical Recommendations - 2018',
      },
    ],
  },
];

export default accordionData;
