import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link, styled, Typography } from '@mui/material';

interface LinkProps {
  path: string;
  text: string;
}

interface ComponentProps {
  title: string;
  links: LinkProps[];
}

const BASE_LINK_URL = 'https://circuit.montu.com.au/assets/docs/research';

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #194457;
`;

function ResearchAccordionItem({ title, links }: ComponentProps) {
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>{title}</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {links.map((link) => (
          <Typography paragraph key={title}>
            <CustomLink href={`${BASE_LINK_URL}/${link.path}`} rel="noreferrer noopener" target="_blank">
              {link.text}
            </CustomLink>
          </Typography>
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export default ResearchAccordionItem;
