/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from 'react';
import { Table } from '@montugroup/design-system';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { usePharmacistUnableToDispenseModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import { UnableToDispenseInfo, UnableToDispenseInfoProductDetails } from '@/types';
import truncateString from '@/utils/common';

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

function UnableToDispenseModal() {
  const { unableToDispenseModal, hideUnableToDispenseModal } = usePharmacistUnableToDispenseModal();

  const orderTableColumns: ColumnDef<UnableToDispenseInfo>[] = useMemo(
    () => [
      {
        accessorKey: 'paidDate',
        header: 'Paid date',
        enableSorting: false,
      },
      {
        accessorKey: 'patientCode',
        header: 'Patient ID',
        enableSorting: false,
      },
      {
        accessorKey: 'prescriptions',
        header: 'Prescription',
        enableSorting: false,
        cell: ({ row }) => row.original.prescriptions.join(', '),
      },
    ],
    [],
  );

  const productsTableColumns: ColumnDef<UnableToDispenseInfoProductDetails>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Product',
        enableSorting: false,
      },
      {
        accessorKey: 'qty',
        header: 'Quantity',
        enableSorting: false,
      },
      {
        id: 'reason',
        header: 'Reason',
        cell: ({ row }) => (
          <div>
            <Tooltip
              title={
                row.original.otherDispenseReasonTxt
                  ? ` - ${truncateString(row.original.otherDispenseReasonTxt, 12)}`
                  : ''
              }
            >
              <div>{row.original.reason}</div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'canDispense',
        header: '',
        cell: ({ row }) => (
          <div>{row.original.canDispense ? <CheckCircle color="success" /> : <Cancel color="error" />}</div>
        ),
      },
    ],
    [],
  );

  return (
    <Dialog open={unableToDispenseModal.visible} onClose={hideUnableToDispenseModal} fullWidth maxWidth="lg">
      {unableToDispenseModal.data && (
        <>
          <DialogTitle>Unable to dispense {unableToDispenseModal?.data?.orderCode}</DialogTitle>
          <Content>
            <Table data={[unableToDispenseModal.data]} columns={orderTableColumns} hasRowBackgroundColor={false} />
            <Table
              data={unableToDispenseModal.data.products}
              columns={productsTableColumns}
              hasRowBackgroundColor={false}
            />
          </Content>
          <DialogActions>
            <Button onClick={hideUnableToDispenseModal}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default UnableToDispenseModal;
