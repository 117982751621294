import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BrandThemeProvider } from '@montugroup/themes';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, List, styled, SwipeableDrawer, Theme } from '@mui/material';

import AltLogo from '@/components/common/AltLogo';
import { alternaleafAppBarConfig, createOrderButton } from '@/components/layout/navbar/navbar.config';
import NavMenuItem, { NavMenuLink } from '@/components/layout/navbar/NavMenuItem';
import USER_ROLES from '@/constants/userRoles';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import AuthService from '@/services/authentication.service';
import { tmpSxSpecificityForce } from '@/theme';
import { BRANDS } from '@/types';

import Logout from './Logout';

interface AppDrawerProps {
  open?: boolean;
}

const DRAWER_WIDTH = 256;
const DRAWER_WIDTH_COLLAPSED = 77;

const openedMixin = (theme: Theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_WIDTH_COLLAPSED,
});

const AppDrawer = styled(SwipeableDrawer)<AppDrawerProps>(({ theme, open }) => ({
  width: open ? DRAWER_WIDTH : DRAWER_WIDTH_COLLAPSED,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  }),
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    ...(open ? openedMixin(theme) : closedMixin(theme)),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const NavContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(14),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const ListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0;
`;

export const OrderButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: `${theme.palette.common.white} ${tmpSxSpecificityForce}`,
  padding: `${theme.spacing(4, 0)} ${tmpSxSpecificityForce}`,
  marginTop: theme.spacing(3),
  minWidth: '64px',
  width: '100%',
  gap: theme.spacing(2),
  boxShadow: theme.shadows[2],
  '&:hover': {
    color: `${theme.palette.primary.main} ${tmpSxSpecificityForce}`,
    backgroundColor: `${theme.palette.grey[200]} ${tmpSxSpecificityForce}`,
  },
  '&:focus': {
    outline: 'none',
  },
}));

function Sidebar() {
  const { key: partnerKey } = usePortalPartnerBrand();

  const [open, setOpen] = useState<boolean>(true);
  const [textVisible, setTextVisible] = useState(true);
  const roleId = AuthService.getUser()?.user?.role_id || USER_ROLES.patient;

  const mainMenuItemsAlternaleaf = alternaleafAppBarConfig.filter(
    (menuItem) => menuItem.brand === BRANDS.ALTERNALEAF && menuItem.roleId.includes(roleId),
  );

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => setTextVisible(true), 200);
    } else {
      setTextVisible(false);
    }
  }, [open]);

  return (
    <AppDrawer variant="permanent" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
      <Box paddingLeft={(theme) => theme.spacing(4.2)} paddingTop={(theme) => theme.spacing(10)}>
        <IconButton
          onClick={() => setOpen((prevState) => !prevState)}
          sx={{
            color: (theme) => theme.palette.common.white,
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          {open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
        </IconButton>
      </Box>

      <NavContainer component="nav">
        <Box
          pl={open ? 7 : 4.2}
          pb={3}
          sx={{
            transition: '0.3s',
          }}
        >
          <NavMenuLink to="/home" onClick={scrollToTop}>
            <AltLogo height="24" />
          </NavMenuLink>
        </Box>

        <ListContainer>
          {mainMenuItemsAlternaleaf.map((mainMenuItem) => (
            <NavMenuItem
              key={mainMenuItem.id}
              toggleText={open}
              menuItem={mainMenuItem}
              onClick={scrollToTop}
              isSideBar
            />
          ))}
          <BrandThemeProvider variant={partnerKey}>
            <Link
              to={createOrderButton.link}
              style={{
                marginLeft: open ? '1rem' : 'auto',
                marginRight: open ? '1rem' : 'auto',
                marginBottom: '1.5rem',
                marginTop: 'auto',
              }}
            >
              <OrderButton variant="contained" size="large">
                <createOrderButton.icon />
                {textVisible && createOrderButton.name}
              </OrderButton>
            </Link>
          </BrandThemeProvider>
          <Logout toggleText={open} />
        </ListContainer>
      </NavContainer>
    </AppDrawer>
  );
}

export default Sidebar;
