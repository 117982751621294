import { Box, styled } from '@mui/material';

import { Heading } from './common';
import { Product } from './types';

const ProductDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: 0,
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const ProductDetailRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '1.5rem',
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    paddingRight: '2rem',
  },
}));

const ProductDetailLabel = styled(Box)`
  flex: 1;
  font-weight: 700;
  font-size: 1.1rem;
  padding-right: 2rem;
`;

const ProductDetailContent = styled(Box)`
  flex: 1;
  font-weight: 500;
  font-size: 1.1rem;
`;

interface ProductDetailsProps {
  product: Product;
}

export function ProductDetails(props: ProductDetailsProps) {
  const { product } = props;

  return (
    <div>
      <Heading>Details</Heading>
      <hr />
      <ProductDetailsContainer>
        <ProductDetailRow>
          <ProductDetailLabel>Brand:</ProductDetailLabel>
          <ProductDetailContent>{product.Suppliers[0] ? product.Suppliers[0].supplier_name : '-'}</ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>CBD-THC Ratio:</ProductDetailLabel>
          <ProductDetailContent>{product.cbd_thc_ratio}</ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>Strain:</ProductDetailLabel>
          <ProductDetailContent>{product.ProductStrain ? product.ProductStrain.name : '-'}</ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>Schedule:</ProductDetailLabel>
          <ProductDetailContent>{product.ProductSchedule ? product.ProductSchedule.name : '-'}</ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>Category:</ProductDetailLabel>
          <ProductDetailContent>
            {product.ProductIngredient ? product.ProductIngredient.name.split(':')[0] : '-'}
          </ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>Terpene profile:</ProductDetailLabel>
          <ProductDetailContent>
            {product.contents ? product.contents.replaceAll('\n', ', ') : '-'}
          </ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow sx={{ display: 'none' }}>
          <ProductDetailLabel>Price:</ProductDetailLabel>
          <ProductDetailContent>
            <span className="price">
              $
              {product.size
                ? `${(Number(product.price) / Number(product.size.replace(/[^0-9]/gi, ''))).toFixed(2)}`
                : product.price}
            </span>
            {product.size && `/${product.size.replace(/[^a-z]/gi, '')}`}
          </ProductDetailContent>
        </ProductDetailRow>
        <ProductDetailRow>
          <ProductDetailLabel>Condition used:</ProductDetailLabel>
          <ProductDetailContent>
            {product.conditions ? product.conditions.replaceAll('\n', ', ') : '-'}
          </ProductDetailContent>
        </ProductDetailRow>
      </ProductDetailsContainer>
    </div>
  );
}

export default ProductDetails;
