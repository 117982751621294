import { useContext } from 'react';

import { BrazeContentCardContext } from '@/App';

import ContentCard from './components/ContentCard';

type Props = {
  displayType: string;
};

export default function SinglePageContentCard({ displayType }: Props) {
  const brazeContentCards = useContext(BrazeContentCardContext);
  const contentCard = (brazeContentCards || []).find((card) => card?.extras?.display_type === displayType);

  return contentCard ? <ContentCard card={contentCard} /> : null;
}
