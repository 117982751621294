import React, { PropsWithChildren } from 'react';
import { Info, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import { Box, Checkbox, Link, Stack, styled } from '@mui/material';

import SameDayDeliveryChip from '@/components/order/orderAllocationTool/SameDayDeliveryChip';
import { usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';
import {
  usePharmacistApprovalDocumentsModal,
  usePharmacistUnableToDispenseModal,
} from '@/context/pharmacist/orders/PharmacistOrdersModals';
import { ProductDetails } from '@/services/pharmacist.service';
import { PharmacistOrder, PrescriptionDetails } from '@/types';

const CellContent = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnorderedList = styled('ul')`
  list-style: none;
  margin-bottom: 0;
`;

const ApprovalsIcon = styled(PictureAsPdfIcon)`
  cursor: pointer;
`;

const ShipmentStatus = styled(Box)`
  margin-left: 0.25rem;
`;

const InfoIcon = styled(Info)`
  cursor: pointer;
`;

export function TableCell({ children }: PropsWithChildren) {
  return <CellContent>{children}</CellContent>;
}

export function ApprovalsTableCell({ order }: { order: PharmacistOrder }) {
  const { showApprovalDocumentsModal } = usePharmacistApprovalDocumentsModal();
  return (
    <CellContent>
      <ApprovalsIcon aria-label="show approvals" onClick={() => showApprovalDocumentsModal(order)} />
    </CellContent>
  );
}

export function PrescriptionsTableCell({ prescriptions }: { prescriptions: PrescriptionDetails[] }) {
  return (
    <CellContent>
      <UnorderedList>
        {prescriptions.map(({ name, path }) => (
          <li key={name}>
            <Link href={path} target="_blank">
              {name}
            </Link>
          </li>
        ))}
      </UnorderedList>
    </CellContent>
  );
}

export function ProductNameTableCell({ productDetails }: { productDetails: ProductDetails[] }) {
  return (
    <CellContent>
      <UnorderedList>
        {productDetails.map(({ name, id }) => (
          <li key={id}>{name}</li>
        ))}
      </UnorderedList>
    </CellContent>
  );
}

export function ArrayValueTableCell<T extends number | string>({ values }: { values: T[] }) {
  // todo look at adding unique identifiers for these
  return (
    <CellContent>
      <UnorderedList>
        {values.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${value}${index}`}>{value}</li>
        ))}
      </UnorderedList>
    </CellContent>
  );
}

export function ShipmentStatusTableCell({ orderId, status }: { orderId: number; status: string }) {
  const { showUnableToDispenseModal } = usePharmacistUnableToDispenseModal();
  switch (status) {
    case 'Pharmacy out of Stock':
      return <CellContent>Out of stock</CellContent>;
    case 'Pharmacy Partially out of Stock':
      return <CellContent>Partially out of stock</CellContent>;
    case 'Unable to Dispense':
      return (
        <CellContent>
          <InfoIcon onClick={() => showUnableToDispenseModal(orderId)} />
          <ShipmentStatus>Unable to dispense</ShipmentStatus>
        </CellContent>
      );
    default:
      return status;
  }
}

export function OrderSelectTableCell({
  orderId,
  potentialSameDayDelivery,
}: {
  orderId: number;
  potentialSameDayDelivery: boolean | null;
}) {
  const { selectedOrders, toggleOrderSelection } = usePharmacistOrders();

  return (
    <CellContent>
      <Stack>
        <Checkbox checked={selectedOrders.includes(orderId)} onChange={() => toggleOrderSelection(orderId)} />
        <SameDayDeliveryChip potentialSameDayDelivery={!!potentialSameDayDelivery} />
      </Stack>
    </CellContent>
  );
}

export function DoctorTableCell({ gps }: { gps: string[] }) {
  return (
    <CellContent>
      <UnorderedList>
        {gps.map((gp) => (
          <li key={gp}>{gp}</li>
        ))}
      </UnorderedList>
    </CellContent>
  );
}

export default TableCell;
