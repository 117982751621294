import React from 'react';
// eslint-disable-next-line no-duplicate-imports
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AuthService } from '../../services/authentication.service';
import HeaderUnauth from '../layout/header-unauth';
import { Logger } from '@/utils/logger';

const logger = new Logger('confirmGPAdmin');

const ConfirmGPBySuperAdmins = (props) => {
  const [response, setResponse] = useState({
    isVerified: false,
    message: '',
  });
  let responseObj;
  const { id } = props.match.params;
  React.useEffect(() => {
    (async () => {
      const user = AuthService.getUser();
      if (!user) {
        toast.error('kindly login in as super-admin');
        logger.error('No user - kindly login in as super-admin');
        return props.history.push('/');
      }
      if (user && !AuthService.isSuperAdmin()) {
        AuthService.clearCurrentUserFromStorage();
        toast.error('kindly login in as super-admin');
        logger.error('Not super admin - kindly login in as super-admin');
        return props.history.push('/');
      }
      responseObj = await AuthService.updateGPInfo(id);
      setResponse({ ...response, isVerified: responseObj.data.isVerified, message: responseObj.data.message });
    })();
  }, []);

  return (
    <div style={{ height: '90vh' }}>
      <HeaderUnauth />
      <div style={{ margin: 'auto', width: '600px', padding: '20px', 'font-size': '20px', 'text-align': 'center' }}>
        {response.message}
      </div>
    </div>
  );
};

export default ConfirmGPBySuperAdmins;
