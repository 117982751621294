import { MdLock } from 'react-icons/md';
import styled from '@emotion/styled';
import { Divider, Skeleton, SvgIcon, Typography, TypographyProps } from '@mui/material';

import RefillCheckoutButton from '@/components/patient/RefillCheckoutButton';
import { numberFormat } from '@/utils/helpers';

const Container = styled.div(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '288px',
    minHeight: '288px',
  },
}));

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  margin-top: 0;
`;

const OrderDetails = styled.dl`
  display: flex;
  flex-direction: column;
  margin-block-start: 1rem;
  margin-bottom: 0.5rem;
`;

const OrderTotal = styled(OrderDetails)`
  padding: 0 0 0.75rem 0;
`;

const OrderItems = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: space-between;
  column-gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

const OrderCaption = styled.small`
  display: inline-block;
  text-align: center;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 1.25rem;
  background-color: ${({ theme }) => theme?.palette?.grey?.[50]};
  border: 1px solid ${({ theme }) => theme?.palette?.grey?.[200]};
  border-radius: 6px;
`;

type TypographyWrapperProps = Omit<TypographyProps, 'variant' | 'fontWeight'>;

function MainText({ sx = [], ...otherProps }: TypographyWrapperProps) {
  return (
    <Typography
      variant="body1"
      fontWeight="fontWeightMedium"
      sx={[{ color: 'text.primary' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...otherProps}
    />
  );
}

function SummaryText({ sx = [], ...otherProps }: TypographyWrapperProps) {
  return (
    <Typography
      variant="caption"
      fontWeight="fontWeightBold"
      sx={[{ color: 'text.secondary' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...otherProps}
    />
  );
}

function formatAdjustmentAmount(amount: string | number) {
  return numberFormat(typeof amount === 'string' ? -parseFloat(amount) : -amount);
}

const DEFAULT_SHIPPING_CALCULATION_NOTE = 'Shipping fee calculated at checkout.';

type Discount = {
  id: string;
  display: string;
  amount: number | string;
};

type Credit = {
  id: string;
  display: string;
  amount: number | string;
};

type Product = {
  id: string | number;
  name: string;
  price: number;
  quantity: number;
};

type CheckoutData = {
  products: Product[];
  totalNumberOfProducts: number;
  totalPrice: number;
  subTotalPrice?: number;
  discounts?: Discount[];
  credits?: Credit[];
  shippingCosts?: {
    shipping: number;
    shippingGst: number;
  };
  shippingCalculationNote?: string;
};

type Props = {
  checkoutData: CheckoutData;
  handleCheckout: () => void;
  disabled?: boolean;
  shopifyCheckoutEnabled: boolean;
};

export default function OrderSummary({
  checkoutData: {
    products,
    totalPrice,
    totalNumberOfProducts,
    discounts = [],
    credits = [],
    shippingCosts = {
      shipping: 0,
      shippingGst: 0,
    },
    subTotalPrice = 0,
    shippingCalculationNote = DEFAULT_SHIPPING_CALCULATION_NOTE,
  },
  handleCheckout,
  disabled,
  shopifyCheckoutEnabled,
}: Props) {
  const hasAnyProducts = products.length > 0;
  const hasAnyDiscounts = discounts.length > 0;
  const hasAnyCredits = credits.length > 0;
  const canRenderAdjustments = hasAnyProducts && (hasAnyDiscounts || hasAnyCredits);
  const canRenderSummary = hasAnyProducts;

  return (
    <Container>
      <Wrapper>
        <Heading>Total</Heading>
        <OrderDetails>
          {hasAnyProducts ? (
            products.map(({ id, name, price, quantity }) => (
              <OrderItems key={id}>
                <MainText>
                  {quantity} x {name}
                </MainText>
                <MainText>{numberFormat(price * quantity)}</MainText>
              </OrderItems>
            ))
          ) : (
            <OrderItems>
              <MainText>-</MainText>
              <MainText>-</MainText>
            </OrderItems>
          )}
        </OrderDetails>
        {canRenderAdjustments && (
          <>
            <Divider />
            <OrderDetails>
              {discounts.map((discount) => (
                <OrderItems key={discount.id}>
                  <SummaryText noWrap>{discount.display}</SummaryText>
                  <SummaryText>{formatAdjustmentAmount(discount.amount)}</SummaryText>
                </OrderItems>
              ))}
              {credits.map((credit) => (
                <OrderItems key={credit.id}>
                  <SummaryText noWrap>{credit.display}</SummaryText>
                  <SummaryText>{formatAdjustmentAmount(credit.amount)}</SummaryText>
                </OrderItems>
              ))}
            </OrderDetails>
          </>
        )}
        {canRenderSummary && (
          <>
            <Divider />
            <OrderDetails>
              {shopifyCheckoutEnabled ? (
                <OrderItems>
                  <SummaryText>{shippingCalculationNote}</SummaryText>
                </OrderItems>
              ) : (
                <>
                  <OrderItems>
                    <SummaryText>Shipping and dispensing fees</SummaryText>
                    <SummaryText>{numberFormat(shippingCosts.shipping)}</SummaryText>
                  </OrderItems>
                  <OrderItems>
                    <SummaryText>Subtotal</SummaryText>
                    <SummaryText>{numberFormat(subTotalPrice)}</SummaryText>
                  </OrderItems>
                  <OrderItems>
                    <SummaryText>Total GST</SummaryText>
                    <SummaryText>{numberFormat(shippingCosts.shippingGst)}</SummaryText>
                  </OrderItems>
                </>
              )}
            </OrderDetails>
          </>
        )}
        <Divider />
        <OrderTotal>
          <OrderItems>
            <strong>Total</strong>
            <strong>{hasAnyProducts ? numberFormat(totalPrice) : '-'}</strong>
          </OrderItems>
        </OrderTotal>
        <RefillCheckoutButton
          totalNumberOfProducts={totalNumberOfProducts}
          handleCheckout={handleCheckout}
          disabled={disabled}
        />
        <OrderCaption>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            <SvgIcon component={MdLock} sx={{ fontSize: 'inherit' }} /> Payments are processed securely
          </Typography>
        </OrderCaption>
      </Wrapper>
    </Container>
  );
}

export function OrderSummarySkeleton() {
  return (
    <Container data-testid="order-summary-skeleton">
      <Wrapper>
        <Heading>Total</Heading>
        <OrderDetails>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" height={36} />
        </OrderDetails>
      </Wrapper>
    </Container>
  );
}
