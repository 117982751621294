import React from 'react';
import TopBanner from '../resources/TopBanner';

function Campaign() {
  return (
    <React.Fragment>
      <div className="h-100 resources-content">
        <TopBanner h1Text="The Montu Observational Study Program" />

        <div className="card text-center container border-0 mx-auto font-light mt-4 p-4">
          <p>
            The Montu Observational Study Program (MOBS) is designed to collect real-world data across our growing
            network of cannabis prescribers to derive robust treatment frameworks. Participating doctors receive the
            opportunity to contribute to the advancement of medical cannabis treatments, while providing their patients
            improved access with subsidised products.
          </p>
          <p className="text-success font-weight-bold font-bold mt-4">
            Enrolment closes 30th April 2021 - limited slots remaining
          </p>
          <p className="mt-4">
            This study analyses the impact of medical cannabis treatment on patients with chronic disease, focusing on
            symptom control, side effects, QOL and reduction in other medications. Patients receive their{' '}
            <b>
              <u>first bottle of Circle BALANCE 1:1 free of charge.</u>
            </b>{' '}
            Data will be collected directly from patients via questionnaires throughout the initial titration phase
            during the first 4 weeks.
          </p>
          <button
            style={{ width: '300px', background: 'rgb(249, 154, 32)' }}
            className="btn text-white mx-auto font-weight-bold mt-5"
            disabled={true}
          >
            ENROL PATIENT
          </button>
          <a
            style={{
              width: '300px',
              color: 'rgb(249, 154, 32)',
              fontSize: '14px',
              border: '2px solid rgb(249, 154, 32)',
            }}
            rel="noopener noreferrer"
            target="_blank"
            href="https://circuit.montu.com.au/assets/docs/Patient+Consent+Form.pdf"
            className="btn mx-auto font-weight-bold font-bold mt-3"
          >
            DOWNLOAD CONSENT FORM
          </a>
        </div>
        <div className="mt-5 text-center pt-4 font-light card-header border-0" style={{ marginBottom: '-40px' }}>
          <p className="mt-0">
            For more information about the study go to:{' '}
            <a href="https://www.montu.com.au/study" rel="noopener noreferrer" target="_blank">
              www.montu.com.au/study
            </a>
          </p>
          <p className="mt-0">
            Should you require any assistance, please contact our team at{' '}
            <a href="mailto:hcp@montu.com.au">hcp@montu.com.au</a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Campaign;
